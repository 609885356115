import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "sonner";
import { BackLink } from "../../components/BackLink";
import { Button, ButtonWrap, Submit } from "../../components/CTA";
import { CancelIcon } from "../../components/CancelIcon";
import { Card } from "../../components/Card";
import { Chevron } from "../../components/Chevron";
import DetailList from "../../components/DetailList";
import { Flex } from "../../components/Flex";
import { H2, H3 } from "../../components/Heading";
import { Input } from "../../components/Input";
import Loading from "../../components/Loading";
import { Modal } from "../../components/Modal";
import { InlineAddBtn, InlineBtn } from "../../components/NewButton";
import { Select } from "../../components/Select";
import { Text } from "../../components/Text";
import { View } from "../../components/View";
import { CheckCircle } from "../../components/icons/CheckCircle";
import { TrashIcon } from "../../components/icons/TrashIcon";
import { MOBILE_BREAKPOINT } from "../../config";
import {
  BrandLocationQuery,
  ExcludedHoursInput,
  OpeningHoursInput,
  useBrandLocationQuery,
  useDeleteLocationMutation,
  useUpdateLocationMutation,
} from "../../graphql/generated";
import useAnalytics from "../../hooks/useAnalytics";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import { styled } from "../../styles";
import { formatTime } from "../../utils/formatTime";
import { daysSortMap } from "./CreateLocation";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 600px;
  text-align: left;
  margin: 0 ${(p) => p.theme.spacing.l};

  @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-top: 0px;
  }
`;

const StyledCard = styled(Card)`
  padding: ${(p) => p.theme.spacing.xl};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.l};
  }
`;

const OptionsToggleWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export interface MatchParams {
  page: string;
}

export const BrandLocation = () => {
  return (
    <Wrap>
      <BackLink margin="0 0 0" to="/b/locations">
        <Chevron direction="left" /> Locations
      </BackLink>
      <LocationDetails />
    </Wrap>
  );
};

const LocationDetails = () => {
  const { track } = useAnalytics();
  const client = useGqlClient();
  let { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [error, setError] = useState<string | null>(null);
  const [excludedHours, setExcludedHours] = useState<ExcludedHoursInput[]>([]);
  const [openingHours, setOpeningHours] = useState<OpeningHoursInput[]>([]);
  const [editingOpeningHours, setEditingOpeningHours] = useState(false);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const queryClient = useQueryClient();
  const account = useSelector(authSelectors.account);

  const brand = useSelector(authSelectors.activeBrand);
  const domain = account
    ? account.email.substring(account?.email.lastIndexOf("@") + 1)
    : "example.com";
  const brandQuery = useBrandLocationQuery(
    client,
    { id },
    {
      retry: false,
    }
  );
  const updateLocation = useUpdateLocationMutation(client, {});

  const deleteLocation = useDeleteLocationMutation(client, {});

  const { isLoading, data, isError } = brandQuery;

  useEffect(() => {
    if (!data) {
      return;
    }

    setExcludedHours(data.location.excludedHours);
    setOpeningHours(
      data.location.openingHours.map((ohs) => {
        return {
          dayOfWeek: ohs.dayOfWeek,
          startHour: ohs.openHour,
          startMinute: ohs.openMinute,
          endHour: ohs.closeHour,
          endMinute: ohs.closeMinute,
        };
      })
    );

    if (data.location.openingHours.length === 0) {
      Sentry.captureException(`${data.location.id} has no opening hours`);
    }
  }, [data]);

  if (isLoading) {
    return (
      <Wrap>
        <Loading defer />
      </Wrap>
    );
  }

  if (isError || !data) {
    return (
      <Wrap>
        <H3>Oops</H3>

        <Text margin={"0 0 xl 0"}>
          Couldn't load this location, please try again later.
        </Text>
      </Wrap>
    );
  }

  const defaultExcludedHours: ExcludedHoursInput | undefined =
    openingHours.length > 0
      ? {
          startHour: openingHours[0].startHour,
          startMinute: 0,
          endHour: openingHours[0].endHour,
          endMinute: 0,
          dayOfWeek: openingHours[0].dayOfWeek,
        }
      : undefined;

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fd = new FormData(e.currentTarget);

    updateLocation.mutate(
      {
        id,
        name: fd.get("name") as string,
        email: fd.get("email") as string,
        dmnVenueId: fd.get("dmnVenueId")
          ? (fd.get("dmnVenueId") as string)
          : undefined,
        excludedHours,
        openingHours,
      },
      {
        onError: (e) => {
          setError(e as string);
        },
        onSuccess: (res) => {
          toast.success("Location updated");
          track("Edit location", {
            id: res.updateLocation?.id ? res.updateLocation.id : undefined,
            brand: brand ? brand.name : undefined,
          });
          queryClient.resetQueries(["BrandLocations", "BrandLocation"], {
            exact: false,
          });
          history.push("/b/locations");
        },
      }
    );
  };

  return (
    <>
      {error && <Text>Error updating</Text>}

      <Modal isOpen={showDeleteModal} setIsOpen={setShowDeleteModal}>
        <H3 margin=" 0 0">Permanently Delete Location</H3>
        <Text margin="l 0 xl">
          This action is irreversible. For temporary closures, simply unassign
          the location from your live listings instead.
        </Text>
        <ButtonWrap margin="0">
          <Button
            size="s"
            onClick={() => setShowDeleteModal(false)}
            margin="0 0 0 0"
            buttonType="secondary"
          >
            Cancel
          </Button>
          <Button
            size="s"
            buttonType="destructive"
            onClick={() =>
              deleteLocation.mutate(
                {
                  locationId: id,
                },
                {
                  onError: (e) => {
                    setError(e as string);
                  },
                  onSuccess: (res) => {
                    toast.success("Location deleted");
                    track("Delete location", {
                      id: res.deleteLocation?.id
                        ? res.deleteLocation.id
                        : undefined,
                      brand: brand ? brand.name : undefined,
                    });
                    queryClient.resetQueries(
                      ["BrandLocations", "BrandLocation"],
                      {
                        exact: false,
                      }
                    );
                    history.push("/b/locations");
                  },
                }
              )
            }
          >
            {deleteLocation.isLoading ? "Deleting..." : "Delete"}
          </Button>
        </ButtonWrap>
      </Modal>

      <form onSubmit={onSubmit}>
        <StyledCard margin="l 0 xl">
          <Flex
            margin="0 0 l"
            align="center"
            justify="space-between"
            direction="row"
          >
            <H2 margin="0">Edit Location</H2>
            <TrashWrap onClick={() => setShowDeleteModal(true)}>
              <TrashIcon height={16} width={16} colorPreset="secondary" />
            </TrashWrap>
          </Flex>
          <Input
            label="Name"
            defaultValue={data.location.name}
            name="name"
            margin="0 0 l 0"
          />
          <Input
            margin="0 0 l 0"
            label="Email"
            defaultValue={data.location.email}
            name="email"
            placeholder={`e.g. borough@${domain}`}
            help="Add a location email to let them know about confirmed bookings"
          />

          <Input
            margin="0 0 l 0"
            label="Phone"
            defaultValue={data.location.phone}
            name="phone"
          />

          {data.location.instagramHandle ? (
            <div>
              <Text weight="semi" margin="s 0 s">
                Instagram
              </Text>

              <View margin="0 0 m">
                <div style={{ position: "relative" }}>
                  <AbsoluteWrap>
                    <CheckCircle checked={true} />
                  </AbsoluteWrap>
                  <Input
                    margin="0 0 s 0"
                    isDisabled={true}
                    value={`@${data.location.instagramHandle}`}
                    name="handle"
                  />
                </div>
              </View>
            </div>
          ) : null}

          {brand && brand.dmnEnabled ? (
            <>
              <Input
                margin="0 0 l 0"
                label="Access Collins site ID"
                defaultValue={data.location.dmnVenueId}
                name="dmnVenueId"
                placeholder={`eg. 512b2028d5d190d2978ca3b8`}
                help=" if you need help finding it"
                helpLink="https://accessgroup.my.site.com/Support/s/article/Access-Collins-Where-can-I-find-the-site-ID"
              />
            </>
          ) : null}

          <Flex margin="xl 0 0 0" justify="space-between" align="center">
            <div>
              <Text margin="0" weight="semi">
                Opening Hours
              </Text>
            </div>
            <InlineBtn
              label={editingOpeningHours ? "Save Hours" : "Edit Hours"}
              onClick={() => {
                setEditingOpeningHours(!editingOpeningHours);
              }}
            />
          </Flex>
          {editingOpeningHours ? (
            <>
              {openingHours.map((ehs, i) => {
                return (
                  <OpeningHours
                    location={data.location}
                    index={i}
                    key={`${ehs.dayOfWeek}-${ehs.startHour}-${ehs.startMinute}-${ehs.endHour}-${ehs.endMinute}`}
                    defaultValue={ehs}
                    onChange={(index, ehs) => {
                      const newOpeningHours = [...openingHours];
                      newOpeningHours.splice(index, 1);
                      newOpeningHours.splice(index, 0, ehs);

                      setOpeningHours(newOpeningHours);
                    }}
                    onRemove={(index) => {
                      const newOpeningHours = [...openingHours];
                      newOpeningHours.splice(index, 1);

                      setOpeningHours(newOpeningHours);
                    }}
                  />
                );
              })}
              <InlineAddBtn
                label="Add Hours"
                margin="m 0 m 0"
                onClick={() => {
                  const newOpeningHours = [...openingHours];
                  newOpeningHours.push({
                    dayOfWeek: "Monday",
                    startHour: 10,
                    startMinute: 0,
                    endHour: 18,
                    endMinute: 0,
                  });
                  setOpeningHours(newOpeningHours);
                }}
              />
            </>
          ) : (
            <DetailList
              margin="s 0 xl 0"
              fields={openingHours.map((ohs, i) => ({
                label: ohs.dayOfWeek,
                value: `${ohs.startHour < 10 ? "0" : ""}${ohs.startHour}:${
                  ohs.startMinute
                }${ohs.startMinute < 10 ? "0" : ""} - ${
                  ohs.endHour < 10 ? "0" : ""
                }${ohs.endHour}:${ohs.endMinute}${
                  ohs.endMinute < 10 ? "0" : ""
                }`,
              }))}
            />
          )}

          <View margin="m 0 0">
            {showAdvancedOptions ? (
              <OptionsToggleWrap onClick={() => setShowAdvancedOptions(false)}>
                <Chevron colorPreset="link" direction="down" />
                <Text weight="semi" colorPreset="link" margin="0 0 0 s">
                  Hide Advanced Options
                </Text>
              </OptionsToggleWrap>
            ) : (
              <OptionsToggleWrap onClick={() => setShowAdvancedOptions(true)}>
                <Chevron colorPreset="link" />
                <Text weight="semi" colorPreset="link" margin="0 0 0 s">
                  Show Advanced Options
                </Text>
              </OptionsToggleWrap>
            )}
            {showAdvancedOptions ? (
              <>
                <Text margin="xl 0 0 0" weight="semi">
                  Excluded Hours
                </Text>
                <Text size="s" colorPreset="secondary" margin="xs 0 0 0">
                  Block off certain hours of the week to prevent influencers
                  applying
                </Text>
                {excludedHours.map((ehs, i) => {
                  return (
                    <ExcludedHours
                      openingHours={openingHours}
                      index={i}
                      key={`${ehs.dayOfWeek}-${i}-${ehs.startHour}-${ehs.endHour}`}
                      defaultValue={ehs}
                      onChange={(index, ehs) => {
                        const newExcludedHours = [...excludedHours];
                        newExcludedHours.splice(index, 1);
                        newExcludedHours.splice(index, 0, ehs);

                        setExcludedHours(newExcludedHours);
                      }}
                      onRemove={(index) => {
                        setExcludedHours(
                          excludedHours.filter((_, i) => i !== index)
                        );
                      }}
                    />
                  );
                })}
                <InlineAddBtn
                  label="Add Hours"
                  margin="m 0 0"
                  onClick={() => {
                    const newExcludedHours = [...excludedHours];
                    defaultExcludedHours &&
                      newExcludedHours.push(defaultExcludedHours);
                    setExcludedHours(newExcludedHours);
                  }}
                />
              </>
            ) : null}
          </View>
        </StyledCard>

        <Submit
          margin="xl 0 xl 0"
          type="submit"
          value={updateLocation.isLoading ? "Saving..." : "Save"}
        />
      </form>
    </>
  );
};

interface ExcludedHoursProps {
  defaultValue: ExcludedHoursInput;
  openingHours: OpeningHoursInput[];
  onChange: (index: number, ehs: ExcludedHoursInput) => void;
  onRemove: (index: number) => void;
  index: number;
}

export function ExcludedHours(props: ExcludedHoursProps) {
  const [selectedDay, setSelectedDay] = useState("");
  const [startHour, setStartHour] = useState<number | undefined>();
  const [endHour, setEndHour] = useState<number | undefined>();
  const [startMinute, setStartMinute] = useState<number | undefined>();
  const [endMinute, setEndMinute] = useState<number | undefined>();

  useEffect(() => {
    setSelectedDay(props.defaultValue.dayOfWeek);
    setStartHour(props.defaultValue.startHour);
    setStartMinute(props.defaultValue.startMinute);
    setEndHour(props.defaultValue.endHour);
    setEndMinute(props.defaultValue.endMinute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      selectedDay === "" ||
      !startHour ||
      !endHour ||
      startMinute === undefined ||
      endMinute === undefined
    ) {
      return;
    }

    props.onChange(props.index, {
      startHour,
      startMinute,
      endHour,
      endMinute,
      dayOfWeek: selectedDay,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay, startHour, startMinute, endHour, endMinute]);

  const days = props.openingHours.map((ohs) => {
    return {
      value: ohs.dayOfWeek,
      label: ohs.dayOfWeek,
    };
  });

  days.push({
    value: "Everyday",
    label: "Everyday",
  });

  return (
    <Flex align="center" margin="m 0 0 0">
      <Select
        options={days}
        value={selectedDay}
        onSelect={(option) => {
          setSelectedDay(option.value);
        }}
      />

      <Select
        margin="0 0 0 m"
        value={formatTime(
          startHour ? startHour : props.defaultValue.startHour,
          startMinute ? startMinute : props.defaultValue.startMinute
        )}
        onSelect={(option) => {
          setStartHour(option.value.hour);
          setStartMinute(option.value.minute);
        }}
        options={getAvailableExcludedHours(props.openingHours, selectedDay)}
      ></Select>
      <Text margin="0 s 0 s">to</Text>
      <Select
        value={formatTime(
          endHour ? endHour : props.defaultValue.endHour,
          endMinute ? endMinute : props.defaultValue.endMinute
        )}
        margin="0 m 0 0"
        onSelect={(option) => {
          setEndHour(option.value.hour);
          setEndMinute(option.value.minute);
        }}
        options={getAvailableExcludedHours(props.openingHours, selectedDay)}
      ></Select>
      <div
        onClick={() => {
          props.onRemove(props.index);
        }}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        <CancelIcon />
      </div>
    </Flex>
  );
}

interface OpeningHoursProps {
  defaultValue: OpeningHoursInput;
  location: BrandLocationQuery["location"];
  onChange: (index: number, ehs: OpeningHoursInput) => void;
  onRemove: (index: number) => void;
  index: number;
}

function OpeningHours(props: OpeningHoursProps) {
  const [selectedDay, setSelectedDay] = useState("");
  const [startHour, setStartHour] = useState<number | undefined>();
  const [endHour, setEndHour] = useState<number | undefined>();
  const [startMinute, setStartMinute] = useState<number | undefined>();
  const [endMinute, setEndMinute] = useState<number | undefined>();

  useEffect(() => {
    setSelectedDay(props.defaultValue.dayOfWeek);
    setStartHour(props.defaultValue.startHour);
    setStartMinute(props.defaultValue.startMinute);
    setEndHour(props.defaultValue.endHour);
    setEndMinute(props.defaultValue.endMinute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      selectedDay === "" ||
      !startHour ||
      !endHour ||
      startMinute === undefined ||
      endMinute === undefined
    ) {
      return;
    }

    props.onChange(props.index, {
      startHour,
      startMinute,
      endHour,
      endMinute,
      dayOfWeek: selectedDay,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay, startHour, startMinute, endHour, endMinute]);

  const weekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    <Flex align="center" margin="m 0 0 0">
      <Select
        options={weekDays
          .sort((a, b) => {
            if (daysSortMap[a] < daysSortMap[b]) {
              return -1;
            }

            if (daysSortMap[a] > daysSortMap[b]) {
              return 1;
            }

            return 0;
          })
          .map((ohs) => {
            return {
              value: ohs,
              label: ohs,
            };
          })}
        value={selectedDay}
        onSelect={(option) => {
          setSelectedDay(option.value);
        }}
      />

      <Select
        margin="0 0 0 m"
        onSelect={(option) => {
          setStartHour(option.value.hour);
          setStartMinute(option.value.minute);
        }}
        value={`${
          startHour && startHour < 10 ? "0" : ""
        }${startHour}:${startMinute}${
          (startMinute && startMinute < 10) || startMinute === 0 ? "0" : ""
        }`}
        options={getAvailableHours()}
      ></Select>
      <Text margin="0 s 0 s">to</Text>
      <Select
        margin="0 m 0 0"
        onSelect={(option) => {
          setEndHour(option.value.hour);
          setEndMinute(option.value.minute);
        }}
        value={`${endHour && endHour < 10 ? "0" : ""}${endHour}:${endMinute}${
          (endMinute && endMinute < 10) || endMinute === 0 ? "0" : ""
        }`}
        options={getAvailableHours()}
      ></Select>
      <div
        onClick={() => {
          props.onRemove(props.index);
        }}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        <CancelIcon />
      </div>
    </Flex>
  );
}

interface TimeOption {
  label: string;
  value: {
    hour: number;
    minute: number;
  };
}

function getAvailableExcludedHours(
  ohs: OpeningHoursInput[],
  dayOfWeek: string
) {
  const values: TimeOption[] = [];

  const addAvailableHours = (startHour: number, endHour: number) => {
    // First add all hours except 0
    for (let i = startHour; i <= 23; i++) {
      values.push({
        label: `${i < 10 ? "0" : ""}${i}:00`,
        value: {
          hour: i,
          minute: 0,
        },
      });
      values.push({
        label: `${i < 10 ? "0" : ""}${i}:30`,
        value: {
          hour: i,
          minute: 30,
        },
      });
    }

    // Add 00:00 at the end if endHour is 0
    if (endHour === 0) {
      values.push({
        label: "00:00",
        value: {
          hour: 0,
          minute: 0,
        },
      });
    }
  };

  if (dayOfWeek === "Everyday") {
    let earliestStartHour = 23;
    let latestEndHour = 0;

    for (const oh of ohs) {
      if (oh.startHour < earliestStartHour) earliestStartHour = oh.startHour;
      if (oh.endHour > latestEndHour) latestEndHour = oh.endHour;
    }

    addAvailableHours(earliestStartHour, latestEndHour);
  } else {
    for (const oh of ohs) {
      if (oh.dayOfWeek !== dayOfWeek) {
        continue;
      }
      addAvailableHours(oh.startHour, oh.endHour);
    }
  }

  return values;
}

function getAvailableHours() {
  const hours = [];

  for (let i = 0; i < 24; i++) {
    hours.push({
      label: `${i < 10 ? "0" : ""}${i}:00`,
      value: {
        hour: i,
        minute: 0,
      },
    });

    hours.push({
      label: `${i < 10 ? "0" : ""}${i}:30`,
      value: {
        hour: i,
        minute: 30,
      },
    });
  }

  return hours;
}

const TrashWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: ${(p) => p.theme.spacing.m};

  &:hover {
    svg {
      filter: brightness(0.25);
      cursor: pointer;
    }
  }
`;

const AbsoluteWrap = styled.div`
  position: absolute;
  top: 0;
  right: ${(p) => p.theme.spacing.m};
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
`;
