import {
  addMinutes,
  format,
  fromUnixTime,
  getUnixTime,
  isToday,
  isYesterday,
  subDays,
} from "date-fns";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  BookingPostMediaType,
  BookingPostType,
  ConversationMessagesQuery,
  useConversationMessagesQuery,
  useCreateMessageMutation,
  useGetOrCreateConversationQuery,
} from "../graphql/generated";
import useGqlClient from "../hooks/useGqlClient";
import { authSelectors } from "../store/auth/selector";
import styled, { css } from "../styles";
import Avatar from "./Avatar";
import { SendMessageIcon } from "./icons/chat/SendMessageIcon";
import { MediaAsset } from "./MediaAsset";
import { Text } from "./Text";
import { View } from "./View";

const UPLOAD_BUTTON_HEIGHT = 32;
const AVATAR_SIZE = 36;

const Chat = ({
  brandId,
  creatorId,
}: {
  brandId: string;
  creatorId: string;
}) => {
  const [message, setMessage] = useState("");
  const messagesEndRef = React.useRef<HTMLDivElement>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const client = useGqlClient();
  const account = useSelector(authSelectors.account);
  const [textAreaFocused, setTextAreaFocused] = useState(false);
  const firstMessage = React.useMemo(() => subDays(new Date(), 1), []);

  const MESSAGES = [
    {
      id: "2",
      message: "Hey 👋",
      createdAt: getUnixTime(firstMessage),
      type: "text",
      account: {
        id: "2",
        firstName: "John",
        lastName: "Doe",
      },
    },
    {
      id: "1",
      message: "Hello, how are you?",
      createdAt: getUnixTime(addMinutes(firstMessage, 24)),
      type: "text",
      account: {
        id: account?.id,
        firstName: account?.firstName,
        lastName: account?.lastName,
      },
    },
    {
      id: "2",
      message: "I'm good, thank you!",
      createdAt: getUnixTime(addMinutes(firstMessage, 32)),
      type: "text",
      account: {
        id: "2",
        firstName: "John",
        lastName: "Doe",
      },
    },
    {
      id: "3",
      message: "",
      createdAt: getUnixTime(addMinutes(firstMessage, 67)),
      type: "attachment",
      attachmentUrl:
        "https://wenibble-images.s3.eu-central-1.amazonaws.com/posts/cre_2XLJ1BKLn4J2WVYO4Ehdy4NMoRx/18049245697929863.mp4",
      thumbnailUrl:
        "https://wenibble-images.s3.eu-central-1.amazonaws.com/post_thumbnails/cre_2XLJ1BKLn4J2WVYO4Ehdy4NMoRx/18049245697929863.jpg",
      account: {
        id: "2",
        firstName: "John",
        lastName: "Doe",
      },
    },
    {
      id: "4",
      message: `*Caption:*\n\nNew seafood afternoon tea in London 🦞☕️ ⁣
⁣
Smith’s is a part of a 4-storey restaurant and Grade II-listed building beside London’s famous Smithfield Market offers prime cuts of meat and locally sourced seasonal produce including day boat fish. ⁣
⁣
This iconic restaurant brings the freshest seafood to the heart of the city. Set in a chic, industrial setting, the afternoon tea features an array of delicately prepared seafood options.⁣
⁣
The menu typically includes treats like oysters, prawns, clams and stuffed crab, all prepared with high-quality, sustainably sourced ingredients.⁣
⁣
To complete the experience, diners can pair their meal with a glass of champagne or opt for a selection of fine teas, ensuring a luxurious and refreshing afternoon. ⁣
⁣
£49pp (including champagne) ⁣
⁣
The bustling Smiths Restaurant at⁣
‘Smiths’ of Smithfield is a 4-minute walk from Farringdon Tube Station.⁣
⁣
📍 Smiths, 67-77 Charterhouse St, London EC1M 6HJ

#londyn #londonblogger #londontravel #afternoontea #london4all #londonlove #londonafternoontea #travelblogger #londonrooftops #cityoflondon"`,
      createdAt: getUnixTime(addMinutes(firstMessage, 98)),
      type: "text",
      account: {
        id: "2",
        firstName: "John",
        lastName: "Doe",
      },
    },
    {
      id: "2",
      message: "How's that ☝️",
      createdAt: getUnixTime(addMinutes(firstMessage, 120)),
      type: "text",
      account: {
        id: "2",
        firstName: "John",
        lastName: "Doe",
      },
    },
  ] as ConversationMessagesQuery["conversationMessages"]["messages"] &
    {
      type: "text" | "attachment";
      attachmentUrl?: string;
      thumbnailUrl?: string;
    }[];

  // Create a sorted copy of MESSAGES instead of mutating the original array
  const messages = [...MESSAGES].sort((a, b) => a.createdAt - b.createdAt);

  const { data, isLoading } = useGetOrCreateConversationQuery(client, {
    brandId,
    creatorId,
  });

  const { mutate: createMessage } = useCreateMessageMutation(client);

  const { data: messagesData, refetch: refetchMessages } =
    useConversationMessagesQuery(
      client,
      {
        conversationID: data?.getOrCreateConversation?.conversation?.id || "",
        count: 50,
      },
      {
        enabled: !!data?.getOrCreateConversation?.conversation?.id,
      }
    );

  const handleSendMessage = () => {
    if (!data?.getOrCreateConversation?.conversation?.id) {
      return;
    }
    if (message.trim()) {
      setMessage("");
      // Reset textarea height
      if (textareaRef.current) {
        textareaRef.current.style.height = "auto";
      }
      createMessage(
        {
          conversationId: data.getOrCreateConversation.conversation.id,
          content: message,
        },
        {
          onSuccess: () => {
            refetchMessages();
          },
        }
      );
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height to recalculate
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  React.useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messagesData]);

  React.useEffect(() => {
    adjustTextareaHeight();
  }, []);

  // Format date for headers (Today, Yesterday, or date)
  const formatDateHeader = (date: Date) => {
    if (isToday(date)) {
      return "Today";
    } else if (isYesterday(date)) {
      return "Yesterday";
    } else {
      return format(date, "MMMM d, yyyy");
    }
  };

  // Group messages by date
  const groupMessagesByDate = () => {
    if (!messages) return [];

    const groups: { [key: string]: any[] } = {};

    messages.forEach((msg: any) => {
      const messageDate = fromUnixTime(msg.createdAt);
      const dateKey = format(messageDate, "yyyy-MM-dd");

      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }

      groups[dateKey].push(msg);
    });

    // Convert to array of objects with date and messages
    return Object.entries(groups).map(([dateKey, messages]) => ({
      dateKey,
      dateHeader: formatDateHeader(new Date(dateKey)),
      messages,
    }));
  };

  if (!brandId || !creatorId) {
    return null;
  }

  if (!data || isLoading) {
    return null;
  }

  console.log(messages);

  return (
    <ChatContainer>
      <MessagesContainer>
        {messages && messages.length > 0 ? (
          groupMessagesByDate().map((group) => (
            <React.Fragment key={group.dateKey}>
              <Text
                size="xs"
                colorPreset="secondary"
                margin="l 0 0"
                weight="semi"
                align="center"
                style={{ userSelect: "none" }}
              >
                {group.dateHeader}
              </Text>

              {group.messages.map((msg: any, index: number) => {
                const isSender = msg.account?.id === account?.id;
                const isLastNonSenderInGroup =
                  !isSender &&
                  (index === group.messages.length - 1 ||
                    group.messages[index + 1]?.account?.id === account?.id);

                return (
                  <MessageWrap key={msg.id} isSender={isSender}>
                    <MessageRow>
                      {!isSender && (
                        <AvatarWrap>
                          {isLastNonSenderInGroup ? (
                            <Avatar
                              size={AVATAR_SIZE}
                              url={
                                data.getOrCreateConversation?.conversation
                                  ?.creator?.preferredProfile?.avatar
                              }
                              borderSize={0}
                              withShadow={false}
                            />
                          ) : (
                            <View />
                          )}
                        </AvatarWrap>
                      )}
                      <div>
                        <Text
                          size="xxs"
                          colorPreset="secondary"
                          margin="0 xs 0"
                          align={isSender ? "right" : "left"}
                        >
                          {format(fromUnixTime(msg.createdAt), "h:mm a")}
                        </Text>

                        {msg.type === "text" ? (
                          <MessageBubble isSender={isSender}>
                            <Text
                              size="s"
                              margin="0 0 0"
                              style={{ whiteSpace: "pre-wrap" }}
                            >
                              {msg.message}
                            </Text>
                          </MessageBubble>
                        ) : (
                          <MediaWrap>
                            <MediaAsset
                              mediaType={
                                BookingPostMediaType.BookingPostMediaTypeVideo
                              }
                              postType={BookingPostType.BookingPostTypeReel}
                              mediaUrl={msg.attachmentUrl}
                              thumbnailUrl={msg.thumbnailUrl}
                              permalink={msg.attachmentUrl}
                              borderRadius="full"
                            />
                          </MediaWrap>
                        )}
                      </div>
                    </MessageRow>
                  </MessageWrap>
                );
              })}
            </React.Fragment>
          ))
        ) : (
          <View style={{ flex: 1 }}>
            <Text
              size="xs"
              colorPreset="secondary"
              margin="0 0 0"
              weight="semi"
              align="center"
              style={{ userSelect: "none" }}
            >
              Today
            </Text>
            <View
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90%",
                maxWidth: "70%",
                margin: "0 auto",
                flex: 1,
              }}
            >
              <Text
                size="s"
                margin="0 0 0"
                align="center"
                colorPreset="secondary"
              >
                No previous messages between you and @
                {
                  data.getOrCreateConversation?.conversation?.creator
                    ?.preferredProfile?.username
                }
              </Text>
            </View>
          </View>
        )}
        <div ref={messagesEndRef} />
      </MessagesContainer>
      <ChatFooter>
        <ChatInputWrapper isFocused={textAreaFocused}>
          <ChatInput
            ref={textareaRef}
            placeholder="Write a message..."
            value={message}
            onChange={handleMessageChange}
            onKeyDown={handleKeyPress}
            rows={4}
            onFocus={() => setTextAreaFocused(true)}
            onBlur={() => setTextAreaFocused(false)}
          />
          <MessageSendButton onClick={handleSendMessage}>
            <SendMessageIcon
              width={UPLOAD_BUTTON_HEIGHT}
              height={UPLOAD_BUTTON_HEIGHT}
              colorPreset="link"
            />
          </MessageSendButton>
        </ChatInputWrapper>
      </ChatFooter>
    </ChatContainer>
  );
};

export default Chat;

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
`;

const MessagesContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing.s};
  padding: ${(p) => p.theme.spacing.l} ${(p) => p.theme.spacing.l} 0;

  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.color.typography.secondary}30;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${(p) => p.theme.color.typography.secondary}50;
    opacity: 1;
  }

  /* Show scrollbar when hovering or actively scrolling */
  &:hover::-webkit-scrollbar-thumb,
  &:active::-webkit-scrollbar-thumb {
    opacity: 1;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &:hover,
  &:active {
    scrollbar-color: ${(p) => p.theme.color.typography.secondary}30 transparent;
  }
`;

const MessageBubble = styled.div<{ isSender: boolean }>`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  background-color: ${(p) =>
    p.isSender
      ? `${p.theme.color.primary}25`
      : `${p.theme.color.typography.secondary}18`};

  ${(p) =>
    p.isSender
      ? css`
          border-bottom-right-radius: 0;
        `
      : css`
          border-bottom-left-radius: 0;
        `}
`;

const MessageWrap = styled.div<{ isSender: boolean }>`
  max-width: 80%;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  align-self: ${(p) => (p.isSender ? "flex-end" : "flex-start")};
  margin-bottom: ${(p) => p.theme.spacing.s};
`;

const ChatFooter = styled.div`
  padding: ${(p) => p.theme.spacing.m};
`;

const ChatInputWrapper = styled.div<{ isFocused: boolean }>`
  position: relative;
  width: 100%;
  border-radius: ${(p) => p.theme.misc.borderRadiusLarge};
  box-sizing: border-box;
  padding: ${(p) => p.theme.spacing.m};
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing.m};
  justify-content: space-between;
  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;

  ${(p) =>
    p.isFocused &&
    css`
      border-color: ${(p) => p.theme.color.primary};
      box-shadow: 0px 0px 0px 2px ${(p) => p.theme.color.input.hover};
      outline: none;
    `}
`;

const ChatInput = styled.textarea`
  flex: 1;
  border: none;
  outline: none;
  resize: none;
  min-height: 24px;
  max-height: 420px;
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
  font-size: ${(p) => p.theme.typography.size.m};
  font-weight: ${(p) => p.theme.typography.weight.normal};
  color: ${(p) => p.theme.color.typography.text};
  line-height: 1.4;
  padding: 0;
  background: transparent;

  /* Custom scrollbar styling - smaller and subtle */
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(p) => p.theme.color.typography.secondary}30;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: ${(p) => p.theme.color.typography.secondary}50;
    opacity: 1;
  }

  /* Show scrollbar when hovering or actively scrolling */
  &:hover::-webkit-scrollbar-thumb,
  &:active::-webkit-scrollbar-thumb {
    opacity: 1;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &:hover,
  &:active {
    scrollbar-color: ${(p) => p.theme.color.typography.secondary}30 transparent;
  }
`;

const MessageSendButton = styled.div`
  margin-top: auto;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  transition: filter 0.15s ease;

  &:hover {
    filter: brightness(0.9);
  }
`;

const MessageRow = styled.div`
  display: flex;
  align-items: flex-end;
  gap: ${(p) => p.theme.spacing.xs};
`;

const AvatarWrap = styled.div`
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
`;

const MediaWrap = styled.div`
  width: 100%;
  height: 100%;
  max-width: 260px;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  overflow: hidden;
`;
