import { useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { MOBILE_BREAKPOINT } from "../config";
import useAnalytics from "../hooks/useAnalytics";
import { useOutsideAlerter } from "../hooks/useOutsideAlerter";
import { useTheme } from "../hooks/useTheme";
import { useWindowSize } from "../hooks/useWindowSize";
import { authSelectors } from "../store/auth/selector";
import { actions } from "../store/auth/slice";
import styled, { css } from "../styles";
import { darkTheme, lightTheme } from "../styles/theme";
import withMargin from "../styles/withMargin";
import { Avatar } from "./Avatar";
import { Card } from "./Card";
import { Chevron } from "./Chevron";
import { Flex } from "./Flex";
import { InlineAddBtn } from "./NewButton";
import { Badge } from "./Tab";
import { Tag } from "./Tag";
import { Text } from "./Text";
import { View } from "./View";

const AvatarWrap = styled(Flex)`
  border-radius: ${(p) => p.theme.misc.borderRadius};
  box-shadow: ${(p) => p.theme.shadow.card};
  color: ${(p) => p.theme.color.typography.secondary};

  &:hover,
  &.isActive {
    box-shadow: ${(p) => p.theme.shadow.cardHeavy};

    svg {
      path {
        fill: ${(p) => p.theme.color.secondary};
      }
    }
  }
`;

interface Props {
  isSignupHeader?: boolean;
}

export const AccountSwitcher = ({ isSignupHeader }: Props) => {
  const activeCreatorId = useSelector(authSelectors.activeCreatorId);
  const brand = useSelector(authSelectors.activeBrand);
  const account = useSelector(authSelectors.account);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { track } = useAnalytics();
  const wrapperRef = useRef(null);
  const windowSize = useWindowSize();
  const theme = useTheme();

  const queryClient = useQueryClient();

  const [showDropdown, setShowDropdown] = useState(false);

  useOutsideAlerter(wrapperRef, () => {
    setShowDropdown(false);
  });

  let avatarUrl;

  if (brand?.logo) {
    avatarUrl = brand.logo;
  } else if (brand?.instagram?.avatar) {
    avatarUrl = brand.instagram.avatar;
  } else if (brand?.tikTok) {
    avatarUrl = brand.tikTok.avatar;
  } else {
    avatarUrl =
      "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_avatars/empty.jpeg";
  }

  const brandNavigation =
    brand &&
    windowSize.width &&
    windowSize.width >= MOBILE_BREAKPOINT &&
    !location.pathname.startsWith("/signup/");

  const sortedBrands =
    account &&
    account.brands &&
    account.brands.slice().sort((a, b) => a.name.localeCompare(b.name));

  const hasApplicationsToReview = sortedBrands
    ? sortedBrands
        .filter((b) => b.id !== brand?.id)
        .some((b) => b.numApplicationsToReview > 0)
    : false;

  return (
    <SwitcherWrap
      brandNavigation={brandNavigation ? true : false}
      ref={wrapperRef}
      onClick={() => setShowDropdown((s) => !s)}
    >
      {brandNavigation ? (
        <AvatarWrap justify="space-between" align="center">
          <Flex
            margin="s m"
            style={{ gap: lightTheme.spacing.m }}
            align="center"
          >
            <Avatar
              withShadow={false}
              borderSize={0}
              size={32}
              url={avatarUrl}
            />
            {hasApplicationsToReview ? (
              <AbsoluteWrap className="account-switcher-indicator">
                <NotificationIndicator isActive={!showDropdown} />
              </AbsoluteWrap>
            ) : null}

            <div className="account-switcher">
              <Flex direction="column" margin="0 xs 0 0">
                <BrandName truncate>{brand?.name}</BrandName>
              </Flex>
            </div>
          </Flex>
          {/* <AccountSwitcherIcon colorPreset="secondary" /> */}
          <div className="account-switcher">
            <View margin="0 m 0 0">
              <Chevron
                animateTransition
                size="s"
                direction={showDropdown ? undefined : "down"}
              />
            </View>
          </div>
        </AvatarWrap>
      ) : (
        <Avatar
          url={
            avatarUrl
              ? avatarUrl
              : "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_avatars/empty.jpeg"
          }
        />
      )}

      {showDropdown ? (
        <DropdownWrap brandNavigation={brandNavigation ? true : false}>
          <Dropdown
            themeName={theme.name as "light" | "dark"}
            shadow="heavy"
            brandNavigation={brandNavigation ? true : false}
            padding="s 0"
            isSignupHeader={isSignupHeader}
          >
            {sortedBrands && sortedBrands.length > 0 && (
              <>
                {sortedBrands.map((b, i) => (
                  <Row
                    key={i}
                    onClick={() => {
                      dispatch(actions.setActiveBrand({ brandId: b.id }));
                      track("Switch brand", { brand: b.name });
                      queryClient.resetQueries(
                        [
                          "myBookings",
                          "BookingPosts",
                          "BrandLocations",
                          "BrandListings",
                          "brandBookings",
                          "Reports",
                          "GetBrand",
                          "Brands",
                          "Billing",
                          "BrandContent",
                          "Accounts",
                          "BrandNotificationCount",
                        ],
                        { exact: false }
                      );

                      if (location.pathname.startsWith("/i")) {
                        history.push("/b");
                        return;
                      }

                      if (location.pathname.startsWith("/b/bookings/")) {
                        history.push("/b/bookings");
                        return;
                      }

                      if (location.pathname.startsWith("/b/listings/")) {
                        history.push("/b/listings");
                        return;
                      }

                      if (location.pathname.startsWith("/b/locations/")) {
                        history.push("/b/locations");
                        return;
                      }

                      if (location.pathname.startsWith("/b/reports/")) {
                        history.push("/b/reports");
                        return;
                      }

                      history.replace(location.pathname);
                    }}
                  >
                    <Flex
                      direction="row"
                      justify="space-between"
                      align="center"
                    >
                      <Flex direction="column">
                        <Title>{b.name}</Title>
                      </Flex>
                      {brand && b.id === brand.id ? (
                        <Tag
                          shadow={false}
                          padding="l"
                          size="s"
                          color="purple"
                          text="Active"
                        />
                      ) : b.numApplicationsToReview > 0 ? (
                        <>
                          <div style={{ marginLeft: "auto" }}>
                            <Badge>{b.numApplicationsToReview}</Badge>
                          </div>
                        </>
                      ) : null}
                    </Flex>
                  </Row>
                ))}
                <Row onClick={() => history.push("/signup/b/create-brand")}>
                  <InlineAddBtn label="Add brand" margin="0" />
                </Row>
              </>
            )}

            {activeCreatorId ? (
              <Row
                onClick={() => {
                  if (
                    location.pathname.startsWith("/i/") ||
                    location.pathname.startsWith("/signup/i/")
                  ) {
                    history.push("/i/settings");
                  } else {
                    history.push("/b/settings");
                  }
                }}
              >
                <Title>Settings</Title>
              </Row>
            ) : null}
          </Dropdown>
        </DropdownWrap>
      ) : null}
    </SwitcherWrap>
  );
};

const AbsoluteWrap = styled.div`
  position: absolute;
  right: 4px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  transition: right 150ms ease-out;
`;

const NotificationIndicator = styled.span<{ isActive: boolean }>`
  height: ${(p) => p.theme.spacing.xs};
  width: ${(p) => p.theme.spacing.xs};
  border-radius: 100%;
  background-color: ${(p) => p.theme.color.primary};
  opacity: ${(p) => (p.isActive ? 1 : 0)};
  transition: opacity 150ms ease-out;
`;

// To fix the issue of the account switcher being visible on mobile overalys use excluded URLs in BrandNavigation.tsx
const SwitcherWrap = styled.div<{ brandNavigation: boolean }>`
  position: relative;
  user-select: none;
  z-index: 99;
  ${(p) =>
    p.brandNavigation &&
    css`
      width: 100%;
      cursor: pointer;
    `}
`;

const DropdownWrap = styled.div<{ brandNavigation: boolean }>`
  position: absolute;
  right: 0;
  z-index: 99;
  width: 320px;

  ${(p) =>
    p.brandNavigation
      ? css`
          @media (min-width: ${MOBILE_BREAKPOINT}px) {
            left: 100%;
            top: -${(p) => p.theme.spacing.m};
            padding-left: ${(p) => p.theme.spacing.l};
          }
        `
      : null}
`;

interface DropdownProps {
  brandNavigation: boolean;
  themeName: "light" | "dark";
  isSignupHeader?: boolean;
}

const Dropdown = styled(Card)<DropdownProps>`
  z-index: 99;
  overflow-y: scroll;
  max-height: 90vh;
  margin-top: ${(p) => p.theme.spacing.m};

  ${(p: DropdownProps) =>
    p.brandNavigation &&
    !p.isSignupHeader &&
    css`
      background: ${(p: DropdownProps) =>
        p.themeName === "light"
          ? lightTheme.color.background.behind
          : darkTheme.color.background.behind} !important;
    `}

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    background: ${(p) => p.theme.color.card.background} !important;
  }

  ${(p) =>
    p.brandNavigation
      ? css`
          @media (min-width: ${MOBILE_BREAKPOINT}px) {
            left: 100%;
            /* top: ${(p) => p.theme.spacing.xxl}; */
            top: -${(p) => p.theme.spacing.m};
          }
          box-shadow: ${(p) => p.theme.shadow.cardHeavy};
        `
      : null}
`;

const Row = styled.div`
  padding: calc(${(p) => p.theme.spacing.s} + 2px) ${(p) => p.theme.spacing.l};
  color: ${(p) => p.theme.color.typography.secondary};
  margin: 0 ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.misc.borderRadius};

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover,
  &.isActive {
    background-color: ${(p) => p.theme.color.input.hover};

    p {
      color: ${(p) => p.theme.color.primary};
    }

    svg {
      path {
        fill: ${(p) => p.theme.color.primary};
      }
    }
  }

  cursor: pointer;
`;

const Title = styled.p`
  font-family: ${(p) => p.theme.typography.bodyFamily};
  color: ${(p) => p.theme.color.typography.heading};
  font-size: ${(p) => p.theme.typography.size.m};
  line-height: 1.3em;
  font-weight: ${(p) => p.theme.typography.weight.semi};
  margin: 0;
`;

const BrandName = styled(Text)<{ margin?: string }>`
  font-family: ${(p) => p.theme.typography.bodyFamily};
  font-size: ${(p) => p.theme.typography.size.s};
  color: ${(p) => p.theme.color.typography.secondary};
  line-height: 1.3em;
  margin: 0;
  ${withMargin};
`;
