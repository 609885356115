import React from "react";
import { MOBILE_BREAKPOINT } from "../config";
import { styled } from "../styles";
import { Flex } from "./Flex";
import { Text } from "./Text";

interface Tab {
  id: string;
  label: string;
  itemCount?: number;
  icon?: React.ReactNode;
}

interface TabbedContainerProps {
  tabs: Tab[];
  activeTab: string;
  onTabChange: (tabId: string) => void;
  content: React.ReactNode;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(p) => p.theme.color.card.background};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  box-shadow: ${(p) => p.theme.shadow.card};
  box-sizing: border-box;
  overflow: hidden;
`;

const TabsHeader = styled.div`
  display: flex;
  width: 100%;
  background: ${(p) => p.theme.color.card.callout};
  border-bottom: 1px solid ${(p) => p.theme.color.card.divider};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const TabButton = styled.button<{ active: boolean; isHovered: boolean }>`
  flex: 1;
  border: none;
  background: ${(p) =>
    p.active || p.isHovered ? p.theme.color.card.background : "transparent"};
  color: ${(p) => p.theme.color.typography.text};
  padding: ${(p) => p.theme.spacing.l} ${(p) => p.theme.spacing.xl};
  box-sizing: border-box;
  font-size: ${(p) => p.theme.typography.size.m};
  font-weight: ${(p) => p.theme.typography.weight.semi};
  cursor: pointer;
  position: relative;
  border-right: 1px solid ${(p) => p.theme.color.card.divider};

  &:last-child {
    border-right: none;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex: 0 0 auto;
    min-width: max-content;
    padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.xl};

    .tab-icon {
      display: none;
    }
  }
`;

export const TabbedContainer: React.FC<TabbedContainerProps> = ({
  tabs,
  activeTab,
  onTabChange,
  content,
}) => {
  const [hoveredTabId, setHoveredTabId] = React.useState<string | null>(null);

  return (
    <Container>
      <TabsHeader>
        {tabs.map((tab) => {
          const isActive = activeTab === tab.id;
          const isHovered = hoveredTabId === tab.id;

          return (
            <TabButton
              key={tab.id}
              active={isActive}
              isHovered={isHovered}
              onClick={() => onTabChange(tab.id)}
              onMouseEnter={() => setHoveredTabId(tab.id)}
              onMouseLeave={() => setHoveredTabId(null)}
            >
              <Flex align="center" justify="flex-start">
                <Flex margin="0 m 0 0" className="tab-icon">
                  {tab.icon && (
                    <div className={isActive || isHovered ? "active" : ""}>
                      {tab.icon}
                    </div>
                  )}
                </Flex>
                <Flex justify="flex-start" direction="column">
                  <Text margin="0" weight="bold" align="left" isCompact>
                    {tab.label}
                  </Text>
                  <Text
                    isCompact
                    margin="0"
                    size="s"
                    colorPreset="secondary"
                    align="left"
                  >
                    {tab.itemCount} items
                  </Text>
                </Flex>
              </Flex>
            </TabButton>
          );
        })}
      </TabsHeader>
      {content}
    </Container>
  );
};
