import posthog from "posthog-js";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { MOBILE_BREAKPOINT } from "../../config";
import useAnalytics from "../../hooks/useAnalytics";
import { useOutsideAlerter } from "../../hooks/useOutsideAlerter";
import { useTheme } from "../../hooks/useTheme";
import { useWindowSize } from "../../hooks/useWindowSize";
import { authSelectors } from "../../store/auth/selector";
import { actions } from "../../store/auth/slice";
import styled from "../../styles";
import { darkTheme, lightTheme } from "../../styles/theme";
import withMargin from "../../styles/withMargin";
import Avatar from "../Avatar";
import { Card } from "../Card";
import { Chevron } from "../Chevron";
import { CardDivider } from "../Divider";
import { Flex } from "../Flex";
import { AccountSettingsIcon } from "../icons/AccountSettingsIcon";
import { LocationsIcon } from "../icons/LocationsIcon";
import { LogoutIcon } from "../icons/LogoutIcon";
import { SettingsIcon } from "../icons/SettingsIcon";
import { SupportChatIcon } from "../icons/SupportChatIcon";
import { Text } from "../Text";
import { View } from "../View";

const AvatarWrap = styled(Flex)`
  border-radius: ${(p) => p.theme.misc.borderRadius};

  display: flex;
  align-items: center;
  font-size: ${(p) => p.theme.typography.size.s};
  font-weight: ${(p) => p.theme.typography.weight.semi};
  color: ${(p) => p.theme.color.typography.text};
  text-decoration: none;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  line-height: 130%;
  cursor: pointer;

  &:hover,
  &.isActive {
    color: ${(p) => p.theme.color.primary};
    background-color: ${(p) => p.theme.color.input.hover};
    svg {
      path {
        fill: ${(p) => p.theme.color.primary};
      }
    }
  }

  min-width: 0;
`;

const getInitials = (name: string): string => {
  return name
    .split(" ")
    .map((part) => part[0])
    .join("")
    .toUpperCase()
    .slice(0, 2);
};

interface InitialsAvatarProps {
  size: number;
  name: string;
  url?: string;
}

const InitialsAvatar = styled.div<InitialsAvatarProps>`
  min-width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  background-color: ${(props) => props.theme.color.primary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  background-color: ${(props) => props.theme.color.primary};
`;

const OnlineStatusDot = styled.div<{ darkMode: boolean }>`
  width: 8px;
  height: 8px;
  background-color: ${(props) => darkTheme.color.constructive};
  border-radius: 50%;
  position: absolute;
  bottom: -3px;
  right: 0;
  border: 2px solid
    ${(props) =>
      props.darkMode
        ? darkTheme.color.background.behind
        : lightTheme.color.card.background};
`;

export const UserSettingsSwitcher = () => {
  const account = useSelector(authSelectors.account);
  const dispatch = useDispatch();
  const location = useLocation();
  const windowSize = useWindowSize();
  const history = useHistory();
  const { track } = useAnalytics();
  const wrapperRef = useRef(null);
  const theme = useTheme();
  const [showDropdown, setShowDropdown] = useState(false);
  const { hardShutdown, show: showIntercom } = useIntercom();

  let enableUgc = useFeatureFlagEnabled("ugc");

  useOutsideAlerter(wrapperRef, () => {
    setShowDropdown(false);
  });

  const avatarUrl =
    "https://wenibble-images.s3.eu-central-1.amazonaws.com/tiktok_avatars/empty.jpeg";

  const name = account?.firstName
    ? `${account.firstName} ${account.lastName}`
    : account?.email;

  const email = account?.email;

  const initials = useMemo(() => {
    return name ? getInitials(name) : "??";
  }, [name]);

  const darkMode =
    (windowSize.width &&
      windowSize.width >= MOBILE_BREAKPOINT &&
      !location.pathname.startsWith("/signup/")) ||
    (windowSize.width &&
      windowSize.width <= MOBILE_BREAKPOINT &&
      theme.name === "dark");

  return (
    <SwitcherWrap onClick={() => setShowDropdown((s) => !s)} ref={wrapperRef}>
      <AvatarWrap justify="space-between" align="center">
        <Flex
          margin="s m"
          style={{ gap: lightTheme.spacing.m, flex: 1, minWidth: 0 }}
          align="center"
        >
          {name === "" ? (
            <Avatar
              withShadow={false}
              borderSize={0}
              size={32}
              url={avatarUrl}
            />
          ) : (
            <InitialsAvatar size={34} name={name || ""}>
              <Text
                size="s"
                weight="bold"
                margin="0"
                style={{
                  userSelect: "none",
                  marginBottom: -2,
                  color: darkTheme.color.typography.text,
                }}
              >
                {initials}
              </Text>
              <OnlineStatusDot darkMode={darkMode ? true : false} />
            </InitialsAvatar>
          )}

          <Flex
            direction="column"
            margin="0 xs 0 0"
            style={{ minWidth: 0, flex: 1, width: "100%", overflow: "hidden" }}
          >
            <Title>{name}</Title>
            <Username>{email}</Username>
          </Flex>
        </Flex>
        <div className="account-switcher">
          <View margin="0 m 0 0" style={{ marginTop: 4 }}>
            <Chevron
              animateTransition
              size="s"
              direction={showDropdown ? undefined : "down"}
            />
          </View>
        </div>
      </AvatarWrap>

      {showDropdown ? (
        <DropdownWrap>
          <Dropdown
            themeName={theme.name as "light" | "dark"}
            shadow="heavy"
            padding="s 0"
          >
            {enableUgc ? (
              <>
                <Row
                  onClick={() => {
                    history.push("/b/settings");
                  }}
                >
                  <Flex align="center" gap="s">
                    <SettingsIcon
                      width={22}
                      className="hover-stroke"
                      colorPreset="text"
                    />
                    <Title style={{ marginTop: 3 }}>Brand Settings</Title>
                  </Flex>
                </Row>
                <Row
                  onClick={() => {
                    history.push("/b/locations");
                  }}
                >
                  <Flex align="center" gap="s">
                    <LocationsIcon
                      width={22}
                      className="hover-stroke"
                      colorPreset="text"
                    />
                    <Title>Locations</Title>
                  </Flex>
                </Row>
                <View margin="xs 0">
                  <CardDivider />
                </View>
              </>
            ) : null}
            <Row
              onClick={() => {
                history.push("/b/account-settings");
              }}
            >
              <Flex align="center" gap="s">
                <AccountSettingsIcon
                  width={22}
                  className="hover-path"
                  colorPreset="text"
                />
                <Title>Account Settings</Title>
              </Flex>
            </Row>
            <Row
              onClick={() => {
                showIntercom();
              }}
            >
              <Flex align="center" gap="s">
                <SupportChatIcon
                  width={22}
                  className="hover-path"
                  colorPreset="text"
                />
                <Title>Live Support Chat</Title>
              </Flex>
            </Row>
            <Row
              onClick={() => {
                dispatch(actions.logout());
                posthog.reset();
                hardShutdown();
                track("Logout");
              }}
            >
              <Flex align="center" gap="s">
                <Flex
                  align="center"
                  justify="center"
                  style={{ marginLeft: -1, marginTop: -2 }}
                >
                  <LogoutIcon
                    width={22}
                    className="hover-path"
                    colorPreset="text"
                  />
                </Flex>
                <Title>Log Out</Title>
              </Flex>
            </Row>
          </Dropdown>
        </DropdownWrap>
      ) : null}
    </SwitcherWrap>
  );
};

const SwitcherWrap = styled.div`
  position: relative;
  user-select: none;
  z-index: 99;
  width: 100%;
  cursor: pointer;
`;

const DropdownWrap = styled.div`
  position: absolute;
  right: 0;
  z-index: 99;
  width: 220px;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    left: 100%;
    bottom: ${(p) => p.theme.spacing.xs};
    padding-left: ${(p) => p.theme.spacing.l};
  }
`;

interface DropdownProps {
  themeName: "light" | "dark";
}

const Dropdown = styled(Card)<DropdownProps>`
  z-index: 99;
  overflow-y: scroll;
  max-height: 90vh;
  margin-top: ${(p) => p.theme.spacing.m};

  background: ${(p: DropdownProps) =>
    p.themeName === "light"
      ? lightTheme.color.background.behind
      : darkTheme.color.background.behind} !important;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    background: ${(p) => p.theme.color.card.background} !important;
  }
`;

const Row = styled.div`
  padding: calc(${(p) => p.theme.spacing.s} + 2px) ${(p) => p.theme.spacing.l};
  color: ${(p) => p.theme.color.typography.secondary};
  margin: 0 ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover,
  &.isActive {
    background-color: ${(p) => p.theme.color.input.hover};

    p {
      color: ${(p) => p.theme.color.primary};
    }

    .hover-stroke {
      path {
        stroke: ${(p) => p.theme.color.primary};
      }
    }

    .hover-path {
      path {
        fill: ${(p) => p.theme.color.primary};
      }
    }
  }

  cursor: pointer;
`;

const Title = styled.p`
  font-family: ${(p) => p.theme.typography.bodyFamily};
  color: ${(p) => p.theme.color.typography.text};
  font-size: ${(p) => p.theme.typography.size.s};
  line-height: 1.3em;
  font-weight: ${(p) => p.theme.typography.weight.semi};
  margin: 0;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
`;

const Username = styled.p<{ margin?: string }>`
  font-family: ${(p) => p.theme.typography.bodyFamily};
  color: ${(p) => p.theme.color.typography.secondary};
  font-size: ${(p) => p.theme.typography.size.xs};
  line-height: 1.3em;
  margin: 0;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
  ${withMargin};
`;
