import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Divider } from "../../components/Divider";
import { Flex } from "../../components/Flex";
import { H1 } from "../../components/Heading";
import Loading from "../../components/Loading";
import { LocationCard } from "../../components/LocationCard";
import { NewButton } from "../../components/NewButton";
import { Text } from "../../components/Text";
import { MOBILE_BREAKPOINT } from "../../config";
import { useBrandLocationsQuery } from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import { styled } from "../../styles";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 600px;
  text-align: left;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${(p) => p.theme.spacing.l};
  }
`;

const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
`;

export interface MatchParams {
  page: string;
}

export const BrandLocations = () => {
  return (
    <Wrap>
      <HeaderWrap>
        <div style={{ flex: 1 }}>
          <H1 margin="0">Locations</H1>
        </div>
        <div>
          <NewButton label="New location" to="/b/create-location" />
        </div>
      </HeaderWrap>
      <Divider margin="xl 0 xxl 0" />
      <Locations />
    </Wrap>
  );
};

const CenteredContainer = styled.div`
  flex: 1;
  display: flex;
  align-content: center;
`;

const PageWrap = styled.div`
  flex: 1;
`;

const ContentWrap = styled.div`
  flex: 1;
`;

export function Locations() {
  const client = useGqlClient();
  const brandId = useSelector(authSelectors.activeBrandId);
  const { data, isLoading, error } = useBrandLocationsQuery(
    client,
    {
      brandId: brandId ? brandId : "",
    },
    {
      enabled: brandId !== null,
    }
  );

  if (isLoading) {
    return <Loading />;
  }

  if (error || !data || !data.brand) {
    return <Text>Something went wrong</Text>;
  }

  return (
    <CenteredContainer>
      <PageWrap>
        <ContentWrap>
          {data.brand.locations.locations.length === 0 ? (
            <Flex direction="row" align="center" justify="center">
              <Text align="center">
                <b>No locations</b> <br />
                Get started by creating a new location
              </Text>
            </Flex>
          ) : (
            <div>
              {data.brand.locations.locations
                .sort(function (a, b) {
                  return a.name.localeCompare(b.name);
                })
                .map((loc) => {
                  return (
                    <CardLink to={`/b/locations/${loc.id}`} key={loc.id}>
                      <LocationCard
                        dmnVenueId={loc.dmnVenueId}
                        address={loc.address}
                        phone={loc.phone}
                        lat={loc.lat}
                        lng={loc.lng}
                        email={loc.email}
                        name={loc.name}
                      />
                    </CardLink>
                  );
                })}
            </div>
          )}
        </ContentWrap>
      </PageWrap>
    </CenteredContainer>
  );
}

const CardLink = styled(Link)`
  text-decoration: none;
  display: block;
  margin-bottom: ${(p) => p.theme.spacing.m};
`;
