import {
  addDays,
  addHours,
  addMinutes,
  format,
  getUnixTime,
  subDays,
  subMinutes,
} from "date-fns";
import {
  BookingEventPerformerType,
  BookingEventType,
  BookingPostType,
  BookingStatus,
  BookingTimeSlot,
  GetBookingQuery,
} from "../graphql/generated";
import { BookingTabs } from "./enums";

type DemoBooking = {
  id: string;
  bookings: {
    id: string;
    status: BookingStatus;
    tab: BookingTabs;
    paid?: boolean;
    events: GetBookingQuery["booking"]["bookingEvents"];
  }[];
};

export const demoBookingConfig: DemoBooking[] = [
  {
    id: "brnd_2kkLlrCvbpc3IWezSBcsVBaerAH",
    bookings: [
      {
        id: "book_2lWfcqiqVTfumiwgJpkzC94Caoq",
        status: BookingStatus.BookingStatusPending,
        tab: BookingTabs.New,
        events: [
          {
            id: "evt_2lWfcqiqVTfumiwgJpkzC94Caoq",
            performedByType: BookingEventPerformerType.Creator,
            type: BookingEventType.Applied,
            timestamp: getUnixTime(subDays(new Date(), 5)), // Unix timestamp in seconds
            description: "",
          },
        ],
      },
      {
        id: "book_2lk45DiROTkKq4lr7YRRRx9VtFc",
        status: BookingStatus.BookingStatusPending,
        tab: BookingTabs.New,
        events: [
          {
            id: "evt_2lWfcqiqVTfumiwgJpkzC94Caoq",
            performedByType: BookingEventPerformerType.Creator,
            type: BookingEventType.Applied,
            timestamp: getUnixTime(subDays(new Date(), 3)), // Unix timestamp in seconds
            description: "",
          },
        ],
      },
      {
        id: "book_2mU6wRe9YmeVy30D8R6N7TLrAn2",
        status: BookingStatus.BookingStatusPending,
        tab: BookingTabs.New,
        events: [
          {
            id: "evt_2lWfcqiqVTfumiwgJpkzC94Caoq",
            performedByType: BookingEventPerformerType.Creator,
            type: BookingEventType.Applied,
            timestamp: getUnixTime(subDays(new Date(), 2)), // Unix timestamp in seconds
            description: "",
          },
        ],
      },
      {
        id: "book_2n9KQB9OmC0JvM5yzejhJD8Lxag",
        status: BookingStatus.BookingStatusPending,
        tab: BookingTabs.New,
        events: [
          {
            id: "evt_2lWfcqiqVTfumiwgJpkzC94Caoq",
            performedByType: BookingEventPerformerType.Creator,
            type: BookingEventType.Applied,
            timestamp: getUnixTime(subDays(new Date(), 2)), // Unix timestamp in seconds
            description: "",
          },
        ],
      },
      {
        id: "book_2mWR0L7j2lzFQACMii8nAXRnR6d",
        status: BookingStatus.BookingStatusApproved,
        tab: BookingTabs.InProgress,
        events: [
          {
            id: "evt_1",
            performedByType: BookingEventPerformerType.Creator,
            type: BookingEventType.Applied,
            timestamp: generateEventTimestamp(BookingEventType.Applied),
            description: "",
          },
          {
            id: "evt_2",
            performedByType: BookingEventPerformerType.Brand,
            type: BookingEventType.Approved,
            timestamp: generateEventTimestamp(BookingEventType.Approved),
            description:
              "Hey, thanks for your interest in our venue! Looking at your content, we'd love for you to try out our sharing boards. Looking forward to welcoming you soon!",
            approvedDate: getApprovedDate(),
            performedBy: {
              firstName: "Mike",
              lastName: "Televantou",
              email: "mike@televantou.com",
            },
          },
          {
            id: "evt_2.5",
            performedByType: BookingEventPerformerType.Automatic,
            type: BookingEventType.AccessCollinsBookingCreated,
            timestamp: generateEventTimestamp(
              BookingEventType.AccessCollinsBookingCreated
            ),
            description: "67b5c0f15410234de81eba3e",
            approvedDate: getApprovedDate(),
          },
          {
            id: "evt_2.75",
            performedByType: BookingEventPerformerType.Automatic,
            type: BookingEventType.ToggleVoucherCreated,
            timestamp: generateEventTimestamp(
              BookingEventType.ToggleVoucherCreated
            ),
            description: "6302190087162579722",
            approvedDate: getApprovedDate(),
          },
          {
            id: "evt_3",
            performedByType: BookingEventPerformerType.Brand,
            type: BookingEventType.CreatorConfirmedAttendance,
            timestamp: generateEventTimestamp(
              BookingEventType.CreatorConfirmedAttendance
            ),
            approvedDate: getApprovedDate(),
            description: "",
          },
          {
            id: "evt_4",
            performedByType: BookingEventPerformerType.Creator,
            type: BookingEventType.CreatorFeedbackSubmitted,
            timestamp: generateEventTimestamp(
              BookingEventType.CreatorFeedbackSubmitted
            ),
            description: "5/5 — Food was incredible. Service was fab ",
          },
          {
            id: "evt_5",
            performedByType: BookingEventPerformerType.Creator,
            type: BookingEventType.ContentUploaded,
            timestamp: getContentUploadTimestamps().firstStory,
            description: "",
            bookingPost: {
              id: "bp_2nyF1bUvHgK5Pw7qQmJhLuvuXxq",
              postType: BookingPostType.BookingPostTypeStory,
            },
          },
          {
            id: "evt_6",
            performedByType: BookingEventPerformerType.Creator,
            type: BookingEventType.ContentUploaded,
            timestamp: getContentUploadTimestamps().secondStory,
            description: "",
            bookingPost: {
              id: "bp_2nyF1lDu7zYDKXJsgdfjLjb7VBU",
              postType: BookingPostType.BookingPostTypeStory,
            },
          },
          {
            id: "evt_7",
            performedByType: BookingEventPerformerType.Creator,
            type: BookingEventType.ContentUploaded,
            timestamp: getContentUploadTimestamps().reel,
            description: "",
            bookingPost: {
              id: "bp_2nyF2EqIHWsR2AcMeHPfcaQU0hW",
              postType: BookingPostType.BookingPostTypeReel,
            },
          },
          {
            id: "evt_8",
            performedByType: BookingEventPerformerType.Internal,
            type: BookingEventType.AutomatedContentReminderSent,
            timestamp: generateEventTimestamp(
              BookingEventType.AutomatedContentReminderSent,
              2
            ),
            description: "",
          },
          {
            id: "evt_9",
            performedByType: BookingEventPerformerType.Internal,
            type: BookingEventType.ContentOverdueMessageSent,
            timestamp: generateEventTimestamp(
              BookingEventType.ContentOverdueMessageSent,
              2
            ),
            description: "",
          },
        ],
      },
      {
        id: "book_2lYpKA2OFiDj1qanltGubiqEIVV",
        status: BookingStatus.BookingStatusApproved,
        tab: BookingTabs.InProgress,
        paid: true,
        events: [
          {
            id: "evt_1",
            performedByType: BookingEventPerformerType.Creator,
            type: BookingEventType.Applied,
            timestamp: generateEventTimestamp(BookingEventType.Applied, -6),
            description: "",
          },
          {
            id: "evt_2",
            performedByType: BookingEventPerformerType.Brand,
            type: BookingEventType.Approved,
            timestamp: generateEventTimestamp(BookingEventType.Approved, -6),
            description:
              "Hey, thanks for your interest in our venue! Looking at your content, we'd love for you to try out our sharing boards. Looking forward to welcoming you soon!",
            approvedDate: getApprovedDate(undefined, -14),
            performedBy: {
              firstName: "Mike",
              lastName: "Televantou",
              email: "mike@televantou.com",
            },
          },
          {
            id: "evt_2.5",
            performedByType: BookingEventPerformerType.Automatic,
            type: BookingEventType.AccessCollinsBookingCreated,
            timestamp: generateEventTimestamp(
              BookingEventType.AccessCollinsBookingCreated,
              -6
            ),
            description: "67b5c0f15410234de81eba3e",
            approvedDate: getApprovedDate(undefined, -6),
          },
          {
            id: "evt_2.75",
            performedByType: BookingEventPerformerType.Automatic,
            type: BookingEventType.ToggleVoucherCreated,
            timestamp: generateEventTimestamp(
              BookingEventType.ToggleVoucherCreated,
              -5
            ),
            description: "6302190087162579722",
            approvedDate: getApprovedDate(undefined, -6),
          },
          {
            id: "evt_3",
            performedByType: BookingEventPerformerType.Brand,
            type: BookingEventType.CreatorConfirmedAttendance,
            timestamp: generateEventTimestamp(
              BookingEventType.CreatorConfirmedAttendance,
              -6
            ),
            approvedDate: getApprovedDate(undefined, -6),
            description: "",
          },
          {
            id: "evt_4",
            performedByType: BookingEventPerformerType.Creator,
            type: BookingEventType.CreatorFeedbackSubmitted,
            timestamp: generateEventTimestamp(
              BookingEventType.CreatorFeedbackSubmitted,
              -6
            ),
            description:
              "5/5 — Absolutely Incredible!! Possibly my favourite restaurant of this year & last year - food was incredible (the wagyu & short rib was so good). The customer service was nothing short of amazing, they were so lovely and kind, checking up on us and being attentive at all times. Special shoutout to KT who was our main server of the night. Genuinely had a great evening and I can’t wait to come back!! Will be doing a Google review too, it was that good!",
          },
        ],
      },
    ],
  },
];

/**
 * Generates a Unix timestamp for booking events based on the event type
 * @param eventType The type of booking event
 * @param baseDate Optional reference date (defaults to current date)
 * @returns Unix timestamp in seconds
 */
export function generateEventTimestamp(
  eventType: BookingEventType,
  daysOffset: number = 0
): number {
  // The approvedDate is our reference point (16 days ago at 7 PM)
  const approvedDate = subDays(new Date(), 10 + daysOffset);
  approvedDate.setHours(19, 0, 0, 0); // Set to 7 PM

  // The applied date is 5 days before approvedDate, 47 minutes earlier
  const appliedDate = subDays(addMinutes(approvedDate, -247), 5);

  // The actual approval event happens a few hours after the application
  const approvalEventTime = addHours(appliedDate, 3); // 3 hours after application

  switch (eventType) {
    case BookingEventType.Applied:
      return getUnixTime(appliedDate);

    case BookingEventType.Approved:
      // The approval event is a few hours after application
      return getUnixTime(approvalEventTime);

    case BookingEventType.AccessCollinsBookingCreated:
    case BookingEventType.ToggleVoucherCreated:
      return getUnixTime(approvalEventTime);

    case BookingEventType.CreatorConfirmedAttendance:
      // 23 hours and random minutes (1-59) before the booked date
      return getUnixTime(
        subMinutes(approvedDate, 1080 + Math.floor(Math.random() * 59))
      );

    case BookingEventType.CreatorFeedbackSubmitted:
      // Morning after booked date (9 AM)
      const feedbackDate = new Date(approvedDate.getTime());
      feedbackDate.setDate(approvedDate.getDate() + 1);
      feedbackDate.setHours(9, Math.floor(Math.random() * 59), 0, 0);
      return getUnixTime(feedbackDate);

    case BookingEventType.ContentUploaded:
      // Base time for first story upload (2 days after booked date)
      return getUnixTime(addDays(approvedDate, 2));

    case BookingEventType.AutomatedContentReminderSent:
      // 14 days after booked date
      return getUnixTime(addDays(approvedDate, 10));

    case BookingEventType.ContentOverdueMessageSent:
      // 1 day after the automated reminder
      return getUnixTime(addDays(subMinutes(approvedDate, 247), 11));

    default:
      return getUnixTime(approvedDate); // Default to booked date
  }
}

// Helper function to get timestamps for multiple content uploads
export function getContentUploadTimestamps(baseDate: Date = new Date()) {
  // The approvedDate is our reference point
  const approvedDate = subDays(baseDate, 10);
  approvedDate.setHours(19, 0, 0, 0); // Set to 7 PM

  // Base time for first story upload (2 days after booked date)
  const firstStoryTime = addDays(approvedDate, 2);

  // Second story is 3-4 minutes after first story
  const secondStoryTime = addMinutes(
    firstStoryTime,
    3 + Math.floor(Math.random() * 2)
  );

  // Reel is 4 hours after first story
  const reelTime = addMinutes(firstStoryTime, 240);

  return {
    firstStory: getUnixTime(firstStoryTime),
    secondStory: getUnixTime(secondStoryTime),
    reel: getUnixTime(reelTime),
  };
}

// Helper function to get the approvedDate (16 days ago at 7 PM)
export function getApprovedDate(
  baseDate: Date = new Date(),
  daysOffset: number = 0
): number {
  const approvedDate = subDays(baseDate, 10 + daysOffset);
  approvedDate.setHours(19, 0, 0, 0); // Set to 7 PM
  return getUnixTime(approvedDate);
}

export function generateSuggestedTimeSlots(
  baseDate: Date = new Date(),
  daysOffset: number = 0
) {
  const result: BookingTimeSlot[] = [];
  const startDate = addDays(baseDate, 3 + daysOffset);

  // Generate 2 time slots on consecutive days
  for (let i = 0; i < 2; i++) {
    const slotDate = addDays(startDate, i); // Consecutive days
    slotDate.setHours(18, 0, 0, 0); // Always at 6:00 PM

    result.push({
      id: `timeslot-${i + 1}`,
      date: getUnixTime(slotDate),
      title: `Booking Slot ${i + 1}`,
      description: `Available time slot on ${format(
        slotDate,
        "MMMM d"
      )} at ${format(slotDate, "h:mm a")}`,
    });
  }

  return result;
}
