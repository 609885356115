import * as React from "react";
import styled from "../../../styles";
import theme from "../../../styles/theme";

type colors = typeof theme.color.typography;

const SendMessageIconSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.1602 27.8418C12.2643 27.8418 10.487 27.4863 8.82812 26.7754C7.17839 26.0645 5.72461 25.0801 4.4668 23.8223C3.20898 22.5553 2.22461 21.097 1.51367 19.4473C0.802734 17.7884 0.447266 16.0156 0.447266 14.1289C0.447266 12.2422 0.802734 10.4694 1.51367 8.81055C2.22461 7.15169 3.20898 5.69336 4.4668 4.43555C5.72461 3.17773 7.17839 2.19336 8.82812 1.48242C10.487 0.771484 12.2643 0.416016 14.1602 0.416016C16.0469 0.416016 17.8197 0.771484 19.4785 1.48242C21.1374 2.19336 22.5911 3.17773 23.8398 4.43555C25.0977 5.69336 26.082 7.15169 26.793 8.81055C27.513 10.4694 27.873 12.2422 27.873 14.1289C27.873 16.0156 27.513 17.7884 26.793 19.4473C26.082 21.097 25.0977 22.5553 23.8398 23.8223C22.5911 25.0801 21.1374 26.0645 19.4785 26.7754C17.8197 27.4863 16.0469 27.8418 14.1602 27.8418ZM14.1602 7.18359C14.0508 7.18359 13.946 7.20182 13.8457 7.23828C13.7454 7.27474 13.6452 7.34766 13.5449 7.45703L8.88281 11.9961C8.80078 12.0781 8.73698 12.1647 8.69141 12.2559C8.65495 12.347 8.63672 12.4518 8.63672 12.5703C8.63672 12.7891 8.70964 12.9714 8.85547 13.1172C9.0013 13.263 9.18359 13.3359 9.40234 13.3359C9.64844 13.3359 9.84896 13.2448 10.0039 13.0625L12.3965 10.5742L13.3672 9.58984L13.2988 11.6133V20.2402C13.2988 20.4681 13.3809 20.6641 13.5449 20.8281C13.7181 20.9922 13.9232 21.0742 14.1602 21.0742C14.3971 21.0742 14.5977 20.9922 14.7617 20.8281C14.9349 20.6641 15.0215 20.4681 15.0215 20.2402V11.6133L14.9531 9.58984L15.9102 10.5742L18.3027 13.0625C18.4486 13.2448 18.6491 13.3359 18.9043 13.3359C19.1139 13.3359 19.2962 13.263 19.4512 13.1172C19.6061 12.9714 19.6836 12.7891 19.6836 12.5703C19.6836 12.4518 19.6608 12.347 19.6152 12.2559C19.5697 12.1647 19.5059 12.0781 19.4238 11.9961L14.7754 7.45703C14.6751 7.34766 14.5749 7.27474 14.4746 7.23828C14.3743 7.20182 14.2695 7.18359 14.1602 7.18359Z"
        fill="#D64077"
      />
    </svg>
  );
};

interface Props {
  colorPreset?: keyof colors;
}

export const SendMessageIcon = styled(SendMessageIconSvg)<Props>`
  path {
    fill: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
