import { SVGProps } from "react";
import styled from "../../../styles";

const SquareFormatSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.1187 11.9238C14.1187 12.7344 13.9087 13.3521 13.4888 13.7769C13.0737 14.1968 12.4658 14.4067 11.665 14.4067H2.76611C1.96533 14.4067 1.35498 14.1968 0.935059 13.7769C0.515137 13.3521 0.305176 12.7344 0.305176 11.9238L0.305176 3.07617C0.305176 2.26562 0.515137 1.65039 0.935059 1.23047C1.35498 0.805664 1.96533 0.593262 2.76611 0.593262H11.665C12.4658 0.593262 13.0737 0.805664 13.4888 1.23047C13.9087 1.65039 14.1187 2.26563 14.1187 3.07617V11.9238ZM12.4927 11.792V3.20801C12.4927 2.89063 12.4121 2.64648 12.251 2.47559C12.085 2.30469 11.8335 2.21924 11.4966 2.21924H2.94189C2.60498 2.21924 2.35352 2.30469 2.1875 2.47559C2.02148 2.64648 1.93848 2.89062 1.93848 3.20801L1.93848 11.792C1.93848 12.1143 2.02148 12.3608 2.1875 12.5317C2.35352 12.6978 2.60498 12.7808 2.94189 12.7808H11.4966C11.8335 12.7808 12.085 12.6978 12.251 12.5317C12.4121 12.3608 12.4927 12.1143 12.4927 11.792Z"
      fill="#9F9AA7"
    />
  </svg>
);

export const SquareFormatIcon = styled(SquareFormatSVG)<{
  active: boolean;
}>`
  path {
    ${(p) => {
      const fill = p.active
        ? p.theme.color.primary
        : `${p.theme.color.typography.secondary}50`;
      return { fill };
    }}
  }
`;
