export const MOBILE_BREAKPOINT = 800; // Typical mobile devices (phones and small tablets)
export const SMALL_DESKTOP_BREAKPOINT = 1024; // 13" MacBook Air/Pro or small desktop monitors
export const MEDIUM_DESKTOP_BREAKPOINT = 1280; // 15" MacBook Pro or 21-24" desktop monitors
export const LARGE_DESKTOP_BREAKPOINT = 1440; // 16" MacBook Pro or 27" desktop monitors
export const XL_DESKTOP_BREAKPOINT = 1920; // Large 32"+ desktop monitors or 4K displays

export const TikTokEngagementsHelpText28Days =
  "Total number of likes, comments and shares on videos posted in the last 28 days";
export const TikTokViewsHelpText28Days =
  "Total number of views on videos posted in the last 28 days";
export const TikTokEngagementRateHelpText28Days =
  "Number of engagements divided by views on videos posted in the last 28 days (engagements/reach) * 100";
export const InstagramReachHelpText28Days =
  "Number of unique people that have seen their content over the last 28 days";
export const InstagramImpressionsHelpText28Days =
  "Total number of times that their posts, stories, reels were seen over the last 28 days";
export const InstagramEngagementsHelpText28Days =
  "Total number of likes, comments, saves, shares and any story interactions over the last 28 days.";
export const InstagramEngagementsHelpText =
  "Total number of likes, comments, saves, shares and any story interactions";
export const InstagramEngagementRateHelpText28Days =
  "Number of engagements divided by people reached over the last 28 days (engagements/reach) * 100";
export const InstagramEngagementRateHelpText =
  "Number of engagements divided by people reached (engagements/reach) * 100";
export const InstagramReachedAudienceDemographicsHelpText =
  "Breakdown of people who have seen their content over the last 30 days";
export const InstagramFollowerDemographicsHelpText =
  "Breakdown of people who currently follow their account";
export const ReportReachHelpText =
  "Total number of people that have seen content from Joli bookings";
export const ExpiredTikTokConnectionText =
  "Expired TikTok connection - data may be missing until they reconnect";
export const ExpiredIGConnectionText =
  "Expired Instagram connection - data may be missing until they reconnect";

interface Config {
  intercomToken: string;
  fullStoryToken: string;
  apiEndpoint: string;
  facebookAppId: string;
  instagramAppId: string;
  openAiToken: string;
  posthogToken: string;
  recaptchaSiteKey: string;
  referrals: {
    localStorageKey: string;
    independent: {
      discount: number;
      id: string;
    };
    friend: {
      discount: number;
    };
  };
}

let config: Config = {
  intercomToken: "r6eh0f0u",
  fullStoryToken: "1847BY",
  apiEndpoint: "https://stag-nibble-api.onrender.com",
  facebookAppId: "181590020062028",
  instagramAppId: "1578592999737510",
  openAiToken: "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",
  posthogToken: "phc_xD1DXKhfQSwPYqgim8E9i5Or3ITD1VXqEM6H5z4Vsn2",
  recaptchaSiteKey: "6LeQgzcqAAAAAOxdUoV-Lb38cxgGAewTfNMFWaz-",
  referrals: {
    localStorageKey: "joli_referral_id",
    independent: {
      discount: 50,
      id: "independents",
    },
    friend: {
      discount: 25,
    },
  },
};

if (process.env.NODE_ENV === "production") {
  if (
    window.location.hostname === "app.joliapp.com" ||
    window.location.hostname === "web.joliapp.com" ||
    window.location.hostname === "web.joliapp.com"
  ) {
    config = {
      intercomToken: "hhm6xlhq",
      fullStoryToken: "1847BY",
      apiEndpoint: "https://api.wenibble.com",
      facebookAppId: "181590020062028",
      instagramAppId: "1578592999737510",
      openAiToken: "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",
      posthogToken: "phc_xD1DXKhfQSwPYqgim8E9i5Or3ITD1VXqEM6H5z4Vsn2",
      recaptchaSiteKey: "6LeQgzcqAAAAAOxdUoV-Lb38cxgGAewTfNMFWaz-",
      referrals: {
        localStorageKey: "joli_referral_id",
        independent: {
          discount: 50,
          id: "independents",
        },
        friend: {
          discount: 25,
        },
      },
    };
  } else {
    config = {
      intercomToken: "r6eh0f0u",
      fullStoryToken: "1847BY",
      apiEndpoint: "https://stag-nibble-api.onrender.com",
      facebookAppId: "181590020062028",
      instagramAppId: "1578592999737510",
      openAiToken: "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",
      posthogToken: "phc_xD1DXKhfQSwPYqgim8E9i5Or3ITD1VXqEM6H5z4Vsn2",
      recaptchaSiteKey: "6LeQgzcqAAAAAOxdUoV-Lb38cxgGAewTfNMFWaz-",
      referrals: {
        localStorageKey: "joli_referral_id",
        independent: {
          discount: 50,
          id: "independents",
        },
        friend: {
          discount: 25,
        },
      },
    };
  }
}

export default config;
