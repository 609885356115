import { format, fromUnixTime } from "date-fns";
import { useState } from "react";
import Masonry from "react-masonry-css";
import { useHistory } from "react-router-dom";
import { Callout } from "../../../components/Callout";
import { CloseIcon } from "../../../components/CloseIcon";
import { Button, ButtonWrap } from "../../../components/CTA";
import { Flex } from "../../../components/Flex";
import { H2 } from "../../../components/Heading";
import { StoryIcon } from "../../../components/icons/Instagram/StoryIcon";
import { PicturesIcon } from "../../../components/icons/PictureIcon";
import { VideoIcon } from "../../../components/icons/VideoIcon";
import Loader from "../../../components/Loader";
import { SegmentedControl } from "../../../components/SegmentedControl";
import { Text } from "../../../components/Text";
import { View } from "../../../components/View";
import { MOBILE_BREAKPOINT } from "../../../config";
import {
  BrandContentBriefQuery,
  ContentBriefStatus,
  ContentDeliverable,
  MediaType,
  useBrandContentBriefQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { useWindowSize } from "../../../hooks/useWindowSize";
import styled from "../../../styles";

interface PreviewProps {
  isOpen: boolean;
  onClose: () => void;
  briefId: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ScrollContent = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const Footer = styled.div`
  padding: ${(p) => p.theme.spacing.l};
  border-top: 1px solid ${(p) => p.theme.color.card.divider};
`;

const Header = styled.div`
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
  padding-right: ${(p) => p.theme.spacing.l};
`;

enum Tab {
  BRIEF_DETAILS = "brief-details",
  CHAT = "chat",
}

export const Details = (props: PreviewProps) => {
  const client = useGqlClient();
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.BRIEF_DETAILS);
  const history = useHistory();

  const window = useWindowSize();

  const maxNameLength =
    window && window.width && window.width > MOBILE_BREAKPOINT ? 52 : 30;

  const { data: briefData } = useBrandContentBriefQuery(client, {
    id: props.briefId,
  });

  if (!briefData?.contentBrief) {
    return null;
  }

  return (
    <Container>
      <Header>
        <Flex justify="space-between" align="center">
          <View margin="m 0 0">
            <H2 margin="0 0 0" style={{ marginBottom: -4 }}>
              {briefData.contentBrief.name.slice(0, maxNameLength - 1)}
              {briefData.contentBrief.name.length > maxNameLength && "..."}
            </H2>
            <Text size="xs" colorPreset="secondary" margin="s 0 0">
              Created{" "}
              {format(
                fromUnixTime(briefData.contentBrief.createdAt),
                "EEE do MMM YYY"
              )}
            </Text>
          </View>
          <Flex align="center" justify="center">
            <CloseButtonWrap onClick={props.onClose}>
              <CloseIcon height={26} width={26} colorPreset="text" />
            </CloseButtonWrap>
          </Flex>
        </Flex>
      </Header>
      <View margin="0 l 0">
        <View margin="0 0 0">
          <SegmentedControl
            size="l"
            value={selectedTab}
            options={[
              { label: "Details", value: Tab.BRIEF_DETAILS },
              { label: "Chat", value: Tab.CHAT, isDisabled: true },
            ]}
            onChange={(value) => setSelectedTab(value as Tab)}
          />
        </View>
      </View>
      <ScrollContent>
        <View margin="xl l l">
          {selectedTab === Tab.BRIEF_DETAILS && (
            <BriefDetailsTab briefData={briefData.contentBrief} />
          )}
          {selectedTab === Tab.CHAT && <ChatTab />}
        </View>
      </ScrollContent>
      {selectedTab === Tab.BRIEF_DETAILS && (
        <Footer>
          <ButtonWrap>
            <Button
              buttonType="secondary"
              onClick={() => {
                history.push(`/b/briefs/${briefData.contentBrief.id}/edit`);
                return;
              }}
            >
              Edit Brief
            </Button>
            <Button
              isDisabled={true}
              buttonType="primary"
              onClick={() => {
                return;
              }}
            >
              Start Chat
            </Button>
          </ButtonWrap>
        </Footer>
      )}
    </Container>
  );
};

const MasonryGrid = styled(Masonry)`
  &.masonry-grid {
    display: flex;
    width: 100%;

    div {
      &.masonry-grid-column {
        padding-left: ${(p) => p.theme.spacing.l};
        background-clip: padding-box;

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
`;

const BriefDetailsTab = ({
  briefData,
}: {
  briefData: BrandContentBriefQuery["contentBrief"];
}) => {
  const history = useHistory();
  const breakpointCols = { default: 2 }; // Always 2 columns

  return (
    <View>
      <View margin="0">
        <View margin="0">
          <Text weight="bold" margin="0 0 s">
            Content Creator
          </Text>
          {briefData.status === ContentBriefStatus.Pending ? (
            <SearchingForCreator />
          ) : (
            <Callout type="info">
              <Text margin="0 0 0" isInline size="s">
                Publish your brief to match with creators
              </Text>
            </Callout>
          )}
        </View>
        <View margin="xl 0">
          <Text weight="bold" margin="0 0 s">
            Visit Details
          </Text>
          <View>
            <Callout type="info">
              <Text margin="0 0 0" isInline size="s">
                Location and date to be confirmed once matched
              </Text>
            </Callout>
          </View>
        </View>
      </View>
      <View margin="xl 0">
        <Text weight="bold" margin="0 0 s">
          Deliverables
        </Text>
        {briefData.deliverables.length > 0 ? (
          <MasonryGrid
            breakpointCols={breakpointCols}
            className="masonry-grid"
            columnClassName="masonry-grid-column"
          >
            {briefData.deliverables.map((deliverable) =>
              Array.from({ length: deliverable.quantity }).map((_, i) => (
                <Deliverable
                  key={`${deliverable.id}-${i}`}
                  deliverable={deliverable}
                />
              ))
            )}
          </MasonryGrid>
        ) : (
          <AddDelierableEmptyState
            onClick={() => history.push(`/b/briefs/${briefData.id}/edit`)}
          />
        )}
      </View>
    </View>
  );
};

const ChatTab = () => {
  return (
    <View>
      <Text>Chat</Text>
    </View>
  );
};

function getCssAspectRatio(aspectRatio: string) {
  switch (aspectRatio) {
    case "VERTICAL_916":
      return "9:16";
    case "VERTICAL_45":
      return "4:5";
    case "SQUARE":
      return "1:1";
    case "LANDSCAPE_169":
      return "16:9";
    default:
      return aspectRatio;
  }
}

const DeliverableWrap = styled.div<{ aspectRatio: string }>`
  border: 2px dashed ${(p) => p.theme.color.card.divider};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  margin-bottom: ${(p) => p.theme.spacing.m};
  width: 100%;
  position: relative;
  overflow: hidden;

  /* Create aspect ratio container using padding-bottom technique */
  &:before {
    content: "";
    display: block;
    padding-top: ${(p) => {
      const [width, height] = p.aspectRatio.split(":").map(Number);
      return `${(height / width) * 100}%`;
    }};
  }
`;

const DeliverableContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Deliverable = ({ deliverable }: { deliverable: ContentDeliverable }) => {
  return (
    <DeliverableWrap aspectRatio={getCssAspectRatio(deliverable.aspectRatio)}>
      <DeliverableContent>
        <Flex align="center" direction="column">
          {deliverable.mediaType === MediaType.Video ? (
            <VideoIcon width={26} colorPreset="secondary" />
          ) : (
            <PicturesIcon width={26} colorPreset="secondary" />
          )}
          <Text size="xs" margin="xs 0 0 0" colorPreset="secondary">
            {formatAspectRatio(deliverable.aspectRatio)}
          </Text>
        </Flex>
      </DeliverableContent>
    </DeliverableWrap>
  );
};

const formatAspectRatio = (aspectRatio: string) => {
  switch (aspectRatio) {
    case "VERTICAL_916":
      return "9:16";
    case "VERTICAL_45":
      return "4:5";
    case "SQUARE":
      return "1:1";
    case "LANDSCAPE_169":
      return "16:9";
    default:
      return aspectRatio;
  }
};

const SearchingWrap = styled.div`
  background: ${(p) => p.theme.color.card.callout};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
`;

const SearchingForCreator = () => {
  return (
    <SearchingWrap>
      <Flex direction="column" align="center" justify="center" margin="xs 0 m">
        <Loader width={32} />
        <Text margin="xs 0 0" weight="bold" size="s">
          Matching...
        </Text>
        <Text margin="xxs 0 0" size="xs" colorPreset="secondary">
          We'll email you when we find a match
        </Text>
      </Flex>
    </SearchingWrap>
  );
};

const AddDelierableWrap = styled.div`
  border: 2px dashed ${(p) => p.theme.color.typography.secondary}70;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  padding: ${(p) => p.theme.spacing.xl} ${(p) => p.theme.spacing.m}
    ${(p) => p.theme.spacing.m};
  margin-top: ${(p) => p.theme.spacing.l};
  cursor: pointer;
  transition: opacity 0.1s ease-in-out, border-color 0.1s ease-in-out;
  text-decoration: none;
  display: block;
  opacity: 0.7;

  :hover {
    border-color: ${(p) => p.theme.color.typography.secondary};
    opacity: 1;
  }
`;

const AddDelierableEmptyState = ({ onClick }: { onClick: () => void }) => {
  return (
    <AddDelierableWrap onClick={onClick}>
      <Flex direction="column" align="center" justify="center" margin="xs 0 m">
        <StoryIcon width={26} colorPreset="secondary" />
        <Text margin="s 0 0" weight="semi" size="s" colorPreset="secondary">
          Add Deliverable
        </Text>
      </Flex>
    </AddDelierableWrap>
  );
};

const CloseButtonWrap = styled.div`
  position: absolute;
  top: ${(p) => p.theme.spacing.l};
  right: ${(p) => p.theme.spacing.l};
  z-index: 1;
  cursor: pointer;

  &:hover {
    svg {
      path {
        fill: ${(p) => p.theme.color.typography.heading};
      }
    }
  }
`;
