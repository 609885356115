import { Link } from "react-router-dom";
import { MOBILE_BREAKPOINT } from "../config";
import styled, { css } from "../styles";
import withMargin, { WithMarginProp } from "../styles/withMargin";
import { Flex } from "./Flex";
import Loader from "./Loader";

type CTAProps = {
  type?: "primary" | "destructive" | "secondary" | "tertiary" | "quaternary";
  disabled?: boolean;
  size?: "s" | "xs";
  borderRadius?: "rounded";
};

export function ButtonLoader({ loaderHeight }: { loaderHeight?: number }) {
  return (
    <Flex align="center" justify="center">
      <Loader height={loaderHeight ? loaderHeight : 26} />
    </Flex>
  );
}

export const CTA = styled(Link)<CTAProps & WithMarginProp>`
  background-color: ${(p) => p.theme.color.primary};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  color: #ffffff;
  border: none;
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.xl};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-weight: ${(p) => p.theme.typography.weight.bold};
  font-size: ${(p) => p.theme.typography.size.m};
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  outline: none !important;
  &:hover {
    box-shadow: none;
    filter: brightness(0.95);
    ${(p) =>
      p.disabled
        ? css`
            cursor: not-allowed;
          `
        : css`
            cursor: pointer;
          `}
  }

  ${(p) =>
    p.type === "secondary"
      ? css`
          background: none;
          padding: calc(${(p) => p.theme.spacing.m} - 1px)
            ${(p) => p.theme.spacing.xl};
          border: 1px solid ${(p) => p.theme.color.button.secondaryBorder};
          color: ${(p) => p.theme.color.button.secondaryText};
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

          &:hover {
            background: ${(p) => p.theme.color.typography.secondary}10;
          }
        `
      : null}

  ${(p) =>
    p.type === "tertiary"
      ? css`
          background: ${(p) => p.theme.color.input.hover};
          color: ${(p) => p.theme.color.primary};
          box-shadow: none;
          font-weight: ${(p) => p.theme.typography.weight.semi};
          padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
        `
      : null}

      ${(p) =>
    p.type === "quaternary"
      ? css`
          background: transparent;
          color: ${(p) => p.theme.color.primary};
          text-align: left;
          box-shadow: none;
          display: inline-block;
          width: auto !important;
          padding: 0;
          font-weight: ${(p) => p.theme.typography.weight.semi};
          font-size: ${(p) => p.theme.typography.size.s};
        `
      : null}

${(p) =>
    p.type === "destructive"
      ? css`
          background: none;
          border: 1px solid ${(p) => p.theme.color.destructive};
          color: ${(p) => p.theme.color.destructive};
        `
      : null}

  ${(p) =>
    p.disabled
      ? css`
          background: ${(p) => p.theme.color.inactive};
          padding: calc(${(p) => p.theme.spacing.m} - 1px)
            ${(p) => p.theme.spacing.xl};
          border: 1px solid ${(p) => p.theme.color.inactive};
          color: "#fff";
        `
      : null}

  font-family: ${(p) => p.theme.typography.bodyFamily};

  ${(p) =>
    p.size === "s"
      ? css`
          padding: calc(${(p) => p.theme.spacing.m}) ${(p) =>
          p.theme.spacing.l};
          font-size: ${(p) => p.theme.typography.size.s};
          border-radius: ${(p) => p.theme.misc.borderRadiusSmall});
        `
      : null}

  ${(p) =>
    p.size === "xs"
      ? css`
          padding: calc(${(p) => p.theme.spacing.s}) ${(p) => p.theme.spacing.l};
          font-size: ${(p) => p.theme.typography.size.xs};
          border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
        `
      : null}

  display: block;
  box-sizing: border-box;

  text-decoration: none;

  ${withMargin}
`;

type ButtonProps = {
  buttonType?:
    | "primary"
    | "destructive"
    | "secondary"
    | "tertiary"
    | "quaternary"
    | "warning"
    | "upgrade"
    | "add";
  isDisabled?: boolean;
  size?: "l" | "s" | "xs";
};

export const Button = styled.button<ButtonProps & WithMarginProp>`
  background-color: ${(p) => p.theme.color.primary};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  color: #ffffff;
  border: none;
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.xl};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-weight: ${(p) => p.theme.typography.weight.bold};
  font-size: ${(p) => p.theme.typography.size.m};
  text-align: center;
  width: 100%;
  outline: none !important;
  box-sizing: border-box;

  &:hover {
    box-shadow: none;
    filter: brightness(0.95);
    ${(p) =>
      p.isDisabled
        ? css`
            cursor: not-allowed;
          `
        : css`
            cursor: pointer;
          `}
  }

  ${(p) =>
    p.size === "l" &&
    css`
      padding: calc(${(p) => p.theme.spacing.m} - 2px)
        ${(p) => p.theme.spacing.xl};
    `}

  ${(p) =>
    p.size === "s"
      ? css`
          padding: calc(${(p) => p.theme.spacing.m} - 2px)
            ${(p) => p.theme.spacing.l};
          font-size: ${(p) => p.theme.typography.size.s};
          border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
          height: 42px;
        `
      : null}
      

    ${(p) =>
    p.size === "xs" &&
    css`
      padding: calc(${(p) => p.theme.spacing.s} - 2px)
        ${(p) => p.theme.spacing.m};
      font-size: ${(p) => p.theme.typography.size.xs};
      border-radius: ${(p) => p.theme.misc.borderRadiusXSmall};
    `}

  ${(p) =>
    p.buttonType === "secondary"
      ? css`
          background: none;
          padding: calc(${(p) => p.theme.spacing.m} - 1px)
            ${(p) => p.theme.spacing.l};
          border: 1px solid ${(p) => p.theme.color.button.secondaryBorder};
          color: ${(p) => p.theme.color.button.secondaryText};
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

          &:hover {
            background: ${(p) => p.theme.color.typography.secondary}10;
          }
        `
      : null}

      
      ${(p) =>
    p.buttonType === "secondary" && p.size === "s"
      ? css`
          background: none;
          padding: calc(${(p) => p.theme.spacing.m} - 4px)
            ${(p) => p.theme.spacing.l};
          border: 1px solid ${(p) => p.theme.color.button.secondaryBorder};
          color: ${(p) => p.theme.color.button.secondaryText};
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

          &:hover {
            background: ${(p) => p.theme.color.typography.secondary}10;
          }
        `
      : null}
     
      ${(p) =>
    p.buttonType === "secondary" && p.size === "xs"
      ? css`
          background: none;
          padding: calc(${(p) => p.theme.spacing.s} - 2px)
            ${(p) => p.theme.spacing.l};
          border: 1px solid ${(p) => p.theme.color.button.secondaryBorder};
          color: ${(p) => p.theme.color.button.secondaryText};
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

          &:hover {
            background: ${(p) => p.theme.color.typography.secondary}10;
          }
        `
      : null}

${(p) =>
    p.buttonType === "tertiary"
      ? css`
          background: ${(p) => p.theme.color.input.hover};
          color: ${(p) => p.theme.color.primary};
          box-shadow: none;
          padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
          font-weight: ${(p) => p.theme.typography.weight.semi};
        `
      : null}

${(p) =>
    p.buttonType === "tertiary" && p.size === "s"
      ? css`
          background: ${(p) => p.theme.color.input.hover};
          color: ${(p) => p.theme.color.primary};
          box-shadow: none;
          padding: calc(${(p) => p.theme.spacing.m} - 2px)
            ${(p) => p.theme.spacing.l};
          font-weight: ${(p) => p.theme.typography.weight.semi};
        `
      : null}

      ${(p) =>
    p.buttonType === "quaternary"
      ? css`
          background: transparent;
          color: ${(p) => p.theme.color.primary};
          text-align: left;
          box-shadow: none;
          display: inline-block;
          width: auto !important;
          padding: 0;
          font-weight: ${(p) => p.theme.typography.weight.semi};
          font-size: ${(p) => p.theme.typography.size.s};
          min-width: auto;
        `
      : null}

${(p) =>
    p.buttonType === "destructive"
      ? css`
          background: ${(p) => p.theme.color.destructive};
          border: 2px solid transparent;
          color: white;
        `
      : null}

${(p) =>
    p.buttonType === "upgrade"
      ? css`
          background: ${(p) => p.theme.color.primary};
        `
      : null}

      ${(p) =>
    p.buttonType === "add"
      ? css`
          background: ${(p) => p.theme.color.card.callout};
          color: ${(p) => p.theme.color.typography.text};
          box-shadow: none;
          padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
          font-weight: ${(p) => p.theme.typography.weight.semi};
          border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
        `
      : null}

  ${(p) =>
    p.isDisabled
      ? css`
          opacity: 0.4;
        `
      : null}


  font-family: ${(p) => p.theme.typography.bodyFamily};

  display: block;
  box-sizing: border-box;

  text-decoration: none;

  ${withMargin}
`;

export const ButtonWrap = styled.div<WithMarginProp>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing.m};
  align-items: start;
  ${withMargin}
`;

export const Submit = styled.input`
  background-color: ${(p) => p.theme.color.primary};
  border: none;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  font-family: ${(p) => p.theme.typography.bodyFamily};
  width: 100%;
  display: block;
  box-sizing: border-box;
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.xl};
  text-align: center;
  font-weight: 600;
  font-size: ${(p) => p.theme.typography.size.m};
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  ${withMargin}
`;

export const StyledButtonWrap = styled(ButtonWrap)`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr;
    grid-row-gap: ${(p) => p.theme.spacing.m};
  }
`;
