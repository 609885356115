import * as React from "react";
import styled from "../../styles";
import theme from "../../styles/theme";

type colors = typeof theme.color.typography;

const LogoutSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    viewBox="0 0 31 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30.4707 20.4941C30.4707 21.8249 30.1335 22.8275 29.459 23.502C28.7936 24.1764 27.8001 24.5137 26.4785 24.5137H12.834C11.5124 24.5137 10.5189 24.1764 9.85352 23.502C9.17904 22.8275 8.8418 21.8249 8.8418 20.4941L8.8418 15.7363H10.5234L10.5234 20.4531C10.5234 21.2279 10.724 21.8203 11.125 22.2305C11.526 22.6315 12.123 22.832 12.916 22.832H26.3965C27.1803 22.832 27.7728 22.6315 28.1738 22.2305C28.584 21.8203 28.7891 21.2279 28.7891 20.4531V4.78516C28.7891 4.02865 28.584 3.44531 28.1738 3.03516C27.7728 2.61589 27.1803 2.40625 26.3965 2.40625L12.916 2.40625C12.123 2.40625 11.526 2.61589 11.125 3.03516C10.724 3.44531 10.5234 4.02865 10.5234 4.78516V9.50195H8.8418V4.74414C8.8418 3.41341 9.17904 2.41081 9.85352 1.73633C10.5189 1.06185 11.5124 0.724609 12.834 0.724609L26.4785 0.724609C27.791 0.724609 28.7845 1.06185 29.459 1.73633C30.1335 2.41081 30.4707 3.41341 30.4707 4.74414V20.4941ZM19.9434 12.6191C19.9434 12.847 19.8659 13.043 19.7109 13.207C19.5469 13.3711 19.3555 13.4531 19.1367 13.4531L4.72656 13.4531L2.73047 13.3848L3.64648 14.2734L6.16211 16.6934C6.33529 16.8483 6.42188 17.0443 6.42188 17.2812C6.42188 17.4909 6.35352 17.6686 6.2168 17.8145C6.07096 17.9603 5.89323 18.0332 5.68359 18.0332C5.48307 18.0332 5.29622 17.9466 5.12305 17.7734L0.666016 13.2207C0.565755 13.1204 0.497396 13.0247 0.460938 12.9336C0.424479 12.8333 0.40625 12.7285 0.40625 12.6191C0.40625 12.5098 0.424479 12.4095 0.460938 12.3184C0.497396 12.2181 0.565755 12.1178 0.666016 12.0176L5.12305 7.46484C5.29622 7.30078 5.48307 7.21875 5.68359 7.21875C5.89323 7.21875 6.07096 7.29622 6.2168 7.45117C6.35352 7.59701 6.42188 7.77474 6.42188 7.98438C6.42188 8.21224 6.33529 8.40365 6.16211 8.55859L3.64648 10.9648L2.74414 11.8398L4.72656 11.7852H19.1367C19.3555 11.7852 19.5469 11.8672 19.7109 12.0313C19.8659 12.1953 19.9434 12.3913 19.9434 12.6191Z"
      fill="#D64077"
    />
  </svg>
);

interface Props {
  colorPreset?: keyof colors;
}

export const LogoutIcon = styled(LogoutSVG)<Props>`
  path {
    fill: ${(p) =>
      p.colorPreset ? p.theme.color.typography[p.colorPreset] : "#fff"};
  }
`;
