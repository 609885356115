import { SVGProps } from "react";
import styled from "../../../styles";

const HorizontalFormatSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="17"
    height="13"
    viewBox="0 0 17 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.7197 12.8721H2.71143C1.90576 12.8721 1.29053 12.6646 0.865723 12.2495C0.440918 11.8296 0.228516 11.2168 0.228516 10.4111L0.228516 2.58887C0.228516 1.78809 0.440918 1.17773 0.865723 0.757812C1.29053 0.337891 1.90576 0.12793 2.71143 0.12793H13.7197C14.5303 0.12793 15.1455 0.337891 15.5654 0.757813C15.9854 1.17773 16.1953 1.78809 16.1953 2.58887V10.4111C16.1953 11.2168 15.9854 11.8296 15.5654 12.2495C15.1455 12.6646 14.5303 12.8721 13.7197 12.8721ZM13.5806 11.2461C13.9028 11.2461 14.147 11.1631 14.313 10.9971C14.4839 10.8311 14.5693 10.5796 14.5693 10.2427V2.75732C14.5693 2.42041 14.4839 2.17139 14.313 2.01025C14.147 1.84424 13.9028 1.76123 13.5806 1.76123L2.85059 1.76123C2.5332 1.76123 2.28906 1.84424 2.11816 2.01025C1.94727 2.17139 1.86182 2.42041 1.86182 2.75732L1.86182 10.2427C1.86182 10.5796 1.94727 10.8311 2.11816 10.9971C2.28906 11.1631 2.5332 11.2461 2.85059 11.2461H13.5806Z"
      fill="#9F9AA7"
    />
  </svg>
);

export const HorizontalFormatIcon = styled(HorizontalFormatSVG)<{
  active: boolean;
}>`
  path {
    ${(p) => {
      const fill = p.active
        ? p.theme.color.primary
        : `${p.theme.color.typography.secondary}50`;
      return { fill };
    }}
  }
`;
