import { Disclosure } from "@headlessui/react";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../config";
import RocketEmoji from "../images/rocket-emoji.png";
import { css } from "../styles";
import { Chevron } from "./Chevron";
import { Flex } from "./Flex";
import { CheckCircle } from "./icons/CheckCircle";
import { Text } from "./Text";
import { View } from "./View";

type CollapsableSectionProps = {
  title: string;
  children: React.ReactNode;
  tooltip?: React.ReactNode;
  defaultOpen: boolean;
  isCheckboxComplete?: boolean; // used for set up guide
  showCheckbox?: boolean; // used for set up guide
  emoji?: "rocket"; // used for set up guide
  transparentPanelBackground?: boolean;
  chevronPlacement?: "left" | "right";
  verticalPaddingHeight?: "s";
  panelHorizontalPadding?: "s" | "none";
  badgeCount?: number;
  headerSize: "m" | "l";
  alwaysOpen?: boolean;
};

const CollapsableSectionWrap = styled(Disclosure)`
  overflow: visible;
`;

const CollapsableSectionButton = styled(Disclosure.Button)<{
  open: boolean;
  transparentPanelBackground?: boolean;
}>`
  background-color: ${(p) => p.theme.color.card.callout};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  width: 100%;
  border: 0px solid ${(p) => p.theme.color.card.divider};
  cursor: pointer;

  ${(p) =>
    p.open && !p.transparentPanelBackground
      ? css`
          border-radius: ${(p) => p.theme.misc.borderRadius}
            ${(p) => p.theme.misc.borderRadius} 0 0;
        `
      : css`
          border-radius: ${(p) => p.theme.misc.borderRadius};
          :hover {
            filter: brightness(0.99);
          }
        `}
`;

const CollapsableSectionPanel = styled(Disclosure.Panel)<{
  transparentPanelBackground?: boolean;
}>`
  background-color: ${(p) =>
    p.transparentPanelBackground ? "transparent" : p.theme.color.card.callout};
  border-radius: 0 0 ${(p) => p.theme.misc.borderRadius}
    ${(p) => p.theme.misc.borderRadius};
  width: 100%;
`;

const CollapsableContentWrap = styled.div<{
  open: boolean;
  panelHorizontalPadding?: "s" | "none";
}>`
  ${(p) =>
    p.panelHorizontalPadding === "s"
      ? css`
          padding: 0 ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.s};
        `
      : p.panelHorizontalPadding === "none"
      ? css`
          padding: 0 0 ${(p) => p.theme.spacing.l};
        `
      : css`
          padding: 0 ${(p) => p.theme.spacing.xl} ${(p) => p.theme.spacing.l};
        `}

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.s};
  }
`;

const CollapsableHeaderWrap = styled(Flex)<{
  open: boolean;
  chevronPlacement?: "left" | "right";
}>`
  margin: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
  position: relative;

  ${(p) =>
    p.chevronPlacement === "left"
      ? css`
          justify-content: flex-end;
          flex-direction: row-reverse;
          align-items: center;
          gap: ${(p) => p.theme.spacing.l};
        `
      : css`
          justify-content: space-between;
          flex-direction: row;
          align-items: center;
        `}

  @media
    (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
  }
`;

export function CollapsableSection(props: CollapsableSectionProps) {
  return (
    <CollapsableSectionWrap defaultOpen={props.defaultOpen}>
      {({ open }) => (
        <>
          <CollapsableSectionButton
            style={{ cursor: props.alwaysOpen ? "auto" : "pointer" }}
            open={open}
            transparentPanelBackground={props.transparentPanelBackground}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              if (props.alwaysOpen) {
                e.preventDefault();
              }
            }}
          >
            <CollapsableHeaderWrap
              open={open}
              chevronPlacement={props.chevronPlacement}
            >
              <Flex align="center">
                {props.showCheckbox ? (
                  <View margin="0 s 0 0">
                    <CheckCircle
                      size="l"
                      checked={
                        props.isCheckboxComplete
                          ? props.isCheckboxComplete
                          : false
                      }
                    />
                  </View>
                ) : null}
                <Text
                  margin={
                    props.verticalPaddingHeight === "s"
                      ? "0 xs 0 0"
                      : "xs 0 xs 0"
                  }
                  style={{
                    textDecoration: props.isCheckboxComplete
                      ? "line-through"
                      : "none",
                  }}
                  size={props.headerSize}
                  weight="bold"
                  colorPreset="heading"
                >
                  {props.title}
                </Text>
                {props.emoji === "rocket" ? (
                  <div style={{ marginBottom: -1, marginLeft: 3 }}>
                    <img alt="rocket icon" width={20} src={RocketEmoji} />
                  </div>
                ) : null}
                {props.tooltip ? (
                  <div style={{ marginBottom: -1, marginLeft: 6 }}>
                    {props.tooltip}
                  </div>
                ) : null}
                {props.badgeCount ? <Badge>{props.badgeCount}</Badge> : null}
              </Flex>

              {!props.alwaysOpen ? (
                <Chevron direction={open ? "up" : "down"} animateTransition />
              ) : null}
            </CollapsableHeaderWrap>
          </CollapsableSectionButton>
          <CollapsableSectionPanel
            transparentPanelBackground={props.transparentPanelBackground}
            static={props.alwaysOpen}
          >
            <View>
              <CollapsableContentWrap
                open={open}
                panelHorizontalPadding={props.panelHorizontalPadding}
              >
                {props.children}
              </CollapsableContentWrap>
            </View>
          </CollapsableSectionPanel>
        </>
      )}
    </CollapsableSectionWrap>
  );
}

const Badge = styled.span`
  width: ${(p) => p.theme.spacing.l};
  height: ${(p) => p.theme.spacing.l};
  padding: 3px;
  background-color: ${(p) => p.theme.color.primary}25;
  color: ${(p) => p.theme.color.primary};
  margin-bottom: 2px;
  margin-left: ${(p) => p.theme.spacing.xs};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${(p) => p.theme.typography.weight.bold};
`;
