import { format, fromUnixTime } from "date-fns";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { CTA } from "../../../components/CTA";
import { Drawer } from "../../../components/Drawer";
import { Flex } from "../../../components/Flex";
import { H1, H2 } from "../../../components/Heading";
import { CompleteBriefIcon } from "../../../components/icons/briefs/CompleteBriefIcon";
import { DraftBriefIcon } from "../../../components/icons/briefs/DraftBriefIcon";
import { InProgressBriefIcon } from "../../../components/icons/briefs/InProgressBriefIcon";
import { MatchingBriefIcon } from "../../../components/icons/briefs/MatchingBriefIcon";
import { MatchingStatusIcon } from "../../../components/icons/briefs/MatchingStatusIcon";
import { ReviewBriefIcon } from "../../../components/icons/briefs/ReviewBriefIcon";
import { CompleteStatusIcon } from "../../../components/icons/CompleteStatusIcon";
import { DraftStatusIcon } from "../../../components/icons/DraftStatusIcon";
import { ListingEmptyStateIcon } from "../../../components/icons/ListingEmptyStateIcon";
import { PicturesIcon } from "../../../components/icons/PictureIcon";
import { ScheduledStatusIcon } from "../../../components/icons/ScheduledStatusIcon";
import { VideoIcon } from "../../../components/icons/VideoIcon";
import Loading from "../../../components/Loading";
import { Modal } from "../../../components/Modal";
import { NewButton } from "../../../components/NewButton";
import { Subtitle } from "../../../components/Subtitle";
import { TabbedContainer } from "../../../components/TabbedContainer";
import { Column, Table } from "../../../components/Table";
import { Text } from "../../../components/Text";
import { ExternalTextLink, FakeTextLink } from "../../../components/TextLink";
import { View } from "../../../components/View";
import { LARGE_DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT } from "../../../config";
import {
  BrandBriefsQuery,
  ContentBriefStatus,
  ContentDeliverable,
  MediaType,
  useBrandBriefsQuery,
} from "../../../graphql/generated";
import useAnalytics from "../../../hooks/useAnalytics";
import useGqlClient from "../../../hooks/useGqlClient";
import { useTheme } from "../../../hooks/useTheme";
import { authSelectors } from "../../../store/auth/selector";
import { css, styled } from "../../../styles";
import { Details } from "../brief/details";

export enum BriefTabs {
  Draft = "drafts",
  Matching = "matching",
  InProgress = "in_progress",
  Review = "review",
  Complete = "complete",
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1600px;
  max-height: 92vh;
  height: 100%;
  padding: 0 ${(p) => p.theme.spacing.l};
  box-sizing: border-box;

  @media (min-width: ${LARGE_DESKTOP_BREAKPOINT}px) {
    padding: 0 ${(p) => p.theme.spacing.xl};
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 ${(p) => p.theme.spacing.l} ${(p) => p.theme.spacing.xl};
    max-height: unset;

    .hide-on-mobile {
      display: none;
    }
  }
`;

export interface MatchParams {
  page: BriefTabs;
  id: string | undefined;
}

export function getBriefStatusPage(status: ContentBriefStatus): BriefTabs {
  switch (status) {
    case ContentBriefStatus.Draft:
      return BriefTabs.Draft;
    case ContentBriefStatus.Pending:
    case ContentBriefStatus.Matched:
      return BriefTabs.Matching;
    case ContentBriefStatus.InProgress:
      return BriefTabs.InProgress;
    case ContentBriefStatus.InReview:
      return BriefTabs.Review;
    case ContentBriefStatus.Complete:
      return BriefTabs.Complete;
    default:
      return BriefTabs.Draft;
  }
}

export const BrandBriefsView = () => {
  const [showExplainer, setShowExplainer] = useState(() => {
    const hasSeenExplainer = localStorage.getItem("hasSeenBriefsExplainer");
    return !hasSeenExplainer;
  });

  const handleCloseExplainer = () => {
    setShowExplainer(false);
    localStorage.setItem("hasSeenBriefsExplainer", "true");
  };

  const history = useHistory();
  let pageMatch = useRouteMatch<{ page: BriefTabs }>("/b/briefs/:page");
  let briefMatch = useRouteMatch<{ tab: BriefTabs; id: string }>(
    "/b/briefs/:tab/:id"
  );
  const [page, setPage] = useState(BriefTabs.Draft);
  const [activeBriefId, setActiveBriefId] = useState<string | null>(null);
  const { track } = useAnalytics();

  useEffect(() => {
    if (briefMatch) {
      const newActiveBriefId = briefMatch.params.id || null;
      if (activeBriefId !== newActiveBriefId) {
        setActiveBriefId(newActiveBriefId);
      }
    } else {
      setActiveBriefId(null);
    }
  }, [briefMatch, activeBriefId]);

  useEffect(() => {
    if (pageMatch) {
      const newPage = pageMatch.params.page as BriefTabs;
      if (page !== newPage) {
        setPage(newPage);
      }
    }
  }, [pageMatch, page]);

  const client = useGqlClient();
  const activeBrandId = useSelector(authSelectors.activeBrandId);

  // Todo handle the counts more efficiently
  const { data: allBriefsData, isLoading } = useBrandBriefsQuery(
    client,
    {
      brandId: activeBrandId ?? "",
      status: [
        ContentBriefStatus.Draft,
        ContentBriefStatus.Pending,
        ContentBriefStatus.Matched,
        ContentBriefStatus.InProgress,
        ContentBriefStatus.InReview,
      ],
    },
    {
      retry: false,
      cacheTime: 0,
    }
  );

  const briefsByStatus = {
    [BriefTabs.Draft]:
      allBriefsData?.contentBriefs.contentBriefs.filter(
        (brief) => brief.status === ContentBriefStatus.Draft
      ) ?? [],
    [BriefTabs.Matching]:
      allBriefsData?.contentBriefs.contentBriefs.filter((brief) =>
        [ContentBriefStatus.Pending, ContentBriefStatus.Matched].includes(
          brief.status
        )
      ) ?? [],
    [BriefTabs.InProgress]:
      allBriefsData?.contentBriefs.contentBriefs.filter(
        (brief) => brief.status === ContentBriefStatus.InProgress
      ) ?? [],
    [BriefTabs.Review]:
      allBriefsData?.contentBriefs.contentBriefs.filter(
        (brief) => brief.status === ContentBriefStatus.InReview
      ) ?? [],
    [BriefTabs.Complete]:
      allBriefsData?.contentBriefs.contentBriefs.filter(
        (brief) => brief.status === ContentBriefStatus.Complete
      ) ?? [],
  };

  const tabs = [
    {
      id: BriefTabs.Draft,
      label: "Drafts",
      itemCount: briefsByStatus[BriefTabs.Draft].length,
      icon: <DraftBriefIcon />,
    },
    {
      id: BriefTabs.Matching,
      label: "Matching",
      itemCount: briefsByStatus[BriefTabs.Matching].length,
      icon: <MatchingBriefIcon />,
    },
    {
      id: BriefTabs.InProgress,
      label: "In Progress",
      itemCount: briefsByStatus[BriefTabs.InProgress].length,
      icon: <InProgressBriefIcon />,
    },
    {
      id: BriefTabs.Review,
      label: "Review",
      itemCount: briefsByStatus[BriefTabs.Review].length,
      icon: <ReviewBriefIcon />,
    },
    {
      id: BriefTabs.Complete,
      label: "Complete",
      itemCount: briefsByStatus[BriefTabs.Complete].length,
      icon: <CompleteBriefIcon />,
    },
  ];

  const handleTabChange = (tabId: string) => {
    history.push(`/b/briefs/${tabId}`);
  };

  // Add new useEffect to handle brief status -> page mapping
  useEffect(() => {
    if (activeBriefId && allBriefsData?.contentBriefs.contentBriefs) {
      const brief = allBriefsData.contentBriefs.contentBriefs.find(
        (brief) => brief.id === activeBriefId
      );

      if (brief) {
        const newPage = getBriefStatusPage(brief.status);
        if (page !== newPage) {
          history.push(`/b/briefs/${newPage}/${activeBriefId}`);
          setPage(newPage);
        }
      }
    }
  }, [activeBriefId, allBriefsData, history, page]);

  return (
    <Wrap>
      <Flex justify="space-between">
        <Flex align="flex-end" margin="0 0 m">
          <H1 margin="m m 0 0">Studio</H1>
          <FakeTextLink
            size="xs"
            onClick={() => {
              setShowExplainer(!showExplainer);
              track("Briefs explainer clicked");
            }}
          >
            How does it work?
          </FakeTextLink>
        </Flex>
        <Flex align="center" justify="center">
          <NewButton label="New Brief" to="/b/briefs/create-brief" />
        </Flex>
      </Flex>
      <TabbedContainer
        tabs={tabs}
        activeTab={page}
        onTabChange={handleTabChange}
        content={
          <BriefList
            page={page}
            briefs={briefsByStatus[page]}
            isLoading={isLoading}
          />
        }
      />
      <Drawer
        isOpen={!!activeBriefId}
        onClose={() => {
          setActiveBriefId(null);
          history.push(`/b/briefs/${page}`);
        }}
        width={500}
        position="right"
        showCloseButton={false}
      >
        <Details
          isOpen={!!activeBriefId}
          onClose={() => {
            setActiveBriefId(null);
            history.push(`/b/briefs/${page}`);
          }}
          briefId={activeBriefId ?? ""}
        />
      </Drawer>
      <Modal
        isOpen={showExplainer}
        setIsOpen={handleCloseExplainer}
        maxWidth={660}
      >
        <H2 margin="0">Content You Control, Assets You Own </H2>
        <Text size="l" margin="s 0 xl">
          Custom videos, made for your brand—shot by top creators, tailored to
          your vision, and yours to use however you want
        </Text>
        <View>
          <Flex direction="row" align="flex-start" margin="0 0 xl">
            <div className="active">
              <DraftBriefIcon />
            </div>
            <Text margin="0 0 0 m">
              <Text weight="bold" margin="0" size="l">
                Create your brief
              </Text>{" "}
              Tell us what content you need—set your style, key shots, and
              requirements. Our team is here if you need a hand 👋
            </Text>
          </Flex>
          <Flex direction="row" align="flex-start" margin="0 0 xl">
            <div className="active">
              <InProgressBriefIcon />
            </div>
            <Text margin="0 0 0 m">
              <Text weight="bold" margin="0" size="l">
                Get matched with top creators
              </Text>{" "}
              We’ll show you a few great options at different budgets. Pick the
              best fit, and they’ll get to work—no back-and-forth, just a simple
              selection.
            </Text>
          </Flex>
          <Flex direction="row" align="flex-start" margin="0 0 xl">
            <div className="active">
              <ReviewBriefIcon />
            </div>
            <Text margin="0 0 0 m">
              <Text weight="bold" margin="0" size="l">
                Review & approve
              </Text>
              Your creator films, edits, and delivers the content to you.
              Request changes and approve once you're happy 😄
            </Text>
          </Flex>
        </View>
        <Flex margin="m s xl">
          <ExternalTextLink
            onClick={handleCloseExplainer}
            href="https://joliapp.com/solutions/content/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more at joliapp.com/solutions/content
          </ExternalTextLink>
        </Flex>
        <CTA to="#" onClick={handleCloseExplainer}>
          Got it
        </CTA>
      </Modal>
    </Wrap>
  );
};

const BriefList = ({
  page,
  briefs,
  isLoading,
}: {
  page: BriefTabs;
  briefs: BrandBriefsQuery["contentBriefs"]["contentBriefs"];
  isLoading: boolean;
}) => {
  const history = useHistory();

  if (isLoading) {
    return <Loading />;
  }

  if (!briefs || briefs.length === 0) {
    return <EmptyState page={page} />;
  }

  const columns: Column<
    BrandBriefsQuery["contentBriefs"]["contentBriefs"][0]
  >[] = [
    {
      header: "Name",
      key: "name",
      width: 100,
      render: (brief) => (
        <Text margin="0" truncate>
          {brief.name}
        </Text>
      ),
    },
    {
      header: "Deliverables",
      key: "deliverables",
      width: 100,
      render: (brief) =>
        brief.deliverables && brief.deliverables.length > 0 ? (
          renderDeliverables(brief.deliverables)
        ) : (
          <Text margin="0" colorPreset="secondary">
            -
          </Text>
        ),
    },
    {
      header: "Deadline",
      key: "deadline",
      width: 100,
      render: (brief) => (
        <Text margin="0" colorPreset="secondary">
          {brief.dueDate
            ? format(fromUnixTime(brief.dueDate), "dd/MM/yyyy")
            : "-"}
        </Text>
      ),
    },
    {
      header: "Available Locations",
      key: "locations",
      width: 160,
      render: (brief) => (
        <Text margin="0" colorPreset="secondary" truncate>
          {brief.locations.length === 1
            ? brief.locations[0].name
            : brief.locations.length > 1
            ? `${brief.locations[0].name} +${brief.locations.length - 1} more`
            : "-"}
        </Text>
      ),
    },
    {
      header: "Status",
      key: "status",
      width: 140,
      render: (brief) => (
        <Flex align="center">
          <StatusTag status={brief.status} />
        </Flex>
      ),
    },
  ];

  if (page === BriefTabs.InProgress) {
    columns.push({
      header: "Visit Date",
      key: "visitDate",
      width: 100,
      render: (brief) => <Text margin="0">—</Text>,
    });
  }

  return (
    <View margin="0 0 l">
      <Table
        transparentHeader={true}
        headerFontSize="s"
        columns={columns}
        data={briefs}
        onRowClick={(brief) => history.push(`/b/briefs/${page}/${brief.id}`)}
      />
    </View>
  );
};

const EmptyState = ({ page }: { page: BriefTabs }) => {
  let header = "Start here or pick up where you left off";
  let subtitle = "Create your first brief or jump back into your drafts";
  let showCTA = true;

  switch (page) {
    case BriefTabs.Draft:
      // Draft copy remains the same
      break;

    case BriefTabs.Matching:
      header = "No published briefs";
      subtitle = "Once you publish a brief, you'll see matched creators here";
      showCTA = true;
      break;

    case BriefTabs.InProgress:
      header = "No active productions";
      subtitle = "You'll be able to track progress here once matched";
      showCTA = false;
      break;

    case BriefTabs.Review:
      header = "Nothing to review yet";
      subtitle =
        "Once Creators submit content, you'll review and approve it here";
      showCTA = false;
      break;

    case BriefTabs.Complete:
      header = "No completed content";
      subtitle =
        "All your approved content will be stored here for easy access";
      showCTA = false;
      break;
  }

  return (
    <EmptyStateWrap>
      <Flex
        margin="xxxl l 0"
        justify="center"
        direction="column"
        align="center"
        style={{ textAlign: "center" }}
      >
        <ListingEmptyStateIcon />
        <H2 margin="xxl 0 m">{header}</H2>
        <Subtitle margin="0">{subtitle}</Subtitle>
        {showCTA && (
          <div>
            <CTA margin="l 0 0" to={"/b/briefs/create-brief"}>
              Create a brief
            </CTA>
          </div>
        )}
      </Flex>
    </EmptyStateWrap>
  );
};

const EmptyStateWrap = styled.div`
  max-width: 600px;
  margin: 80px auto;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 32px;
  }
`;

export function StatusTag(props: { status: ContentBriefStatus }) {
  const theme = useTheme();

  if (props.status === ContentBriefStatus.Draft) {
    return (
      <TagWrap color="yellow">
        <DraftStatusIcon color={theme.color.pills.yellowValue} />
        <span>Draft</span>
      </TagWrap>
    );
  }

  if (props.status === ContentBriefStatus.Pending) {
    return (
      <TagWrap color="blue">
        <MatchingStatusIcon color={theme.color.pills.blueValue} />
        <span>
          Matching<DotOne>.</DotOne>
          <DotTwo>.</DotTwo>
          <DotThree>.</DotThree>
        </span>
      </TagWrap>
    );
  }

  if (props.status === ContentBriefStatus.Matched) {
    return (
      <TagWrap color="green">
        <CompleteStatusIcon color={theme.color.pills.greenValue} />
        <span>Matched</span>
      </TagWrap>
    );
  }

  if (props.status === ContentBriefStatus.InProgress) {
    return (
      <TagWrap color="pink">
        <ScheduledStatusIcon color={theme.color.pills.pinkValue} />
        <span>In Progress</span>
      </TagWrap>
    );
  }

  if (props.status === ContentBriefStatus.InReview) {
    return (
      <TagWrap color="blue">
        <DraftStatusIcon color={theme.color.pills.blueValue} />
        <span>Review</span>
      </TagWrap>
    );
  }

  if (props.status === ContentBriefStatus.Complete) {
    return (
      <TagWrap color="grey">
        <CompleteStatusIcon color={theme.color.pills.greyValue} />
        <span>Complete</span>
      </TagWrap>
    );
  }

  return <TagWrap color="grey">Unknown</TagWrap>;
}

const TagWrap = styled.div<{
  color: "green" | "pink" | "blue" | "yellow" | "grey";
}>`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.l};
  font-size: ${(p) => p.theme.typography.size.xs};
  font-weight: ${(p) => p.theme.typography.weight.bold};
  border-radius: ${(p) => p.theme.misc.borderRadiusXSmall};
  display: flex;
  align-items: center;
  gap: 6px;

  span {
    margin-bottom: -2px;
  }

  ${(p) => {
    if (p.color === "green") {
      return css`
        background-color: ${p.theme.color.pills.greenBackground};
        color: ${p.theme.color.pills.greenValue};
      `;
    } else if (p.color === "pink") {
      return css`
        background-color: ${p.theme.color.pills.pinkBackground};
        color: ${p.theme.color.pills.pinkValue};
      `;
    } else if (p.color === "blue") {
      return css`
        background-color: ${p.theme.color.pills.blueBackground};
        color: ${p.theme.color.pills.blueValue};
      `;
    } else if (p.color === "yellow") {
      return css`
        background-color: ${p.theme.color.pills.yellowBackground};
        color: ${p.theme.color.pills.yellowValue};
      `;
    } else {
      return css`
        background-color: ${p.theme.color.pills.greyBackground};
        color: ${p.theme.color.pills.greyValue};
      `;
    }
  }}
`;

const dotAnimation = css`
  opacity: 0;
  display: inline-block;
`;

const DotOne = styled.span`
  ${dotAnimation}
  animation: showDot 2s infinite;
  margin-right: 1px;
  margin-left: 1px;
  @keyframes showDot {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const DotTwo = styled.span`
  ${dotAnimation}
  animation: showDot 2s infinite;
  animation-delay: 0.2s;
  margin-right: 1px;
`;

const DotThree = styled.span`
  ${dotAnimation}
  animation: showDot 2s infinite;
  animation-delay: 0.4s;
  margin-right: 1px;
`;

const DeliverableWrap = styled.div`
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing.xxs};
  margin-right: ${(p) => p.theme.spacing.s};
`;

function renderDeliverables(deliverables: ContentDeliverable[]) {
  const deliverablesByType = deliverables.reduce((acc, deliverable) => {
    acc[deliverable.mediaType] = (acc[deliverable.mediaType] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  return (
    <Flex align="center" gap="s">
      {Object.entries(deliverablesByType).map(([type, count], i) => (
        <DeliverableWrap key={type}>
          <div style={{ marginBottom: -5 }}>
            {type === MediaType.Video ? (
              <VideoIcon height={21} colorPreset="secondary" />
            ) : (
              <PicturesIcon height={21} colorPreset="secondary" />
            )}
          </div>
          <Text
            margin="0 xxs 0 0"
            colorPreset="secondary"
            size="xs"
            weight="semi"
            style={{ marginTop: -1 }}
          >
            x
          </Text>
          <Text margin="0" size="m" colorPreset="secondary">
            {count}
          </Text>
        </DeliverableWrap>
      ))}
    </Flex>
  );
}
