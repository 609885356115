import { ReactNode } from "react";
import styled from "../styles";
import { Flex } from "./Flex";
import Loading from "./Loading";

export interface Column<T> {
  header: string;
  key: string;
  width?: number;
  render?: (item: T) => ReactNode;
}

interface TableProps<T> {
  columns: Column<T>[];
  data: T[];
  onRowClick?: (item: T) => void;
  lastElementRef?: (node: any) => void;
  transparentHeader?: boolean;
  headerFontSize?: "s";
  isFetching?: boolean;
}

export const Table = <T extends {}>({
  columns,
  data,
  onRowClick,
  lastElementRef,
  transparentHeader,
  headerFontSize,
  isFetching,
}: TableProps<T>) => {
  return (
    <TableWrap>
      <StyledTable
        transparentHeader={transparentHeader}
        headerFontSize={headerFontSize}
      >
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.key}
                style={{ width: column.width ? `${column.width}px` : "130px" }}
                scope="col"
              >
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <Row
              key={index}
              onClick={() => onRowClick?.(item)}
              ref={index === data.length - 5 ? lastElementRef : null}
            >
              {columns.map((column) => (
                <td
                  key={column.key}
                  style={{
                    width: column.width ? `${column.width}px` : "130px",
                  }}
                >
                  {column.render
                    ? column.render(item)
                    : (item as any)[column.key]}
                </td>
              ))}
            </Row>
          ))}
        </tbody>
      </StyledTable>
      {isFetching && (
        <Flex align="center" justify="center" style={{ padding: "16px 0" }}>
          <Loading />
        </Flex>
      )}
    </TableWrap>
  );
};

const TableWrap = styled.div`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;

const StyledTable = styled.table<{
  transparentHeader?: boolean;
  headerFontSize?: "s";
}>`
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  box-sizing: border-box;

  thead,
  tbody tr {
    display: table;
    min-width: 100%;
    table-layout: fixed;
  }

  tbody {
    display: block;
    overflow-y: scroll;
    min-width: 100%;
  }

  th,
  td {
    text-align: left;
    padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.xl};
    border-bottom: 1px solid
      ${(p) =>
        p.transparentHeader ? "transparent" : p.theme.color.card.divider};
  }

  th {
    background-color: ${(p) =>
      p.transparentHeader ? "transparent" : p.theme.color.card.callout};
    font-size: ${(p) =>
      p.headerFontSize === "s"
        ? p.theme.typography.size.xxs
        : p.theme.typography.size.s};
    font-weight: ${(p) => p.theme.typography.weight.bold};
    color: ${(p) => p.theme.color.typography.secondary};
    text-transform: uppercase;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  tr:last-of-type {
    td {
      border-bottom: none;
    }
  }
`;

const Row = styled.tr`
  display: table;
  width: 100%;
  cursor: pointer;
  table-layout: fixed;
  background-color: ${(p) => p.theme.color.card.background};

  :hover {
    background-color: ${(p) => p.theme.color.card.callout};
  }
`;
