import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "sonner";
import Avatar from "../../../components/Avatar";
import { UpgradePrompt } from "../../../components/Brand/UpgradePrompt";
import { Card } from "../../../components/Card";
import { Button } from "../../../components/CTA";
import { Flex } from "../../../components/Flex";
import { H2, H3 } from "../../../components/Heading";
import { InstagramIcon } from "../../../components/icons/InstagramIcon";
import { TickIcon } from "../../../components/icons/TickIcon";
import { TikTokIcon } from "../../../components/icons/TikTokIcon";
import { Input } from "../../../components/Input";
import Loading from "../../../components/Loading";
import { Modal } from "../../../components/Modal";
import { Text } from "../../../components/Text";
import config, { MOBILE_BREAKPOINT } from "../../../config";
import {
  BillingPlanType,
  useConnectToInstagramMutation,
  useConnectToTikTokMutation,
  useCreateToggleUserMutation,
  useGetBrandQuery,
  useUpdateBrandMutation,
} from "../../../graphql/generated";
import useAnalytics from "../../../hooks/useAnalytics";
import useGqlClient from "../../../hooks/useGqlClient";
import { useTheme } from "../../../hooks/useTheme";
import { authSelectors } from "../../../store/auth/selector";
import styled from "../../../styles";
import withMargin from "../../../styles/withMargin";

const DataSourceWrap = styled.div`
  border-radius: ${(p) => p.theme.misc.borderRadius};
  background-color: ${(p) => p.theme.color.card.background};
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
  border: 1px solid ${(p) => p.theme.color.card.divider};
  margin: ${(p) => p.theme.spacing.m} 0px;
`;

const AvatarWrap = styled.div`
  position: relative;
  margin-top: -3px;
`;

const StyledInstagramIcon = styled(InstagramIcon)`
  position: absolute;
  background-color: ${(p) => p.theme.color.card.background};
  padding: 2px;
  right: -2px;
  bottom: -4px;
  border-radius: 5px;
`;

const StyledTikTokIcon = styled(TikTokIcon)`
  position: absolute;
  background-color: ${(p) => p.theme.color.card.background};
  padding: 1px;
  right: -2px;
  bottom: -3px;
  border-radius: 5px;
`;

const Title = styled.p`
  font-family: ${(p) => p.theme.typography.bodyFamily};
  color: ${(p) => p.theme.color.typography.heading};
  font-size: ${(p) => p.theme.typography.size.m};
  line-height: 1.2em;
  font-weight: ${(p) => p.theme.typography.weight.semi};
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Username = styled.p<{ margin?: string }>`
  font-family: ${(p) => p.theme.typography.bodyFamily};
  color: ${(p) => p.theme.color.typography.secondary};
  font-size: ${(p) => p.theme.typography.size.s};
  line-height: 1.2em;
  margin: 0;
  ${withMargin};
`;

const TikTokWrap = styled(Flex)`
  width: 32px;
  padding-left: 2px;
`;

const InstagramWrap = styled(Flex)`
  width: 32px;
`;

const ToggleIcon = styled.img`
  border-radius: ${(p) => p.theme.misc.borderRadius};
`;

export interface MatchParams {
  page: string;
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

interface OAuthResponse {
  code: string | null;
  error: string | null;
  state: string | null;
}

export const Integrations = () => {
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const query = useQuery();
  const client = useGqlClient();
  const personalAccount = useSelector(authSelectors.account);
  const queryClient = useQueryClient();
  const { track } = useAnalytics();
  const theme = useTheme();

  const updateBrand = useUpdateBrandMutation(client);

  const brandQuery = useGetBrandQuery(client, {
    id: activeBrandId ? activeBrandId : "",
  });

  const connectToInstagramMutation = useConnectToInstagramMutation(client);
  const connectToTikTokMutation = useConnectToTikTokMutation(client);
  const createToggleUserMutation = useCreateToggleUserMutation(client);

  const stateHash = `${(+new Date().setHours(0, 0, 0, 0)).toString(
    36
  )}_instagram`;

  const [toggleUsername, setToggleUsername] = useState("");
  const [togglePassword, setTogglePassword] = useState("");
  const [toggleError, setToggleError] = useState("");
  const [showToggleModal, setShowToggleModal] = useState(false);
  const [showDmnModal, setShowDmnModal] = useState(false);

  const [showUpgradePrompt, setShowUpgradePrompt] = useState(false);
  const [dmnEnabled, setDmnEnabled] = useState(false);

  function handleToggleModalClose() {
    if (createToggleUserMutation.isLoading) {
      return;
    }
    setShowToggleModal(false);
    setToggleError("");
  }

  useEffect(() => {
    const handleOAuthFlow = ({ code, error, state }: OAuthResponse) => {
      if (!code) return;

      const cleanupUrl = () => {
        const url = new URL(window.location.href);
        ["code", "error", "error_reason", "error_description", "state"].forEach(
          (param) => {
            url.searchParams.delete(param);
          }
        );
        window.history.replaceState({}, "", url.toString());
      };

      // Instagram Flow
      if (state === stateHash) {
        connectToInstagramMutation.mutate(
          {
            code,
            redirectUri: `${window.location.origin}/b/settings`,
          },
          {
            onSuccess: () => {
              toast.success("Instagram connected");
              track("Instagram connected");
              brandQuery.refetch();
              cleanupUrl();
            },
            onError: (error) => {
              Sentry.captureException(error);
              toast.error("Failed to connect with Instagram");
              cleanupUrl();
            },
          }
        );
        return;
      }

      // TikTok Flow
      const csrf = query.get("state");
      if (csrf && !csrf.endsWith("tiktok")) {
        return;
      }

      connectToTikTokMutation.mutate(
        {
          ttCode: code,
          redirectUri: `${window.location.origin}/b/settings/`,
        },
        {
          onSuccess: () => {
            toast.success("TikTok connected");
            track("TikTok connected");
            brandQuery.refetch();
            cleanupUrl();
          },
          onError: (error) => {
            Sentry.captureException(error);
            toast.error("Failed to connect TikTok");
            cleanupUrl();
          },
        }
      );
    };

    const params: OAuthResponse = {
      code: query.get("code"),
      error: query.get("error"),
      state: query.get("state"),
    };

    if (params.error) {
      const service = params.state === stateHash ? "Instagram" : "TikTok";
      const errorMessage = `Failed to connect with ${service}`;
      toast.error(errorMessage);
      Sentry.captureException(params.error);
      return;
    }

    handleOAuthFlow(params);
  }, [
    query,
    connectToInstagramMutation,
    connectToTikTokMutation,
    brandQuery,
    stateHash,
    track,
  ]);

  useEffect(() => {
    if (!brandQuery.data || !brandQuery.data.brand) {
      return;
    }

    const data = brandQuery.data;

    setDmnEnabled(brandQuery.data.brand.dmnEnabled);

    const currentPlan = data.billingPlans.find(
      (bp) => bp.id === data.brand!.billingPlanId
    );

    const shouldShowUpgradePrompt =
      currentPlan &&
      currentPlan.planType === BillingPlanType.BillingPlanTypeStarter
        ? true
        : false;

    setShowUpgradePrompt(shouldShowUpgradePrompt);
  }, [brandQuery.data]);

  if (brandQuery.isLoading || !brandQuery.data || !personalAccount) {
    return <Loading />;
  }

  if (brandQuery.error || !brandQuery.data.brand) {
    return <Text>Something went wrong</Text>;
  }

  return (
    <StyledCard margin="l 0 xl 0">
      <H3 margin="0 0 0">Social Profiles</H3>
      <Text colorPreset="secondary" margin="0 0 0 0">
        Unlock analytics from influencer content
      </Text>
      {brandQuery.data.brand.instagram ? (
        brandQuery.data.brand.igConnectionExpired ? (
          <DataSourceWrap>
            <Flex justify="space-between" align="center">
              <Flex direction="row" align="center">
                <AvatarWrap>
                  <Avatar
                    borderSize={0}
                    withShadow={false}
                    style={{ position: "relative" }}
                    url={brandQuery.data!.brand!.instagram!.avatar}
                  />
                  <StyledInstagramIcon width={14} />
                </AvatarWrap>

                <Flex direction="column" margin="0 0 0 m">
                  <Title>{brandQuery.data!.brand!.instagram!.name}</Title>
                  <Flex align="center">
                    <Username margin="0">
                      @{brandQuery.data!.brand!.instagram!.username}
                    </Username>
                  </Flex>
                </Flex>
              </Flex>
              <Flex>
                <ExternalLink
                  href={`https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=0&client_id=${config.instagramAppId}&redirect_uri=${window.location.origin}/b/settings&response_type=code&scope=instagram_business_basic,instagram_business_manage_messages,instagram_business_manage_insights&state=${stateHash}`}
                >
                  <ReconnectButton buttonType="secondary" size="xs">
                    Reconnect
                  </ReconnectButton>
                </ExternalLink>
              </Flex>
            </Flex>
          </DataSourceWrap>
        ) : (
          <DataSourceWrap>
            <Flex justify="space-between" align="center">
              <Flex direction="row" align="center">
                <AvatarWrap>
                  <Avatar
                    borderSize={0}
                    withShadow={false}
                    style={{ position: "relative" }}
                    url={brandQuery.data.brand.instagram.avatar}
                  />
                  <StyledInstagramIcon width={14} />
                </AvatarWrap>
                <Flex direction="column" margin="0 0 0 m">
                  <Title>{brandQuery.data.brand.instagram.name}</Title>
                  <Flex align="center">
                    <Username margin="0">
                      @{brandQuery.data.brand.instagram.username}
                    </Username>
                  </Flex>
                </Flex>
              </Flex>
              <Flex>
                <ConnectedStatus>
                  <TickIcon
                    width={19}
                    color={theme.color.constructive}
                    style={{ marginTop: -1 }}
                  />
                  Connected
                </ConnectedStatus>
              </Flex>
            </Flex>
          </DataSourceWrap>
        )
      ) : (
        <DataSourceWrap>
          <Flex justify="space-between" align="center">
            <Flex direction="row" align="center">
              <InstagramWrap align="center" justify="center">
                <InstagramIcon width={34} />
              </InstagramWrap>

              <Flex direction="column" margin="0 0 0 m">
                <Title>Connect Instagram</Title>
              </Flex>
            </Flex>
            <Flex>
              <ExternalLink
                href={`https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=0&client_id=${config.instagramAppId}&redirect_uri=${window.location.origin}/b/settings&response_type=code&scope=instagram_business_basic,instagram_business_manage_messages,instagram_business_manage_insights&state=${stateHash}`}
              >
                <Button size="xs" buttonType="secondary">
                  Connect
                </Button>
              </ExternalLink>
            </Flex>
          </Flex>
        </DataSourceWrap>
      )}

      {brandQuery.data.brand.ttReportingEnabled &&
      brandQuery.data.brand.tikTok ? (
        <DataSourceWrap>
          <Flex justify="space-between" align="center">
            <Flex direction="row" align="center">
              <AvatarWrap>
                <Avatar
                  borderSize={0}
                  withShadow={false}
                  style={{ position: "relative" }}
                  url={brandQuery.data.brand.tikTok.avatar}
                />
                <StyledTikTokIcon width={14} />
              </AvatarWrap>
              <Flex direction="column" margin="0 0 0 m">
                <Title>{brandQuery.data.brand.tikTok.displayName}</Title>
                <Flex align="center">
                  <Username margin="0">
                    @{brandQuery.data.brand.tikTok.username}
                  </Username>
                </Flex>
              </Flex>
            </Flex>
            <Flex>
              <ConnectedStatus>
                <TickIcon
                  width={19}
                  color={theme.color.constructive}
                  style={{ marginTop: -1 }}
                />
                Connected
              </ConnectedStatus>
            </Flex>
          </Flex>
        </DataSourceWrap>
      ) : (
        <>
          <DataSourceWrap>
            <Flex justify="space-between" align="center">
              <Flex direction="row" align="center">
                <TikTokWrap align="center" justify="center">
                  <TikTokIcon width={36} />
                </TikTokWrap>
                <Flex direction="column" margin="0 0 0 m">
                  <Title>Connect Tik Tok</Title>
                </Flex>
              </Flex>
              <Flex>
                <ExternalLink
                  href={`${
                    config.apiEndpoint
                  }/oauth/tiktok?redirect_uri=${encodeURIComponent(
                    `${window.location.origin}/b/settings/`
                  )}&scope=user.info.basic,user.info.profile,user.info.stats,video.list`}
                >
                  <Button size="xs" buttonType="secondary">
                    Connect
                  </Button>
                </ExternalLink>
              </Flex>
            </Flex>
          </DataSourceWrap>
        </>
      )}

      <H3 margin="xl 0 0">Integrations</H3>
      <Text colorPreset="secondary" margin="0 0 0 0">
        Connect Joli to other tools your teams use
      </Text>
      <Modal
        isOpen={showToggleModal}
        setIsOpen={handleToggleModalClose}
        maxWidth={500}
      >
        <H2 margin="0 0 l">Log in to Toggle</H2>
        <Text margin="0 0 0">
          By connecting Toggle, we'll create and share gift cards for confirmed
          Joli bookings.{<br />}
          {<br />}Once connected, you'll be able to assign Toggle products and
          values when creating a listing.
          {<br />}
        </Text>
        <a
          target="_blank"
          rel="noreferrer"
          href="http://academy.usetoggle.com/en/articles/6428926-how-to-connect-nibble-app-and-toggle"
        >
          <Text
            size="s"
            margin="l 0 l"
            colorPreset="secondary"
            isInline
            style={{ textDecoration: "underline" }}
          >
            How do I connect Joli and Toggle?
          </Text>
        </a>
        <Input
          name="username"
          placeholder="Username"
          // label="Username"
          onChange={(e) => setToggleUsername(e.currentTarget.value)}
          margin="0 0 m 0"
        />
        <Input
          name="password"
          type="password"
          placeholder="Password"
          onChange={(e) => setTogglePassword(e.currentTarget.value)}
          margin="0 0 m 0"
        />
        {toggleError ? (
          <Text margin="0 0 m" colorPreset="warning">
            {toggleError}
          </Text>
        ) : null}
        <Button
          onClick={() => {
            if (createToggleUserMutation.isLoading) {
              return;
            }

            createToggleUserMutation.mutate(
              {
                username: toggleUsername,
                password: togglePassword,
              },
              {
                onSuccess: () => {
                  toast.success("Toggle connected");
                  track("Toggle connected");
                  setShowToggleModal(false);
                  brandQuery.refetch();
                },
                onError: (error) => {
                  Sentry.captureException(error);
                  setToggleError("Failed to connect to Toggle");
                },
              }
            );
          }}
        >
          {createToggleUserMutation.isLoading ? "Loading..." : "Login"}
        </Button>
      </Modal>
      <Modal isOpen={showDmnModal} setIsOpen={setShowDmnModal} maxWidth={500}>
        <H2 margin="0 0 l">Connect Access Collins</H2>
        <Text margin="0 0 0">
          By connecting Access Collins, we'll only show dates and times that are
          available for influencers to apply to.{<br />}
          {<br />}Once connected, you'll need to add your Collins site ID's to
          your Joli locations.
          {<br />}
        </Text>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://accessgroup.my.site.com/Support/s/article/Access-Collins-Where-can-I-find-the-site-ID"
        >
          <Text
            size="s"
            margin="l 0 l"
            colorPreset="secondary"
            isInline
            style={{ textDecoration: "underline" }}
          >
            How do I find my Access Collins site ID's?
          </Text>
        </a>
        <Button
          onClick={() => {
            updateBrand.mutate(
              {
                input: {
                  id: activeBrandId ? activeBrandId : "",
                  dmnEnabled: true,
                },
              },
              {
                onSuccess: () => {
                  toast.success("Access Collins connected");
                  track("Access Collins connected");
                  setDmnEnabled(true);
                  setShowDmnModal(false);
                  queryClient.resetQueries(["BrandLocations"], {
                    exact: false,
                  });
                },
              }
            );
          }}
        >
          Connect
        </Button>
      </Modal>

      {brandQuery.data.brand.toggleIntegrationEnabled ? (
        <DataSourceWrap>
          <Flex justify="space-between" align="center">
            <Flex direction="row" align="center">
              <TikTokWrap align="center" justify="center">
                <ToggleIcon
                  width={40}
                  src="https://wenibble-images.s3.eu-central-1.amazonaws.com/misc/partners/toggleIcon.png"
                />
              </TikTokWrap>
              <Flex direction="column" margin="0 0 0 m">
                <Title>Toggle</Title>
                <Flex align="center">
                  <Username margin="0">
                    Create gift cards for Joli bookings
                  </Username>
                </Flex>
              </Flex>
            </Flex>
            <Flex>
              <ConnectedStatus>
                <TickIcon
                  width={19}
                  color={theme.color.constructive}
                  style={{ marginTop: -1 }}
                />
                Connected
              </ConnectedStatus>
            </Flex>
          </Flex>
        </DataSourceWrap>
      ) : showUpgradePrompt ? (
        <DataSourceWrap style={{ overflow: "visible" }}>
          <Flex justify="space-between" align="center">
            <Flex direction="row" align="center">
              <TikTokWrap align="center" justify="center">
                <ToggleIcon
                  width={40}
                  src="https://wenibble-images.s3.eu-central-1.amazonaws.com/misc/partners/toggleIcon.png"
                />
              </TikTokWrap>
              <Flex direction="column" margin="0 0 0 m">
                <Title>Connect Toggle</Title>
                <Username margin="0">
                  Create gift cards for Joli bookings
                </Username>
              </Flex>
            </Flex>
            <Flex>
              <UpgradePrompt feature="toggle" />
            </Flex>
          </Flex>
        </DataSourceWrap>
      ) : (
        <DataSourceWrap>
          <Flex justify="space-between" align="center">
            <Flex direction="row" align="center">
              <TikTokWrap align="center" justify="center">
                <ToggleIcon
                  width={40}
                  src="https://wenibble-images.s3.eu-central-1.amazonaws.com/misc/partners/toggleIcon.png"
                />
              </TikTokWrap>
              <Flex direction="column" margin="0 0 0 m">
                <Title>Connect Toggle</Title>
                <Username margin="0">
                  Create gift cards for Joli bookings
                </Username>
              </Flex>
            </Flex>
            <Flex>
              <Button
                size="xs"
                buttonType="secondary"
                onClick={() => setShowToggleModal(!showToggleModal)}
              >
                Connect
              </Button>
            </Flex>
          </Flex>
        </DataSourceWrap>
      )}

      {dmnEnabled ? (
        <DataSourceWrap>
          <Flex justify="space-between" align="center">
            <Flex direction="row" align="center">
              <TikTokWrap align="center" justify="center">
                <ToggleIcon
                  width={40}
                  src="https://wenibble-images.s3.eu-central-1.amazonaws.com/misc/partners/access_collins.png"
                />
              </TikTokWrap>
              <Flex direction="column" margin="0 0 0 m">
                <Title>Access Collins</Title>
                <Flex align="center">
                  <Username margin="0">
                    Show real time booking availability
                  </Username>
                </Flex>
              </Flex>
            </Flex>
            <Flex>
              <ConnectedStatus>
                <TickIcon
                  width={19}
                  color={theme.color.constructive}
                  style={{ marginTop: -1 }}
                />
                Connected
              </ConnectedStatus>
            </Flex>
          </Flex>
        </DataSourceWrap>
      ) : showUpgradePrompt ? (
        <DataSourceWrap>
          <Flex justify="space-between" align="center">
            <Flex direction="row" align="center">
              <TikTokWrap align="center" justify="center">
                <ToggleIcon
                  width={40}
                  src="https://wenibble-images.s3.eu-central-1.amazonaws.com/misc/partners/access_collins.png"
                />
              </TikTokWrap>
              <Flex direction="column" margin="0 0 0 m">
                <Title>Connect Access Collins</Title>
                <Flex align="center">
                  <Username margin="0">
                    Show real time booking availability
                  </Username>
                </Flex>
              </Flex>
            </Flex>
            <UpgradePrompt feature="dmn" />
          </Flex>
        </DataSourceWrap>
      ) : (
        <DataSourceWrap>
          <Flex justify="space-between" align="center">
            <Flex direction="row" align="center">
              <TikTokWrap align="center" justify="center">
                <ToggleIcon
                  width={40}
                  src="https://wenibble-images.s3.eu-central-1.amazonaws.com/misc/partners/access_collins.png"
                />
              </TikTokWrap>
              <Flex direction="column" margin="0 0 0 m">
                <Title>Connect Access Collins</Title>
                <Flex align="center">
                  <Username margin="0">
                    Show real time booking availability
                  </Username>
                </Flex>
              </Flex>
            </Flex>
            <Flex>
              <Button
                size="xs"
                buttonType="secondary"
                onClick={() => setShowDmnModal(!showDmnModal)}
              >
                Connect
              </Button>
            </Flex>
          </Flex>
        </DataSourceWrap>
      )}
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  padding: ${(p) => p.theme.spacing.l} ${(p) => p.theme.spacing.l}
    ${(p) => p.theme.spacing.l};
  overflow: visible;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.l};
  }
`;

const ExternalLink = styled.a`
  text-decoration: none;
`;

const ConnectedStatus = styled.div`
  display: flex;
  align-items: center;
  gap: ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  border: 1px solid ${(p) => p.theme.color.constructive};
  padding: calc(${(p) => p.theme.spacing.s} - 2px) ${(p) => p.theme.spacing.l};
  color: ${(p) => p.theme.color.button.secondaryText};
  font-size: ${(p) => p.theme.typography.size.xs};
  font-weight: ${(p) => p.theme.typography.weight.bold};
  color: ${(p) => p.theme.color.constructive};
  user-select: none;

  svg {
    margin-left: -5px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    padding: calc(${(p) => p.theme.spacing.s} - 2px) ${(p) => p.theme.spacing.m};

    svg {
      display: none;
    }
  }
`;

const ReconnectButton = styled(Button)`
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  border: 1px solid ${(p) => p.theme.color.warning};
  padding: calc(${(p) => p.theme.spacing.s} - 2px) ${(p) => p.theme.spacing.l};
  color: ${(p) => p.theme.color.warning};
  font-size: ${(p) => p.theme.typography.size.xs};
  font-weight: ${(p) => p.theme.typography.weight.bold};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    padding: calc(${(p) => p.theme.spacing.s} - 2px) ${(p) => p.theme.spacing.m};
  }
`;
