import { SVGProps } from "react";
import styled from "../../../styles";

const VerticalFormatSVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="13"
    height="17"
    viewBox="0 0 13 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0.12793 14.2197V3.21143C0.12793 2.40576 0.335449 1.79053 0.750488 1.36572C1.17041 0.940918 1.7832 0.728516 2.58887 0.728516H10.4111C11.2119 0.728516 11.8223 0.940918 12.2422 1.36572C12.6621 1.79053 12.8721 2.40576 12.8721 3.21143V14.2197C12.8721 15.0303 12.6621 15.6455 12.2422 16.0654C11.8223 16.4854 11.2119 16.6953 10.4111 16.6953H2.58887C1.7832 16.6953 1.17041 16.4854 0.750488 16.0654C0.335449 15.6455 0.12793 15.0303 0.12793 14.2197ZM1.75391 14.0806C1.75391 14.4028 1.83691 14.647 2.00293 14.813C2.16895 14.9839 2.42041 15.0693 2.75732 15.0693H10.2427C10.5796 15.0693 10.8286 14.9839 10.9897 14.813C11.1558 14.647 11.2388 14.4028 11.2388 14.0806V3.35059C11.2388 3.0332 11.1558 2.78906 10.9897 2.61816C10.8286 2.44727 10.5796 2.36182 10.2427 2.36182H2.75732C2.42041 2.36182 2.16895 2.44727 2.00293 2.61816C1.83691 2.78906 1.75391 3.0332 1.75391 3.35059V14.0806Z" />
  </svg>
);

export const VerticalFormatIcon = styled(VerticalFormatSVG)<{
  active: boolean;
}>`
  ${(p) => {
    const fill = p.active
      ? p.theme.color.primary
      : `${p.theme.color.typography.secondary}50`;
    return { fill };
  }}
`;
