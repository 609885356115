import { Redirect, Route, Switch } from "react-router-dom";
import { BrandNavigation } from "../../components/Brand/BrandNavigation";
import { Page } from "../../components/Page";
import { MOBILE_BREAKPOINT } from "../../config";
import { useWindowSize } from "../../hooks/useWindowSize";
import styled from "../../styles";
import { AccountSettings } from "./AccountSettings";
import { Billing } from "./Billing";
import { BrandBookingsView } from "./bookings";
import BookingDetailsView from "./bookings/BookingDetailsView";
import { BrandPage } from "./Brand";
import { BriefForm } from "./brief";
import { BrandBriefsView } from "./briefs";
import { BrandContent } from "./Content";
import { CreateLocation } from "./CreateLocation";
import ListingForm from "./Listing";
import { ListingInvites } from "./ListingInvites";
import { BrandListings } from "./listings";
import { BrandLocation } from "./Location";
import { BrandLocations } from "./Locations";
import { BrandReport } from "./Report";
import { BrandReports } from "./Reports";
import { BrandSettings } from "./Settings";

const NavWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

export const PageContent = styled.div<{ paddingTop?: true; noNav?: boolean }>`
  flex: 1;
  display: flex;
  justify-content: center;
  direction: column;
  max-width: 100%;
  box-sizing: border-box;
  border-radius: 18px;
  margin: ${(p) => p.theme.spacing.m};
  margin-left: ${(p) => (p.noNav ? p.theme.spacing.m : 0)};
  max-height: calc(100vh - ${(p) => p.theme.spacing.m} * 2);
  overflow-y: auto;
  background: linear-gradient(98deg, ${(p) =>
    p.theme.color.background.start} 0%, ${(p) =>
  p.theme.color.background.end} 100%), ${(p) => p.theme.color.background.start};
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

@media (min-width: ${MOBILE_BREAKPOINT}px) {
  padding-top: ${(p) => (p.paddingTop ? p.theme.spacing.l : p.theme.spacing.m)};
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    border-radius: 0px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0;
    max-height: none;
    margin-top: 0px;
  }
`;

export const BrandRoot = () => {
  const window = useWindowSize();
  const isMobile = window.width && window.width < MOBILE_BREAKPOINT;

  return (
    <Page noHorizontalPadding>
      <NavWrap>
        <BrandNavigation />
        <PageContent paddingTop>
          <Switch>
            <Redirect from="/b/bookings/pending" to="/b/bookings/new" exact />
            <Redirect from="/b/bookings" to="/b/bookings/new" exact />
            <Redirect from="/b/briefs" to="/b/briefs/drafts" exact />
            <Redirect from="/b/profile" to="/b/settings#profile" exact />
            <Redirect from="/b/team" to="/b/settings#team" exact />
            <Redirect
              from="/b/bookings/rescheduled"
              to="/b/bookings/new"
              exact
            />
            <Redirect
              from="/b/bookings/booking/:id"
              to="/b/bookings/new/:id"
              exact
            />
            <Route
              exact
              path="/b/bookings/:tab/:bookingId"
              component={isMobile ? BookingDetailsView : BrandBookingsView}
            />
            <Route path="/b/bookings/:tab">
              <BrandBookingsView />
            </Route>
            <Route exact path="/b/listings/:id/">
              <ListingForm />
            </Route>
            <Route path="/b/listings">
              <BrandListings />
            </Route>
            <Route path="/b/create-listing">
              <ListingForm />
            </Route>
            <Route exact path="/b/locations/:id/">
              <BrandLocation />
            </Route>
            <Route path="/b/locations">
              <BrandLocations />
            </Route>
            <Route path="/b/create-location">
              <CreateLocation />
            </Route>
            <Route path="/b/brand">
              <BrandPage />
            </Route>
            <Route path="/b/billing">
              <Billing />
            </Route>
            <Route path="/b/settings">
              <BrandSettings />
            </Route>
            <Route path="/b/account-settings">
              <AccountSettings />
            </Route>

            <Route exact path="/b/reports/:id/">
              <BrandReport />
            </Route>
            <Route path="/b/reports">
              <BrandReports />
            </Route>
            <Route path="/b/content">
              <BrandContent />
            </Route>
            <Route path="/b/briefs/:id/edit">
              <BriefForm />
            </Route>
            <Route path="/b/briefs/create-brief">
              <BriefForm />
            </Route>
            <Route path="/b/briefs/:tab/:briefId?">
              <BrandBriefsView />
            </Route>
            <Route path="/b/listing-invites">
              <ListingInvites />
            </Route>
            <Route path="/b" exact>
              <Redirect to="/b/bookings" />
            </Route>
          </Switch>
        </PageContent>
      </NavWrap>
    </Page>
  );
};
