import { GraphQLClient } from "graphql-request";
import { useCallback, useEffect, useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import { Card } from "../../components/Card";
import { CloseIcon } from "../../components/CloseIcon";
import { Button } from "../../components/CTA";
import { Dropdown } from "../../components/Dropdown";
import { Flex } from "../../components/Flex";
import { H1 } from "../../components/Heading";
import Loader from "../../components/Loader";
import Loading from "../../components/Loading";
import { Subtitle } from "../../components/Subtitle";
import { Tag } from "../../components/Tag";
import { Text } from "../../components/Text";
import { View } from "../../components/View";
import { MOBILE_BREAKPOINT, XL_DESKTOP_BREAKPOINT } from "../../config";
import {
  BrandListingsQuery,
  GetListingInvitesDocument,
  GetListingInvitesQuery,
  ListingStatus,
  useBrandListingsQuery,
  useCategoriesQuery,
  useDietaryPreferencesQuery,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { authSelectors } from "../../store/auth/selector";
import { styled } from "../../styles";

const Wrap = styled.div`
  height: 100%;
  padding: 0 ${(p) => p.theme.spacing.l} ${(p) => p.theme.spacing.l};
  box-sizing: border-box;
`;

const HeaderWrap = styled(Flex)`
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: ${(p) => p.theme.spacing.m};
    margin-bottom: ${(p) => p.theme.spacing.m};
  }
`;

const TableWrap = styled.div`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Allows inertial scrolling on iOS */
`;

export const ListingInvites = () => {
  const client = useGqlClient();
  const [selectedListing, setSelectedListing] = useState<
    BrandListingsQuery["listings"]["listings"][0] | undefined
  >(undefined);
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const { isLoading: listingsIsLoading, data: listingsData } =
    useBrandListingsQuery(client, { brandId: activeBrandId });
  const [activeCreatorId, setActiveCreatorId] = useState<string | null>(null);

  useEffect(() => {
    if (!listingsData) {
      return;
    }
    setSelectedListing(
      listingsData.listings.listings.filter(
        (e) =>
          e.status === ListingStatus.StatusActive ||
          e.status === ListingStatus.StatusScheduled
      )[0]
    );
  }, [listingsData]);

  if (listingsIsLoading) {
    return <Loading defer />;
  }

  const activeListings = listingsData?.listings.listings.filter(
    (e) => e.status === ListingStatus.StatusActive
  );

  return (
    <Wrap>
      <HeaderWrap>
        <div>
          <H1 margin="0">Listing Invites</H1>
          <Subtitle margin="xs 0 l 0">
            Currently ONLY visible to Joli Staff
          </Subtitle>
        </div>
        <Flex>
          {activeListings && activeListings.length > 0 ? (
            <Dropdown
              options={activeListings!.map((e) => {
                return { value: e.id, label: e.name };
              })}
              selectedOptions={selectedListing?.id ? [selectedListing.id] : []}
              setSelectedOptions={(e) => {
                setSelectedListing(
                  activeListings?.find((listing) => listing.id === e[0])
                );
              }}
              renderLabel={() => selectedListing?.name || ""}
              selectionMode="single"
            />
          ) : null}
        </Flex>
      </HeaderWrap>

      {selectedListing && selectedListing.id && activeBrandId ? (
        <Card>
          <TableWrap>
            <InvitesTable
              selectedListing={selectedListing}
              client={client}
              activeBrandId={activeBrandId}
              setActiveCreatorId={setActiveCreatorId}
            />
          </TableWrap>
        </Card>
      ) : null}

      <Preview
        isOpen={!!activeCreatorId}
        onClose={() => setActiveCreatorId(null)}
        name={selectedListing?.name}
      />
    </Wrap>
  );
};

interface InvitesTableProps {
  selectedListing: BrandListingsQuery["listings"]["listings"][0];
  client: GraphQLClient;
  activeBrandId: string | null;
  setActiveCreatorId: (creatorId: string | null) => void;
}

const InvitesTable = ({
  selectedListing,
  client,
  activeBrandId,
  setActiveCreatorId,
}: InvitesTableProps) => {
  const { data: categoriesData } = useCategoriesQuery(client);
  const { data: dietaryData } = useDietaryPreferencesQuery(client);

  const useInfinitePosts = () => {
    return useInfiniteQuery(
      "['getListingInvites', variables]",
      ({ pageParam }) =>
        getInvites(pageParam, activeBrandId ? activeBrandId : ""),
      {
        getNextPageParam: (lastPage) => {
          if (!lastPage.listingInvites.hasMore) {
            return;
          }

          return lastPage.listingInvites.cursor;
        },
      }
    );
  };

  async function getInvites(cursor: string, brandID: string) {
    return client.request<GetListingInvitesQuery>(GetListingInvitesDocument, {
      cursor,
      listingId: selectedListing.id,
      brandID,
      count: 10,
    });
  }

  const {
    data,
    refetch,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfinitePosts();

  useEffect(() => {
    refetch();
  }, [selectedListing, activeBrandId, refetch]);

  const observer = useRef<IntersectionObserver | null>(null);

  const lastPostElementRef = useCallback(
    (node: any) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasNextPage]
  );

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th scope="col" className="widerColumn">
              Name
            </th>
            <th scope="col">Handle</th>
            <th scope="col">Followers</th>
            <th scope="col">Categories</th>
            <th scope="col">Dietary</th>
            <th scope="col">Nearest location</th>
            <th scope="col">Score</th>
            <th scope="col" />
          </tr>
        </thead>
        {data ? (
          <tbody>
            {data.pages
              .flatMap((page) => page.listingInvites.creators)
              .filter((c): c is NonNullable<typeof c> => Boolean(c))
              .filter(
                (
                  c
                ): c is typeof c & { creator: NonNullable<typeof c.creator> } =>
                  Boolean(c?.creator)
              )
              .map((c) => {
                const categoryLabels =
                  categoriesData?.categories && c.creator.categories
                    ? c.creator.categories
                        .map(
                          (categoryId: string) =>
                            categoriesData.categories.find(
                              (category) => category.id === categoryId
                            )?.label || "Unknown"
                        )
                        .sort()
                    : [];

                const dietaryPrefs =
                  dietaryData?.dietaryPreferences &&
                  c.creator.dietaryPreferences
                    ? c.creator.dietaryPreferences
                        .map(
                          (dietaryId: string) =>
                            dietaryData.dietaryPreferences.find(
                              (d) => d.id === dietaryId
                            )?.label || "Unknown"
                        )
                        .sort()
                    : [];

                if (!c.creator.preferredProfile) {
                  return null;
                }

                const creatorProfile = c.creator.preferredProfile;

                const isNewPageFirstElement = data.pages.some(
                  (page) =>
                    page.listingInvites.creators.length > 0 &&
                    page.listingInvites.creators[0]?.creator?.id ===
                      c.creator.id
                );

                const socialLink = c.creator.preferredProfile.permalink;
                return (
                  <Row
                    key={c.creator.id}
                    ref={isNewPageFirstElement ? lastPostElementRef : null}
                    onClick={() => setActiveCreatorId(c.creator.id)}
                  >
                    <td className="widerColumn">
                      <Text margin="0" colorPreset="text" isCompact truncate>
                        {creatorProfile.name}
                      </Text>
                    </td>
                    <td>
                      <Text margin="0" colorPreset="text" truncate>
                        {creatorProfile.username}
                      </Text>
                    </td>
                    <td>
                      <Text margin="0" colorPreset="text">
                        {c.creator.preferredProfile?.followers.toLocaleString()}
                      </Text>
                    </td>
                    <td>
                      <Text margin="0" colorPreset="secondary" size="s">
                        {categoryLabels && categoryLabels.join(", ")}
                      </Text>
                    </td>
                    <td>
                      <Text margin="0" colorPreset="secondary" size="s">
                        {dietaryPrefs && dietaryPrefs.join(", ")}
                      </Text>
                    </td>
                    <td>
                      <Flex direction="column">
                        <Text margin="0" colorPreset="text" isCompact>
                          {c.nearestLocation}
                        </Text>
                        <Text margin="0" colorPreset="secondary">
                          {Math.round(c.nearestLocationDistance / 1000)}km away
                        </Text>
                      </Flex>
                    </td>
                    <td>
                      <Tag
                        shadow={false}
                        padding="l"
                        color="green"
                        text={`${Math.round(c.similarityScore * 100)}% match`}
                      />
                    </td>
                    <td>
                      <Button
                        size="xs"
                        buttonType="tertiary"
                        margin="0"
                        onClick={() => window.open(socialLink, "_blank")}
                      >
                        Open Instagram
                      </Button>
                    </td>
                  </Row>
                );
              })}
          </tbody>
        ) : null}
      </Table>
      {isLoading || isFetchingNextPage ? (
        <Flex margin="xl 0" align="center" justify="center">
          <Loader />
        </Flex>
      ) : !data || data.pages[0].listingInvites.creators.length === 0 ? (
        <Text align="center" margin="xl 0 m">
          No creators found 😢
        </Text>
      ) : null}
    </>
  );
};

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  box-sizing: border-box;

  .widerColumn {
    width: 160px;
  }

  thead,
  tbody tr {
    display: table;
    min-width: 100%;
    table-layout: fixed; /* This helps to keep the columns aligned */
  }

  tbody {
    display: block;
    height: 80vh;
    overflow-y: scroll;
    min-width: 100%;
  }

  th,
  td {
    text-align: left;
    padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
    border-bottom: 1px solid ${(p) => p.theme.color.card.divider};
    width: 130px;
  }

  th {
    background-color: ${(p) => p.theme.color.card.callout};
    font-size: ${(p) => p.theme.typography.size.xs};
    font-weight: ${(p) => p.theme.typography.weight.bold};
    color: ${(p) => p.theme.color.typography.secondary};
    text-transform: uppercase;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  tbody {
    overflow-y: scroll;
  }

  tr:last-of-type {
    td {
      border-bottom: none;
    }
  }
`;

const Row = styled.tr`
  display: table;
  width: 100%;
  cursor: pointer;
  table-layout: fixed;
  background-color: ${(p) => p.theme.color.card.background};

  :hover {
    background-color: ${(p) => p.theme.color.card.callout};
  }
`;

interface SlideoutDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  name?: string;
}

export const Preview = (props: SlideoutDrawerProps) => {
  if (!props.isOpen) return null;

  return <AnimatedDrawer {...props} />;
};

const AnimatedDrawer: React.FC<Omit<SlideoutDrawerProps, "isOpen">> = ({
  onClose,
  name,
}) => {
  const handleWrapperClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <BackgroundWrapper onClick={handleWrapperClick}>
      <DrawerWrapper>
        <div style={{ position: "relative" }}>
          <CloseWrapper onClick={onClose}>
            <CloseIcon color={"#fff"} />
          </CloseWrapper>
          <View padding="m l">
            <H1 margin="l 0 xs">{name}</H1>
          </View>
        </div>
      </DrawerWrapper>
    </BackgroundWrapper>
  );
};

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  z-index: 999;
  animation: fadeIn 0.2s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const DrawerWrapper = styled.div`
  position: fixed;
  top: ${(p) => p.theme.spacing.xl};
  right: ${(p) => p.theme.spacing.xl};
  width: 390px;
  bottom: ${(p) => p.theme.spacing.xl};
  z-index: 1000;
  overflow: scroll;
  animation: slideIn 0.2s ease-in-out;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  box-shadow: ${(p) => p.theme.shadow.card};
  background-color: ${(p) => p.theme.color.card.background};

  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    height: 100%; // Full height on mobile
    top: 0;
    right: 0;
  }

  @media (min-width: ${XL_DESKTOP_BREAKPOINT}px) {
    width: 420px;
    top: ${(p) => p.theme.spacing.xl};
    right: ${(p) => p.theme.spacing.xl};
    bottom: ${(p) => p.theme.spacing.xl};
  }
`;

const CloseWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: ${(p) => p.theme.spacing.m};
  left: ${(p) => p.theme.spacing.m};
  width: 32px;
  height: 32px;
  display: flex;
  text-decoration: none;
  border-radius: 999px;
  box-shadow: ${(p) => p.theme.shadow.imageFloatingIcon};
  justify-content: center;
  align-items: center;
  background-color: #000000cc;
  padding: 2px;
  z-index: 3;

  &:hover {
    background: #000;
  }
`;
