import React, { useEffect, useState } from "react";
import { useTheme } from "../hooks/useTheme";
import styled, { css } from "../styles";
import { WithMarginProp } from "../styles/withMargin";
import { CardDivider } from "./Divider";
import { Flex } from "./Flex";
import { Text } from "./Text";
import { ExternalTextLink } from "./TextLink";
import { View } from "./View";
import { TickIcon } from "./icons/TickIcon";

type Option = { label: string; value: string; isChecked: boolean };

type InputProps = {
  label: string;
  name?: string;
  options: Option[];
  placeholder?: string;
  help?: string;
  helpWithLink?: {
    preText: string;
    linkLabel: string;
    linkURL: string;
    postText?: string;
  };
  type?: string;
  error?: string;
  onChange?: (updatedOptions: Option[]) => void;
};

export const Checkbox: React.FC<InputProps & WithMarginProp> = (props) => {
  const theme = useTheme();
  const [options, setOptions] = useState<Option[]>(props.options);

  useEffect(() => {
    setOptions(props.options);
  }, [props.options]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCheckedState = event.target.checked;
    const newOptions = options.map((option) => ({
      ...option,
      isChecked: newCheckedState,
    }));
    setOptions(newOptions);
    if (props.onChange) {
      props.onChange(newOptions);
    }
  };

  const handleOptionChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newOptions = [...options];
      newOptions[index].isChecked = event.currentTarget.checked;
      setOptions(newOptions);
      if (props.onChange) {
        props.onChange(newOptions);
      }
    };

  return (
    <Flex direction="column" margin={props.margin}>
      <Text weight="semi" margin="0">
        {props.label}
      </Text>
      {props.helpWithLink && (
        <Text isInline size="s" margin="0" colorPreset="secondary">
          {props.helpWithLink.preText}&nbsp;
          <ExternalTextLink
            size="s"
            colorPreset="secondary"
            isInline
            href={props.helpWithLink.linkURL}
            target="_blank"
          >
            {props.helpWithLink.linkLabel}
          </ExternalTextLink>
          &nbsp;{props.helpWithLink.postText}
        </Text>
      )}
      {props.help && (
        <Text size="s" margin="0" colorPreset="secondary">
          {props.help}
        </Text>
      )}

      <InputWrapper isError={!!props.error}>
        <Flex align="baseline" justify="space-between">
          <StyledLabel>
            <FakeCheckbox
              name={`selectAll`}
              checked={options.every((option) => option.isChecked)}
              onChange={handleSelectAll}
            />
            <CheckboxLabel>Select All</CheckboxLabel>
          </StyledLabel>
        </Flex>
        <CardDivider margin="0" />
        {options.map((o, i) => (
          <React.Fragment key={o.value}>
            <Flex align="baseline">
              <StyledLabel>
                <View margin="0 0 0 m">
                  <FakeCheckbox
                    name={`${props.name}[${o.value}]`}
                    checked={o.isChecked}
                    onChange={handleOptionChange(i)}
                  />
                </View>
                <CheckboxLabel>{o.label}</CheckboxLabel>
              </StyledLabel>
            </Flex>
            {i !== options.length - 1 ? <CardDivider margin="0" /> : null}
          </React.Fragment>
        ))}
      </InputWrapper>

      {props.error && (
        <Text size="m" margin="s 0 0 0" color={theme.color.destructive}>
          {props.error}
        </Text>
      )}
    </Flex>
  );
};

interface FakeCheckboxProps {
  name: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  size?: "s";
}

const FakeCheckbox: React.FC<FakeCheckboxProps> = (props) => {
  const [checked, setChecked] = useState(props.checked);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  return (
    <>
      <CheckWrap aria-hidden checked={checked}>
        {checked ? (
          <TickWrap align="center" justify="center">
            <TickIcon width={16} height={16} color="#fff" />
          </TickWrap>
        ) : null}
      </CheckWrap>
      <OriginalInput
        type="checkbox"
        name={props.name}
        checked={props.checked}
        onChange={(e) => {
          setChecked(e.currentTarget.checked);
          props.onChange(e);
        }}
      />
    </>
  );
};

export const SingleCheckBox = (props: { checked: boolean; size?: "s" }) => {
  return (
    <SingleCheckboxLabel>
      <SingleCheckWrap aria-hidden isChecked={props.checked} size={props.size}>
        {props.checked ? (
          <TickWrap align="center" justify="center">
            <TickIcon
              width={props.size === "s" ? 12 : 16}
              height={props.size === "s" ? 12 : 16}
              color="#fff"
            />
          </TickWrap>
        ) : null}
      </SingleCheckWrap>
      <OriginalInput
        onClick={(e) => e.stopPropagation()}
        type="checkbox"
        style={{ pointerEvents: "none" }}
      />
    </SingleCheckboxLabel>
  );
};

const OriginalInput = styled.input`
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  cursor: pointer;
`;

const TickWrap = styled(Flex)`
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.theme.color.primary};
`;

const CheckWrap = styled.div<{ checked: boolean; size?: "s" }>`
  width: ${(p) => (p.size === "s" ? 16 : 18)}px;
  height: ${(p) => (p.size === "s" ? 16 : 18)}px;
  border: 1px solid
    ${(p) =>
      p.checked
        ? p.theme.color.primary
        : `${p.theme.color.typography.secondary}50`};
  border-radius: 3px;
  display: flex;
`;

const SingleCheckWrap = styled.div<{ isChecked: boolean; size?: "s" }>`
  width: ${(p) => (p.size === "s" ? 16 : 18)}px;
  height: ${(p) => (p.size === "s" ? 16 : 18)}px;
  border: 1px solid
    ${(p) =>
      p.isChecked
        ? p.theme.color.primary
        : `${p.theme.color.typography.secondary}50`};
  border-radius: 4px;
  display: flex;
`;

const StyledLabel = styled.label`
  width: 100%;
  display: flex;
  line-height: 1em;
  align-items: center;
  padding: ${(p) => p.theme.spacing.m};
  position: relative;
  user-select: none;

  &:hover {
    background-color: ${(p) => p.theme.color.input.hover};
    cursor: pointer;
  }

  & > span {
    padding-left: ${(p) => p.theme.spacing.s};
  }
`;

const SingleCheckboxLabel = styled.label`
  position: relative;
  cursor: pointer;
`;

const CheckboxLabel = styled.label`
  font-size: ${(p) => p.theme.typography.size.s};
  color: ${(p) => p.theme.color.typography.text};
  margin-left: ${(p) => p.theme.spacing.m};
  margin-bottom: 0px;
`;

const InputWrapper = styled.div<{ isError?: boolean }>`
  background-color: ${(p) => p.theme.color.card.background};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  font-size: ${(p) => p.theme.typography.size.m};
  margin-top: ${(p) => p.theme.spacing.s};
  overflow: hidden;

  color: ${(p) => p.theme.color.typography.text};

  &::placeholder {
    color: ${(p) => p.theme.color.typography.secondary};
  }

  border: 1px solid ${(p) => p.theme.color.card.divider};
  transition: border-color 0.1s;

  &:hover {
    border: 1px solid ${(p) => p.theme.color.typography.secondary};
    outline: none;
  }

  &:focus-within {
    border-color: ${(p) => p.theme.color.primary};
    outline: none;
  }

  ${(p) =>
    p.isError
      ? css`
          border-color: ${(p) => p.theme.color.destructive};
        `
      : null}
`;
