import { Popover } from "@headlessui/react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../config";
import { BookingPostType } from "../../graphql/generated";
import { Card } from "../Card";
import { Flex } from "../Flex";
import { H3 } from "../Heading";
import { FakeInput } from "../Input";
import { Text } from "../Text";
import { TextLink } from "../TextLink";
import { View } from "../View";
import { FilterIcon } from "../icons/FilterIcon";

export interface FilterOption {
  label: string;
  id: string | BookingPostType;
}

const DayIndicator = styled.span<{ active: boolean }>`
  text-align: right;
  opacity: ${(p) => (p.active ? 1 : 0)};
  line-height: 12px;
  width: 8px;
  height: 8px;
  font-size: 42px;
  color: ${(p) => p.theme.color.primary};
`;

export const content: FilterOption[] = [
  { id: BookingPostType.BookingPostTypeFeed, label: "Instagram Posts" },
  { id: BookingPostType.BookingPostTypeReel, label: "Instagram Reels" },
  { id: BookingPostType.BookingPostTypeStory, label: "Instagram Stories" },
  { id: BookingPostType.BookingPostTypeTiktok, label: "TikTok" },
];

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  min-width: 500px;
  min-height: 250px;
  max-width: 100%;
  position: relative;
  z-index: 2;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 0px;
  }
`;

const ScrollableContent = styled.div`
  overflow-y: scroll;
  max-height: 300px;
  padding: 0 0 ${(p) => p.theme.spacing.s};
  overflow-x: scrollable;
`;

const Column = styled(Flex)`
  border-right: 1px solid ${(p) => p.theme.color.card.divider};
  min-width: 170px;

  &:last-of-type {
    border-right: none;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 120px;
  }
`;

const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  min-height: ${(p) => p.theme.spacing.xl};
  padding: ${(p) => p.theme.spacing.s} 0;
  height: ${(p) => p.theme.spacing.xl};
  border-bottom: 1px solid ${(p) => p.theme.color.card.divider};
`;

const Row = styled(Flex)`
  padding: calc(${(p) => p.theme.spacing.s} + 2px) ${(p) => p.theme.spacing.l};
  color: ${(p) => p.theme.color.typography.secondary};
  margin: 0 ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.xs};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  user-select: none;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover,
  &.isActive {
    background-color: ${(p) => p.theme.color.input.hover};

    p {
      color: ${(p) => p.theme.color.primary};
    }

    svg {
      path {
        fill: ${(p) => p.theme.color.primary};
      }
    }
  }

  cursor: pointer;
`;

const Title = styled.p`
  font-family: ${(p) => p.theme.typography.bodyFamily};
  color: ${(p) => p.theme.color.typography.text};
  font-size: ${(p) => p.theme.typography.size.m};
  line-height: 1.3em;
  font-weight: ${(p) => p.theme.typography.weight.semi};
  margin: 0;
`;

const StyledPopover = styled(Popover)`
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    position: relative;
  }
`;

const StyledPopoverPanel = styled(Popover.Panel)`
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 2;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    position: absolute;
    top: 90px;
    left: ${(p) => p.theme.spacing.m};
    right: ${(p) => p.theme.spacing.m};
  }
`;

interface FilterComponentProps {
  selectedContentType: string[];
  setSelectedContentType: React.Dispatch<
    React.SetStateAction<BookingPostType[]>
  >;
  listings: FilterOption[];
  selectedListings: string[];
  setSelectedListings: React.Dispatch<React.SetStateAction<string[]>>;
  locations: FilterOption[];
  selectedLocations: string[];
  setSelectedLocations: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ReportFilter = ({
  locations,
  selectedLocations,
  setSelectedLocations,
  setSelectedContentType,
  selectedContentType,
  listings,
  selectedListings,
  setSelectedListings,
}: FilterComponentProps) => {
  const [tab, setTab] = useState<"listings" | "locations" | "content">(
    "listings"
  );
  const [searchQuery, setSearchQuery] = useState("");

  const listingFilterCount = listings.length - selectedListings.length;

  const locationFilterCount = locations.length - selectedLocations.length;

  const contentFilterCount = content.length - selectedContentType.length;

  const selectedFilterCount =
    listingFilterCount + locationFilterCount + contentFilterCount;

  const handleToggle = (
    id: string | BookingPostType,
    tab: "listings" | "locations" | "content"
  ) => {
    switch (tab) {
      case "listings":
        return () =>
          setSelectedListings((prev) =>
            prev.includes(id)
              ? prev.filter((item) => item !== id)
              : [...prev, id]
          );
      case "locations":
        return () =>
          setSelectedLocations((prev) =>
            prev.includes(id)
              ? prev.filter((item) => item !== id)
              : [...prev, id]
          );
      case "content":
        return () =>
          setSelectedContentType((prev) =>
            prev.includes(id as BookingPostType)
              ? prev.filter((item) => item !== id)
              : [...prev, id as BookingPostType]
          );
    }
  };

  const handleToggleOnly = (
    id: string | BookingPostType,
    tab: "listings" | "locations" | "content"
  ) => {
    switch (tab) {
      case "listings":
        return () => setSelectedListings([id]);
      case "locations":
        return () => setSelectedLocations([id]);
      case "content":
        return () => setSelectedContentType([id as BookingPostType]);
    }
  };

  const handleSelectAll = (tab: "listings" | "locations" | "content") => {
    switch (tab) {
      case "listings":
        return () => {
          if (selectedListings.length === listings.length) {
            setSelectedListings([]);
          } else {
            setSelectedListings(listings.map((item) => item.id));
          }
        };
      case "locations":
        return () => {
          if (selectedLocations.length === locations.length) {
            setSelectedLocations([]);
          } else {
            setSelectedLocations(locations.map((item) => item.id));
          }
        };
      case "content":
        return () => {
          if (selectedContentType.length === content.length) {
            setSelectedContentType([]);
          } else {
            setSelectedContentType(
              content.map((item) => item.id as BookingPostType)
            );
          }
        };
    }
  };

  const isSelected = (
    id: string,
    type: "listings" | "locations" | "content"
  ) => {
    return type === "listings"
      ? selectedListings.includes(id)
      : type === "locations"
      ? selectedLocations.includes(id)
      : selectedContentType.includes(id);
  };

  const filteredItems = (
    tab === "listings" ? listings : tab === "locations" ? locations : content
  ).filter((item) =>
    item.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    setSearchQuery("");
  }, [tab]);

  return (
    <StyledPopover>
      {({ open }) => (
        <>
          <View margin="0 0 0 s">
            <Popover.Button
              as="div"
              style={{ cursor: "pointer", userSelect: "none" }}
            >
              <FakeInput isActive={open}>
                <Flex
                  align="center"
                  justify="center"
                  direction="row"
                  margin="0 m"
                  style={{ minHeight: 38 }}
                >
                  <FilterIcon colorPreset="secondary" />
                  <Text margin="0 0 0 s" colorPreset="secondary">
                    {selectedFilterCount > 0 ? `Filters applied` : `Filters`}
                  </Text>
                </Flex>
              </FakeInput>
            </Popover.Button>
          </View>

          <StyledPopoverPanel>
            <Card margin="0 0 0" shadow="heavy">
              <Grid>
                <Column direction="column">
                  <HeaderWrap>
                    <H3 margin="0 0 0 m">Filters</H3>
                  </HeaderWrap>
                  <View padding="m 0 0">
                    <Row
                      dir="row"
                      align="center"
                      justify="space-between"
                      onClick={() => setTab("listings")}
                      className={tab === "listings" ? "isActive" : undefined}
                    >
                      <Title>Listing</Title>
                      <DayIndicator active={listingFilterCount > 0}>
                        ·
                      </DayIndicator>
                    </Row>
                    <Row
                      dir="row"
                      align="center"
                      justify="space-between"
                      onClick={() => setTab("locations")}
                      className={tab === "locations" ? "isActive" : undefined}
                    >
                      <Title>Location</Title>
                      <DayIndicator active={locationFilterCount > 0}>
                        ·
                      </DayIndicator>
                    </Row>
                    <Row
                      dir="row"
                      align="center"
                      justify="space-between"
                      onClick={() => setTab("content")}
                      className={tab === "content" ? "isActive" : undefined}
                    >
                      <Title>Content</Title>
                      <DayIndicator active={contentFilterCount > 0}>
                        ·
                      </DayIndicator>
                    </Row>
                  </View>
                </Column>

                {/* Filter Options */}
                <Column direction="column">
                  <HeaderWrap>
                    <SearchInput
                      type="text"
                      placeholder={`Search ${tab}...`}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </HeaderWrap>
                  <ScrollableContent>
                    <View padding="m l 0 m">
                      <DeskTopOnly>
                        <CheckboxContainer>
                          <HiddenCheckbox
                            checked={
                              tab === "listings"
                                ? listings.length === selectedListings.length
                                : tab === "locations"
                                ? locations.length === selectedLocations.length
                                : content.length === selectedContentType.length
                            }
                            onChange={handleSelectAll(tab)}
                          />
                          <StyledCheckbox className="checkbox" />
                          <CheckboxLabelAll>Select All</CheckboxLabelAll>
                        </CheckboxContainer>
                      </DeskTopOnly>
                      {filteredItems.length > 0 ? (
                        filteredItems
                          .sort((a, b) =>
                            a.label.toLowerCase() > b.label.toLowerCase()
                              ? 1
                              : -1
                          )
                          .map((item) => (
                            <NestedCheckbox key={item.id}>
                              <CheckboxContainer>
                                <HiddenCheckbox
                                  checked={isSelected(item.id, tab)}
                                  onChange={handleToggle(item.id, tab)}
                                />
                                <StyledCheckbox className="checkbox, nested" />
                                <Flex align="center">
                                  <CheckboxLabel>{item.label}</CheckboxLabel>
                                  <TextLink
                                    size="s"
                                    className="only"
                                    margin="0 0 0 s"
                                    to="#"
                                    onClick={handleToggleOnly(item.id, tab)}
                                  >
                                    Only
                                  </TextLink>
                                </Flex>
                              </CheckboxContainer>
                            </NestedCheckbox>
                          ))
                      ) : (
                        <View padding="0 m">
                          <Text>No results found.</Text>
                        </View>
                      )}
                    </View>
                  </ScrollableContent>
                </Column>
              </Grid>
            </Card>
          </StyledPopoverPanel>
        </>
      )}
    </StyledPopover>
  );
};

const DeskTopOnly = styled.div`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const NestedCheckbox = styled.div`
  margin-left: ${(p) => p.theme.spacing.l};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 0px;
  }
`;

const CheckboxContainer = styled.label`
  display: block;
  position: relative;
  padding-left: ${(p) => p.theme.spacing.xl};
  margin-bottom: ${(p) => p.theme.spacing.m};
  cursor: pointer;
  user-select: none;

  .only {
    display: none;
  }

  :hover {
    .checkbox {
      border: 2px solid ${(p) => p.theme.color.typography.text};
    }

    .only {
      display: inline;
    }
  }
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked + span {
    background-color: ${(p) => p.theme.color.primary};
    border-color: ${(p) => p.theme.color.primary} !important;
  }

  &:checked + span:after {
    display: block;
  }
`;

const StyledCheckbox = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 2px solid ${(p) => p.theme.color.card.divider};
  border-radius: 4px;

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const CheckboxLabel = styled.span`
  margin-left: ${(p) => p.theme.spacing.s};
  font-weight: ${(p) => p.theme.typography.weight.semi};
  color: ${(p) => p.theme.color.typography.text};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CheckboxLabelAll = styled.span`
  margin-left: ${(p) => p.theme.spacing.s};
  font-weight: ${(p) => p.theme.typography.weight.bold};
  color: ${(p) => p.theme.color.typography.text};
  font-size: ${(p) => p.theme.typography.size.s};
`;

const SearchInput = styled.input`
  width: 100%;
  border: none;
  padding: 0 ${(p) => p.theme.spacing.m} 0;
  font-size: ${(p) => p.theme.typography.size.m};
  font-family: ${(p) => p.theme.typography.bodyFamily};
  color: ${(p) => p.theme.color.typography.secondary};
  background-color: transparent;

  :active,
  :focus {
    outline: none;
    border: none;
  }
`;
