import { AddIcon } from "../components/AddIcon";
import { MOBILE_BREAKPOINT } from "../config";
import styled from "../styles";
import withMargin, { WithMarginProp } from "../styles/withMargin";
import { CTA } from "./CTA";
import { Flex } from "./Flex";
import { Text } from "./Text";

type ButtonProps = {
  label: string;
  to: string;
};

type InlineAddBtnProps = {
  label: string;
  onClick?: () => void;
};

export const NewButton = (props: ButtonProps & WithMarginProp) => {
  return (
    <StyledAddButton to={props.to}>
      <Flex direction="row" align="center">
        <IconWrap>
          <AddIcon />
        </IconWrap>

        <Text margin="0 0 0 xs" color="white" weight="semi">
          {props.label}
        </Text>
      </Flex>
    </StyledAddButton>
  );
};

const StyledAddButton = styled(CTA)`
  line-height: 1em;
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.l};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};

  p {
    font-size: ${(p) => p.theme.typography.size.m};
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.m};
    font-size: ${(p) => p.theme.typography.size.s};
  }
`;

const IconWrap = styled.div`
  margin-top: 1px;
`;

export const InlineAddBtn = (props: InlineAddBtnProps & WithMarginProp) => {
  return (
    <StyledInlineAddBtn
      type="button"
      onClick={props.onClick}
      margin={props.margin}
    >
      <Flex direction="row" align="center">
        <IconWrap>
          <AddIcon colorPreset="link" />
        </IconWrap>

        <Text size="s" margin="0 0 0 xs" colorPreset="link" weight="semi">
          {props.label}
        </Text>
      </Flex>
    </StyledInlineAddBtn>
  );
};

export const InlineBtn = (props: InlineAddBtnProps & WithMarginProp) => {
  return (
    <StyledInlineAddBtn
      type="button"
      onClick={props.onClick}
      margin={props.margin}
    >
      <Flex direction="row" align="center">
        <Text size="s" margin="0 0 0 xs" colorPreset="link" weight="semi">
          {props.label}
        </Text>
      </Flex>
    </StyledInlineAddBtn>
  );
};

const StyledInlineAddBtn = styled.button<WithMarginProp>`
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  ${withMargin}
`;
