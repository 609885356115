import { addDays, fromUnixTime } from "date-fns";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useSelector } from "react-redux";
import {
  Booking,
  BookingEventType,
  BrandBookingsQuery,
  GetBookingQuery,
} from "../graphql/generated";
import { authSelectors } from "../store/auth/selector";
import { demoBookingConfig } from "../utils/demoBookingConfig";

export interface DemoBookingData {
  isDemoMode: boolean;
  isPaidCollab: boolean;
  booking: Booking;
}

export function useDemoBookingData(
  bookingData?:
    | GetBookingQuery["booking"]
    | BrandBookingsQuery["bookings"]["bookings"][0]
): DemoBookingData {
  const demoModeEnabled = useFeatureFlagEnabled("demo-mode");
  const activeBrandId = useSelector(authSelectors.activeBrandId);

  // Check if we're in demo mode for this brand
  const isDemoMode =
    demoModeEnabled &&
    demoBookingConfig.some((brand) => brand.id === activeBrandId);

  if (!isDemoMode || !bookingData) {
    return {
      booking: bookingData as Booking,
      isDemoMode: false,
      isPaidCollab: false,
    };
  }

  // Find demo booking data only once
  const demoBrand = demoBookingConfig.find(
    (brand) => brand.id === activeBrandId
  );
  const demoBooking = demoBrand?.bookings.find(
    (booking) => booking.id === bookingData.id
  );

  if (!demoBooking) {
    return {
      booking: bookingData as Booking,
      isDemoMode: true,
      isPaidCollab: false,
    };
  }

  // Calculate demo-specific dates
  let lastActivityDate = bookingData.lastActivityDate || bookingData.updatedAt;
  let expiresAt = bookingData.expiresAt;

  // If we have events in the demo config, use them for timestamps
  if (demoBooking.events && demoBooking.events.length > 0) {
    // Last activity date from last event
    const lastEvent = demoBooking.events[demoBooking.events.length - 1];
    if (lastEvent?.timestamp) {
      lastActivityDate = lastEvent.timestamp;
    }

    // For New bookings, calculate expiresAt based on Applied event
    const appliedEvent = demoBooking.events.find(
      (e) => e.type === BookingEventType.Applied
    );
    if (appliedEvent?.timestamp) {
      // Set expiry to 7 days after application
      expiresAt = Math.floor(
        addDays(fromUnixTime(appliedEvent.timestamp), 7).getTime() / 1000
      );
    }
  }

  // Apply all demo mode modifications in one place
  return {
    booking: {
      ...bookingData,
      status: demoBooking.status || bookingData.status,
      inProgress: true, // Demo bookings are always in progress
      lastActivityDate,
      expiresAt,
    } as Booking,
    isDemoMode: true,
    isPaidCollab: demoBooking.paid || false,
  };
}
