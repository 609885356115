import styled from "styled-components";
import { useTheme } from "../hooks/useTheme";
import { ThemeProp } from "../styles";

interface NumberCounterProps {
  value: number;
  onIncrease: () => void;
  onDecrease: () => void;
  minValue?: number;
  size?: "l";
}

const CounterWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  box-sizing: border-box;
  background-color: ${(p) => p.theme.color.card.background};

  &:hover {
    border-color: ${(p) => p.theme.color.typography.text};
    outline: none;
  }
`;

const CounterButton = styled.button<{ size?: "l" }>`
  background-color: ${(p: ThemeProp) => p.theme.color.card.callout};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  border: none;
  width: ${(p) => (p.size === "l" ? "42px" : "36px")};
  height: ${(p) => (p.size === "l" ? "42px" : "36px")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(p: ThemeProp) => p.theme.typography.size.xl};
  font-weight: ${(p: ThemeProp) => p.theme.typography.weight.normal};
  font-family: ${(p: ThemeProp) => p.theme.typography.bodyFamily};
  color: ${(p: ThemeProp) => p.theme.color.typography.secondary};
  padding-top: 3px;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    filter: brightness(0.98);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    color: ${(p) => p.theme.color.typography.text};
  }
`;

const ValueDisplay = styled.div`
  width: 40px;
  text-align: center;
  font-size: ${(p: ThemeProp) => p.theme.typography.size.m};
  font-weight: ${(p: ThemeProp) => p.theme.typography.weight.semi};
  font-family: ${(p: ThemeProp) => p.theme.typography.bodyFamily};
  color: ${(p: ThemeProp) => p.theme.color.typography.text};
`;

const NumberCounter: React.FC<NumberCounterProps> = ({
  value,
  onIncrease,
  onDecrease,
  minValue = 0,
  size,
}) => {
  const theme = useTheme();
  return (
    <CounterWrapper>
      <CounterButton
        size={size}
        onClick={onDecrease}
        disabled={value === (minValue || 0)}
        style={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderRight: `1px solid ${theme.color.card.divider}`,
        }}
      >
        −
      </CounterButton>
      <ValueDisplay>{value}</ValueDisplay>
      <CounterButton
        size={size}
        onClick={onIncrease}
        style={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeft: `1px solid ${theme.color.card.divider}`,
        }}
      >
        +
      </CounterButton>
    </CounterWrapper>
  );
};

export default NumberCounter;
