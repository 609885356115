import "@uploadcare/react-uploader/core.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ListingCard } from ".";
import { SingleCheckBox } from "../../../components/Checkbox";
import { CardDivider } from "../../../components/Divider";
import { Dropdown } from "../../../components/Dropdown";
import { Flex } from "../../../components/Flex";
import { H4 } from "../../../components/Heading";
import { MagicWandIcon } from "../../../components/icons/MagicWand";
import { Radio } from "../../../components/RadioButton";
import { Switch } from "../../../components/Switch";
import { Underline } from "../../../components/Tabs";
import { Text } from "../../../components/Text";
import { Tooltip } from "../../../components/Tooltip";
import { View } from "../../../components/View";
import { MOBILE_BREAKPOINT } from "../../../config";
import {
  ApprovalSettingsInput,
  ApprovalType,
} from "../../../graphql/generated";
import { authSelectors } from "../../../store/auth/selector";
import styled, { css } from "../../../styles";
import { lightTheme } from "../../../styles/theme";

interface Props {
  hasTikTokOffers: boolean;
  setApprovalSettings: (newApprovalSettings: ApprovalSettingsInput) => void;
  approvalSettings: ApprovalSettingsInput;
  // autoApprovalLimit: AutoApprovalLimit | null;
  // setAutoApprovalLimit: React.Dispatch<
  //   React.SetStateAction<AutoApprovalLimit | null>
  // >;
  internalBookingNotes: string;
  setInternalBookingNotes: React.Dispatch<React.SetStateAction<string>>;
  collinsBookingAutoCreate: boolean;
  setCollinsBookingAutoCreate: React.Dispatch<React.SetStateAction<boolean>>;
  approvalSettingsError?: string;
  collinsTypeId: string;
}

const engagementOptions = [
  { label: "1%", value: 1 },
  { label: "3% (good)", value: 3 },
  { label: "5% (high)", value: 5 },
];

const audienceOptions = [
  { label: "10%", value: 10 },
  { label: "20%", value: 20 },
  {
    label: "25%",
    value: 25,
  },
  {
    label: "30%",
    value: 30,
  },
];

const followerOptions = [
  { label: "2,000", value: 2000 },
  { label: "5,000", value: 5000 },
  { label: "8,000", value: 8000 },
  { label: "10,000", value: 10000 },
  { label: "20,000", value: 20000 },
  { label: "30,000", value: 30000 },
  { label: "40,000", value: 40000 },
  { label: "50,000", value: 50000 },
];

const minViewsPerPostOptions = [
  { label: "2,000", value: 2000 },
  { label: "5,000", value: 5000 },
  { label: "8,000", value: 8000 },
  { label: "10,000", value: 10000 },
  { label: "20,000", value: 20000 },
  { label: "30,000", value: 30000 },
  { label: "40,000", value: 40000 },
  { label: "50,000", value: 50000 },
];

const ApprovalSettings = (props: Props) => {
  const brand = useSelector(authSelectors.activeBrand);

  const isCollinsEnabled = brand?.dmnEnabled;
  return (
    <ListingCard>
      <H4 margin="0 0 m">Approval Settings</H4>
      <Underline selected={true} />
      <View margin="l 0 m">
        <Text weight="bold" margin="0">
          Approval Type
        </Text>
        <Text colorPreset="secondary" size="s" margin="0 0 l" isCompact>
          Choose how you'd like to approve influencer applications
        </Text>
      </View>
      <ApprovalTypeGrid>
        <ApprovalTypeSelect
          approvalType={ApprovalType.ApprovalTypeManual}
          selectedApprovalType={props.approvalSettings.type}
          onClick={() => {
            props.setApprovalSettings({
              type: ApprovalType.ApprovalTypeManual,
            });
          }}
        />

        <ApprovalTypeSelect
          approvalType={ApprovalType.ApprovalTypeAutoWithCriteria}
          selectedApprovalType={props.approvalSettings.type}
          onClick={() => {
            props.setApprovalSettings({
              type: ApprovalType.ApprovalTypeAutoWithCriteria,
            });
          }}
        />
        <ApprovalTypeSelect
          approvalType={ApprovalType.ApprovalTypeAuto}
          selectedApprovalType={props.approvalSettings.type}
          onClick={() => {
            props.setApprovalSettings({
              type: ApprovalType.ApprovalTypeAuto,
            });
          }}
        />
      </ApprovalTypeGrid>

      {props.approvalSettings.type ===
        ApprovalType.ApprovalTypeAutoWithCriteria || isCollinsEnabled ? (
        <View margin="xl 0 0">
          {props.approvalSettings.type ===
          ApprovalType.ApprovalTypeAutoWithCriteria ? (
            <SmartApprovalSettings
              approvalSettings={props.approvalSettings}
              setApprovalSettings={props.setApprovalSettings}
              hasTikTokOffers={props.hasTikTokOffers}
              approvalSettingsError={props.approvalSettingsError}
            />
          ) : null}

          {/* {props.approvalSettings.type ===
        ApprovalType.ApprovalTypeManual ? null : (
          <LimitAutoApprovals
            autoApprovalLimit={props.autoApprovalLimit}
            setAutoApprovalLimit={props.setAutoApprovalLimit}
          />
        )} */}

          {isCollinsEnabled && !!props.collinsTypeId && (
            <>
              {props.approvalSettings.type ===
              ApprovalType.ApprovalTypeAutoWithCriteria ? (
                <CardDivider margin="xl 0" />
              ) : null}
              <View margin="l 0 0">
                <CreateBookingsOnCollins
                  collinsBookingAutoCreate={props.collinsBookingAutoCreate}
                  setCollinsBookingAutoCreate={
                    props.setCollinsBookingAutoCreate
                  }
                />
              </View>
            </>
          )}
        </View>
      ) : null}
    </ListingCard>
  );
};

export default ApprovalSettings;

const SmartApprovalSettings = (props: {
  approvalSettings: ApprovalSettingsInput;
  setApprovalSettings: (newApprovalSettings: ApprovalSettingsInput) => void;
  hasTikTokOffers: boolean;
  approvalSettingsError?: string;
}) => {
  // TODO These states aren't initializing correctly.
  const [enabledFollowers, setEnabledFollowers] = useState(
    !!props.approvalSettings.minFollowers
  );
  const [enabledEngagement, setEnabledEngagement] = useState(
    !!props.approvalSettings.minEngagementRate
  );
  const [enabledAudience, setEnabledAudience] = useState(
    !!props.approvalSettings.minUkAudience
  );

  const [minAvViews, setMinAvViews] = useState(
    !!props.approvalSettings.minAvViews
  );

  const [followersCriteria, setFollowersCriteria] = useState(
    props.approvalSettings.minFollowers || followerOptions[3].value
  );
  const [engagementCriteria, setEngagementCriteria] = useState(
    props.approvalSettings.minEngagementRate
      ? props.approvalSettings.minEngagementRate
      : engagementOptions[1].value
  );
  const [audienceCriteria, setAudienceCriteria] = useState(
    props.approvalSettings.minUkAudience
      ? props.approvalSettings.minUkAudience
      : audienceOptions[1].value
  );

  const [viewsPerPostCriteria, setViewsPerPostCriteria] = useState(
    props.approvalSettings.minAvViews
      ? props.approvalSettings.minAvViews
      : minViewsPerPostOptions[3].value
  );

  useEffect(() => {
    const newSettings = {
      ...props.approvalSettings,
      minFollowers: enabledFollowers ? Number(followersCriteria) : undefined,
      minEngagementRate: enabledEngagement ? engagementCriteria : undefined,
      minUkAudience: enabledAudience ? audienceCriteria : undefined,
      minAvViews: minAvViews ? viewsPerPostCriteria : undefined,
    };

    if (
      JSON.stringify(newSettings) !== JSON.stringify(props.approvalSettings)
    ) {
      props.setApprovalSettings(newSettings);
    }
  }, [
    enabledFollowers,
    enabledEngagement,
    enabledAudience,
    minAvViews,
    followersCriteria,
    engagementCriteria,
    audienceCriteria,
    viewsPerPostCriteria,
    props.approvalSettings,
    props.setApprovalSettings,
  ]);

  return (
    <View>
      <Flex
        direction="row"
        align="center"
        margin="l 0"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setEnabledFollowers(!enabledFollowers);
        }}
      >
        <SingleCheckBox checked={enabledFollowers} />
        <View margin="0 0 0 l" style={{ flex: 1 }}>
          <Flex align="center" direction="row">
            <Text weight="semi" margin="0" style={{ userSelect: "none" }}>
              More than
            </Text>
            <View margin="0 l 0">
              <Dropdown
                width={120}
                size="s"
                selectionMode="single"
                renderLabel={() => {
                  const selectedOption = followerOptions.find(
                    (option) => option.value === Number(followersCriteria)
                  );
                  return selectedOption
                    ? selectedOption?.value.toLocaleString()
                    : followersCriteria.toLocaleString();
                }}
                selectedOptions={[followersCriteria.toString()]}
                setSelectedOptions={(value) => {
                  if (value.length > 0) {
                    setFollowersCriteria(Number(value[0]));
                  }
                }}
                disableOptionSort
                options={followerOptions
                  .sort((a, b) => a.value - b.value)
                  .map((option) => ({
                    label: option.label,
                    value: option.value.toString(),
                  }))}
              />
            </View>
            <Text weight="semi" margin="0" style={{ userSelect: "none" }}>
              followers
            </Text>
          </Flex>
        </View>
      </Flex>
      <Flex
        direction="row"
        align="center"
        margin="l 0"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setMinAvViews(!minAvViews);
        }}
      >
        <SingleCheckBox checked={minAvViews} />
        <View margin="0 0 0 l" style={{ flex: 1 }}>
          <Flex align="center" direction="row">
            <Text weight="semi" margin="0" style={{ userSelect: "none" }}>
              At least
            </Text>
            <View margin="0 l 0">
              <Dropdown
                width={120}
                size="s"
                selectionMode="single"
                renderLabel={() => {
                  const selectedOption = minViewsPerPostOptions.find(
                    (option) => option.value === Number(viewsPerPostCriteria)
                  );
                  return selectedOption
                    ? selectedOption?.value.toLocaleString()
                    : viewsPerPostCriteria.toLocaleString();
                }}
                selectedOptions={[viewsPerPostCriteria.toString()]}
                setSelectedOptions={(value) => {
                  if (value.length > 0) {
                    setViewsPerPostCriteria(Number(value[0]));
                  }
                }}
                disableOptionSort
                options={minViewsPerPostOptions
                  .sort((a, b) => a.value - b.value)
                  .map((option) => ({
                    label: option.label,
                    value: option.value.toString(),
                  }))}
              />
            </View>
            <Flex>
              <Text
                weight="semi"
                margin="0 s 0 0"
                style={{ userSelect: "none" }}
              >
                views per post
              </Text>
              <Tooltip>
                <Text size="s" margin="0">
                  Average views per post calculated over the last 28 days
                </Text>
              </Tooltip>
            </Flex>
          </Flex>
        </View>
      </Flex>
      <Flex
        direction="row"
        align="center"
        margin="0 0 0 0"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setEnabledEngagement(!enabledEngagement);
        }}
      >
        <SingleCheckBox checked={enabledEngagement} />
        <View margin="0 0 0 l" style={{ flex: 1 }}>
          <Flex align="center" direction="row">
            <Text weight="semi" margin="0" style={{ userSelect: "none" }}>
              Engagement rate over
            </Text>
            <View margin="0 l 0">
              <Dropdown
                width={120}
                size="s"
                selectionMode="single"
                renderLabel={() => {
                  const selectedOption = engagementOptions.find(
                    (option) => option.value === Number(engagementCriteria)
                  );
                  return selectedOption?.label || engagementCriteria.toString();
                }}
                selectedOptions={[engagementCriteria.toString()]}
                setSelectedOptions={(value) => {
                  if (value.length > 0) {
                    setEngagementCriteria(Number(value[0]));
                  }
                }}
                disableOptionSort
                options={engagementOptions
                  .sort((a, b) => a.value - b.value)
                  .map((option) => ({
                    label: option.label,
                    value: option.value.toString(),
                  }))}
              />
            </View>
          </Flex>
        </View>
      </Flex>
      <Flex
        direction="row"
        align="center"
        margin="l 0"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setEnabledAudience(!enabledAudience);
        }}
      >
        <SingleCheckBox checked={enabledAudience} />
        <View margin="0 0 0 l" style={{ flex: 1 }}>
          <Flex align="center" direction="row">
            <Text weight="semi" margin="0" style={{ userSelect: "none" }}>
              Over
            </Text>
            <View margin="0 l 0">
              <Dropdown
                width={120}
                size="s"
                selectionMode="single"
                renderLabel={() => {
                  const selectedOption = audienceOptions.find(
                    (option) => option.value === Number(audienceCriteria)
                  );
                  return selectedOption?.label || audienceCriteria.toString();
                }}
                selectedOptions={[audienceCriteria.toString()]}
                setSelectedOptions={(value) => {
                  if (value.length > 0) {
                    setAudienceCriteria(Number(value[0]));
                  }
                }}
                disableOptionSort
                options={audienceOptions
                  .sort((a, b) => a.value - b.value)
                  .map((option) => ({
                    label: option.label,
                    value: option.value.toString(),
                  }))}
              />
            </View>
            <Text weight="semi" margin="0 s 0 0" style={{ userSelect: "none" }}>
              of their audience are UK based
            </Text>
            {props.hasTikTokOffers ? (
              <Tooltip>
                <Text size="s" margin="0">
                  Only applicable for Instagram,{" "}
                  <span
                    style={{ fontWeight: lightTheme.typography.weight.bold }}
                  >
                    TikTok influencers will not meet this criteria
                  </span>
                </Text>
                <Text size="xs" margin="0" colorPreset="secondary">
                  TikTok does not currently provide demographic data
                </Text>
              </Tooltip>
            ) : null}
          </Flex>
        </View>
      </Flex>
      {props.approvalSettingsError && (
        <Text size="xs" colorPreset="error" margin="s 0 0">
          {props.approvalSettingsError}
        </Text>
      )}
    </View>
  );
};

const ApprovalTypeSelect = (props: {
  approvalType: ApprovalType;
  selectedApprovalType: ApprovalType;
  onClick: () => void;
}) => {
  let title = "Approve All";
  let subtitle = "All influencers will be automatically approved";
  switch (props.approvalType) {
    case ApprovalType.ApprovalTypeAuto:
      title = "Approve All";
      subtitle = "All influencers will be automatically approved";
      break;
    case ApprovalType.ApprovalTypeAutoWithCriteria:
      title = "Smart Approval";
      subtitle = "Set auto-approval criteria, manually approve the rest";
      break;
    case ApprovalType.ApprovalTypeManual:
      title = "Manual Approval";
      subtitle = "Every influencer must be approved individually";
      break;
    default:
      title = "Unknown";
  }

  const showGetMoreApplications =
    props.approvalType === ApprovalType.ApprovalTypeAutoWithCriteria;

  return (
    <div style={{ position: "relative" }}>
      <AbsoluteWrap
        style={{
          opacity: showGetMoreApplications ? 1 : 0,
        }}
      >
        <MostPopular>Get 3.2x more applications</MostPopular>
      </AbsoluteWrap>
      <ApprovalTypeWrap
        onClick={props.onClick}
        selected={props.approvalType === props.selectedApprovalType}
      >
        <Flex align="center" direction="row" justify="space-between">
          <Flex align="center" direction="row" margin="0 s 0 0">
            <Flex align="center" justify="center">
              <Radio
                width={10}
                height={10}
                active={props.approvalType === props.selectedApprovalType}
              />
            </Flex>
            <View margin="0 0 0 m">
              <Text margin="0" weight="bold">
                {title}
              </Text>
              <Text margin="0" size="xs" colorPreset="secondary">
                {subtitle}
              </Text>
            </View>
          </Flex>
        </Flex>
      </ApprovalTypeWrap>
    </div>
  );
};

const CreateBookingsOnCollins = (props: {
  collinsBookingAutoCreate: boolean;
  setCollinsBookingAutoCreate: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <View>
      <Flex
        direction="row"
        align="center"
        margin="0"
        style={{ userSelect: "none", cursor: "pointer" }}
        onClick={() => {
          props.setCollinsBookingAutoCreate(!props.collinsBookingAutoCreate);
        }}
      >
        <View style={{ flex: 1 }}>
          <Flex direction="row" align="center">
            <Flex
              align="center"
              justify="center"
              margin="0 s 0"
              style={{ marginTop: -8 }}
            >
              <MagicWandIcon colorPreset="text" height={29} />
            </Flex>
            <View margin="0 0 0 m">
              <Text weight="semi" margin="0 0 0 0" isCompact>
                Automate bookings
              </Text>
              <Text size="s" colorPreset="secondary" margin="0 0 0 0">
                Create bookings automatically on Access Collins once approved on
                Joli
              </Text>
            </View>
          </Flex>
        </View>
        <Switch
          value={props.collinsBookingAutoCreate}
          onChange={() => {
            console.log("switched");
          }}
        />
      </Flex>
    </View>
  );
};

const ApprovalTypeWrap = styled.div<{ selected: boolean }>`
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  padding: ${(p) => p.theme.spacing.l};
  cursor: pointer;
  ${(p) =>
    p.selected
      ? css`
          border-color: ${p.theme.color.primary};
          box-shadow: inset 0 0 0 1px ${p.theme.color.primary};
        `
      : null}
`;

const ApprovalTypeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${(p) => p.theme.spacing.m};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(1, 1fr);
    gap: ${(p) => p.theme.spacing.l};
  }
`;

const AbsoluteWrap = styled.div`
  position: absolute;
  top: -9px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
`;

const MostPopular = styled.span`
  background-color: ${(p) => p.theme.color.primary};
  padding: 2px ${(p) => p.theme.spacing.m};
  color: #fff;
  font-size: ${(p) => p.theme.typography.size.xxxs};
  font-weight: ${(p) => p.theme.typography.weight.semi};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
`;
