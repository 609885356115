import styled from "styled-components";

interface Props {
  width?: number;
  active?: boolean | "hover";
}

export const InProgressBriefIconSvg = (props: Props) => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_10521_11377)">
        <path
          d="M44.0625 22.5C44.0625 10.5914 34.4086 0.9375 22.5 0.9375C10.5914 0.9375 0.9375 10.5914 0.9375 22.5C0.9375 34.4086 10.5914 44.0625 22.5 44.0625C34.4086 44.0625 44.0625 34.4086 44.0625 22.5Z"
          stroke="url(#paint0_linear_10521_11377)"
          stroke-width="2"
        />
        <path
          d="M16.0908 29.8789C15.0186 29.8789 14.1777 29.583 13.5684 28.9912C12.9648 28.3936 12.6631 27.5586 12.6631 26.4863V18.8135C12.6631 17.7471 12.9766 16.918 13.6035 16.3262C14.2363 15.7285 15.0654 15.4297 16.0908 15.4297H25.2754C26.3477 15.4297 27.1855 15.7285 27.7891 16.3262C28.3984 16.918 28.7031 17.7471 28.7031 18.8135V26.4863C28.7031 27.5586 28.3984 28.3936 27.7891 28.9912C27.1855 29.583 26.3477 29.8789 25.2754 29.8789H16.0908ZM16.3369 28.5518H25.0293C25.75 28.5518 26.3066 28.3643 26.6992 27.9893C27.0918 27.6143 27.2881 27.0459 27.2881 26.2842V19.0244C27.2881 18.2627 27.0918 17.6943 26.6992 17.3193C26.3066 16.9385 25.75 16.748 25.0293 16.748H16.3369C15.6162 16.748 15.0596 16.9385 14.667 17.3193C14.2744 17.6943 14.0781 18.2627 14.0781 19.0244V26.2842C14.0781 27.0459 14.2744 27.6143 14.667 27.9893C15.0596 28.3643 15.6162 28.5518 16.3369 28.5518ZM28.501 20.1934L32.0254 17.2139C32.2012 17.0674 32.3828 16.9502 32.5703 16.8623C32.7578 16.7686 32.9424 16.7217 33.124 16.7217C33.5107 16.7217 33.8213 16.8506 34.0557 17.1084C34.29 17.3662 34.4072 17.7002 34.4072 18.1104V27.1982C34.4072 27.6084 34.29 27.9424 34.0557 28.2002C33.8213 28.458 33.5107 28.5869 33.124 28.5869C32.9424 28.5869 32.7578 28.543 32.5703 28.4551C32.3828 28.3613 32.2012 28.2412 32.0254 28.0947L28.501 25.1064V23.4453L32.6846 26.8994C32.7256 26.9229 32.7607 26.9463 32.79 26.9697C32.8252 26.9873 32.8604 26.9961 32.8955 26.9961C32.9951 26.9961 33.0449 26.9287 33.0449 26.7939V18.5146C33.0449 18.374 32.9951 18.3037 32.8955 18.3037C32.8604 18.3037 32.8252 18.3154 32.79 18.3389C32.7607 18.3564 32.7256 18.3799 32.6846 18.4092L28.501 21.8633V20.1934Z"
          fill="#3D3C5A"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_10521_11377"
          x1="4.37308e-08"
          y1="22.5005"
          x2="44.9992"
          y2="22.5005"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.13" stop-color="#FA3CAB" />
          <stop offset="0.24" stop-color="#F94C94" />
          <stop offset="0.57" stop-color="#F87C54" />
          <stop offset="0.81" stop-color="#F79A2C" />
          <stop offset="0.94" stop-color="#F7A61D" />
        </linearGradient>
        <clipPath id="clip0_10521_11377">
          <rect width="45" height="45" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const InProgressBriefIcon = styled(InProgressBriefIconSvg)`
  & path:first-of-type {
    stroke: ${(p) => p.theme.color.typography.secondary}80;
  }

  & path:nth-of-type(2) {
    fill: ${(p) => p.theme.color.typography.secondary};
  }

  .active & path:first-of-type {
    stroke: url(#paint0_linear_10521_11358);
  }

  .active & path:nth-of-type(2) {
    fill: ${(p) => p.theme.color.typography.text};
  }
`;
