import React, { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "sonner";
import { BackLink } from "../../../components/BackLink";
import { SignupHeader } from "../../../components/Brand/SignupHeader";
import { CTA } from "../../../components/CTA";
import { Chevron } from "../../../components/Chevron";
import DetailList from "../../../components/DetailList";
import { Flex } from "../../../components/Flex";
import { H1 } from "../../../components/Heading";
import { Input } from "../../../components/Input";
import Loading from "../../../components/Loading";
import { InlineAddBtn, InlineBtn } from "../../../components/NewButton";
import { ProgressBar } from "../../../components/ProgressBar";
import { Steps } from "../../../components/Steps";
import { Subtitle } from "../../../components/Subtitle";
import { Text } from "../../../components/Text";
import { TextArea } from "../../../components/TextArea";
import { MOBILE_BREAKPOINT } from "../../../config";
import {
  ExcludedHoursInput,
  OpeningHoursInput,
  PlaceSearchResult,
  useCreateLocationMutation,
  usePlaceQuery,
  useSearchPlacesQuery,
} from "../../../graphql/generated";
import useAnalytics from "../../../hooks/useAnalytics";
import useGqlClient from "../../../hooks/useGqlClient";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { authSelectors } from "../../../store/auth/selector";
import styled from "../../../styles";
import { Hours } from "../CreateLocation";
import { ExcludedHours } from "../Location";
import { SignupCard } from "./CreateBrand";

const PageWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  min-height: 90vh;
  margin-bottom: 30px;
`;

const ContentWrap = styled.div`
  flex: 1;
  align-self: center;
  justify-content: center;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
`;

const Content = styled.div`
  min-height: 600px;
`;

export function CreateLocation() {
  const window = useWindowSize();
  const client = useGqlClient();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<PlaceSearchResult[]>([]);
  const [selectedPlaceId, setSelectedPlaceId] = useState("");
  const searchPlacesTimeout = useRef<NodeJS.Timeout | null>(null); // Use useRef for timeout
  const queryClient = useQueryClient();

  const { isLoading, error, refetch } = useSearchPlacesQuery(
    client,
    {
      input: searchTerm,
    },
    {
      enabled: false,
    }
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value;
    setSelectedPlaceId(""); // Clear the selected place when input changes

    // Avoid triggering state updates and API calls unnecessarily
    if (inputValue === searchTerm) {
      return;
    }

    setSearchTerm(inputValue); // Update search term immediately

    // Clear previous timeout if any
    if (searchPlacesTimeout.current) {
      clearTimeout(searchPlacesTimeout.current);
    }

    // If the input length is less than or equal to 2, clear results and stop further actions
    if (inputValue.length <= 2) {
      setSearchResults([]);
      return;
    }

    // Debounce the search operation
    searchPlacesTimeout.current = setTimeout(async () => {
      if (!inputValue) {
        return;
      }

      queryClient.setQueryData(["searchPlaces"], { input: inputValue });
      const res = await refetch();
      if (res.data) {
        setSearchResults(res.data.searchPlaces.places);
      }
    }, 1000); // 1 second debounce
  };

  return (
    <PageWrap>
      {window.width && window.width < MOBILE_BREAKPOINT ? (
        <Flex margin="xs 0 xxl 0" justify="space-between" align="center">
          <BackLink margin="0 0 0" to="/signup/b/describe-brand">
            <Chevron direction="left" /> Back
          </BackLink>
          <ProgressBar steps={{ currentStep: 3, totalSteps: 4 }} />
        </Flex>
      ) : (
        <SignupHeader />
      )}
      <ContentWrap>
        <Content>
          <SignupCard>
            <H1 margin="0 0 s 0">Add your first location</H1>
            {selectedPlaceId ? (
              <PlaceForm placeId={selectedPlaceId} />
            ) : (
              <>
                <Subtitle margin="0 0 l 0">
                  A location can be any physical place for influencer visits,
                  such as hotels, restaurants, cafes, entertainment venues, and
                  more
                </Subtitle>
                <Input
                  margin="0 0 0 0"
                  label="Search for address"
                  placeholder="e.g. Honest Burgers Borough"
                  value={searchTerm}
                  onChange={handleInputChange}
                />
                <SearchResults
                  isLoading={isLoading}
                  results={searchResults}
                  error={error}
                  onPlaceSelected={(place) => {
                    setSearchResults([]);
                    setSelectedPlaceId(place.placeId);
                  }}
                />
              </>
            )}
          </SignupCard>
        </Content>
      </ContentWrap>
      {window.width && window.width > MOBILE_BREAKPOINT && (
        <Steps
          margin="xxl 0 0 0"
          steps={[
            {
              link: "/signup/b/confirm-details",
              status: "complete",
              name: "Details",
            },
            {
              link: "/signup/b/describe-brand",
              status: "complete",
              name: "Describe brand",
            },
            {
              link: "/signup/b/create-location",
              status: "current",
              name: "Locations",
            },
          ]}
        />
      )}
    </PageWrap>
  );
}

const SearchResultsWrap = styled.div`
  border-radius: ${(p) => p.theme.misc.borderRadius};
  background-color: ${(p) => p.theme.color.card.background};
  margin-top: ${(p) => p.theme.spacing.s};
`;

const SearchResultItem = styled.div`
  padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.s};
  border-bottom: 1px solid ${(p) => p.theme.color.card.divider};
  &:hover {
    background-color: ${(p) => p.theme.color.card.divider};
    cursor: pointer;
  }
  &:last-of-type {
    padding-bottom: ${(p) => p.theme.spacing.s};
    border-radius: 0 0 ${(p) => p.theme.misc.borderRadius}
      ${(p) => p.theme.misc.borderRadius};
    border-bottom: none;
  }
  &:first-of-type {
    border-radius: ${(p) => p.theme.misc.borderRadius}
      ${(p) => p.theme.misc.borderRadius} 0 0;
    padding-top: ${(p) => p.theme.spacing.s};
  }
`;

interface SearchResultsProps {
  isLoading: boolean;
  error: unknown | null;
  results: PlaceSearchResult[];
  onPlaceSelected: (place: PlaceSearchResult) => void;
}

function SearchResults(props: SearchResultsProps) {
  if (props.isLoading) {
    return <Loading />;
  }

  if (props.error) {
    return <Text>Something went wrong</Text>;
  }

  return (
    <div>
      {props.results && (
        <SearchResultsWrap>
          {props.results.map((place) => {
            return (
              <SearchResultItem
                onClick={() => {
                  props.onPlaceSelected(place);
                }}
                key={place.placeId}
              >
                <Text margin="0">{place.description}</Text>
              </SearchResultItem>
            );
          })}
        </SearchResultsWrap>
      )}
    </div>
  );
}

interface PlaceFormProps {
  placeId: string;
}

function PlaceForm(props: PlaceFormProps) {
  const { track } = useAnalytics();
  const client = useGqlClient();
  const { data, isLoading, error } = usePlaceQuery(client, {
    id: props.placeId,
  });

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [excludedHours, setExcludedHours] = useState<ExcludedHoursInput[]>([]);
  const [editingOpeningHours, setEditingOpeningHours] = useState(false);
  const [openingHours, setOpeningHours] = useState<OpeningHoursInput[]>([
    {
      dayOfWeek: "Monday",
      startHour: 10,
      startMinute: 0,
      endHour: 18,
      endMinute: 0,
    },
    {
      dayOfWeek: "Tuesday",
      startHour: 10,
      startMinute: 0,
      endHour: 18,
      endMinute: 0,
    },
    {
      dayOfWeek: "Wednesday",
      startHour: 10,
      startMinute: 0,
      endHour: 18,
      endMinute: 0,
    },
    {
      dayOfWeek: "Thursday",
      startHour: 10,
      startMinute: 0,
      endHour: 18,
      endMinute: 0,
    },
    {
      dayOfWeek: "Friday",
      startHour: 10,
      startMinute: 0,
      endHour: 18,
      endMinute: 0,
    },
    {
      dayOfWeek: "Saturday",
      startHour: 10,
      startMinute: 0,
      endHour: 18,
      endMinute: 0,
    },
    {
      dayOfWeek: "Sunday",
      startHour: 10,
      startMinute: 0,
      endHour: 18,
      endMinute: 0,
    },
  ]);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  const createLocation = useCreateLocationMutation(client);
  const brand = useSelector(authSelectors.activeBrand);
  const account = useSelector(authSelectors.account);
  const domain = account
    ? account.email.substring(account?.email.lastIndexOf("@") + 1)
    : "example.com";

  const history = useHistory();

  useEffect(() => {
    if (!data) {
      return;
    }

    setAddress(data.place.address);

    if (data.place.openingHours && data.place.openingHours.length > 0) {
      const ohs: OpeningHoursInput[] = data.place.openingHours.map((oh) => {
        const dow = oh.dayOfWeek;
        const startHour = parseInt(oh.openTime.substring(0, 2), 10);
        const startMinute = parseInt(oh.openTime.substring(3, 5), 10);
        const endHour = parseInt(oh.closeTime.substring(0, 2), 10);
        const endMinute = parseInt(oh.closeTime.substring(3, 5), 10);

        return {
          dayOfWeek: dow,
          startHour,
          startMinute,
          endHour,
          endMinute,
        };
      });

      setOpeningHours(ohs);
    }
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  if (error || !data || !brand) {
    return <Text>Something went wrong</Text>;
  }

  const defaultExcludedHours: ExcludedHoursInput = {
    startHour: openingHours[0].startHour,
    startMinute: 0,
    endHour: openingHours[0].endHour,
    endMinute: 0,
    dayOfWeek: openingHours[0].dayOfWeek,
  };

  return (
    <>
      <Input
        margin="0 0 l 0"
        label="Name"
        value={name}
        placeholder="eg. Borough"
        autoFocus
        error={nameError}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setNameError("");
          setName(e.currentTarget.value);
        }}
      />
      <TextArea
        margin="0 0 l 0"
        label="Address"
        error={addressError}
        defaultValue={data.place.address}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setAddressError("");
          setAddress(e.currentTarget.value);
        }}
        rows={3}
      />
      <Input
        margin="0 0 xl 0"
        label="Email"
        error={emailError}
        value={email}
        type="email"
        placeholder={`e.g. borough@${domain}`}
        help="Add the location's email to let them know about confirmed bookings"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setEmailError("");
          setEmail(e.currentTarget.value);
        }}
      />

      <Flex margin="l 0 0 0" justify="space-between" align="center">
        <div>
          <Text margin="0" weight="semi">
            Opening Hours
          </Text>
        </div>
        <InlineBtn
          label={editingOpeningHours ? "Done" : "Edit"}
          onClick={() => {
            setEditingOpeningHours(!editingOpeningHours);
          }}
        />
      </Flex>
      {editingOpeningHours ? (
        <>
          {openingHours
            .sort((a, b) => {
              const daysOfWeek = [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ];
              return (
                daysOfWeek.indexOf(a.dayOfWeek) -
                daysOfWeek.indexOf(b.dayOfWeek)
              );
            })
            .map((ehs, i) => {
              return (
                <Hours
                  place={data.place}
                  index={i}
                  key={`${ehs.dayOfWeek}-${ehs.startHour}-${ehs.startMinute}-${ehs.endHour}-${ehs.endMinute}`}
                  defaultValue={ehs}
                  onChange={(index, ehs) => {
                    const newOpeningHours = [...openingHours];
                    newOpeningHours.splice(index, 1);
                    newOpeningHours.splice(index, 0, ehs);

                    setOpeningHours(newOpeningHours);
                  }}
                  onRemove={(index) => {
                    const newOpeningHours = [...openingHours];
                    newOpeningHours.splice(index, 1);

                    setOpeningHours(newOpeningHours);
                  }}
                />
              );
            })}
          <InlineAddBtn
            label="Add Hours"
            margin="m 0 xl 0"
            onClick={() => {
              const newOpeningHours = [...openingHours];
              newOpeningHours.push({
                dayOfWeek: "Monday",
                startHour: 10,
                startMinute: 0,
                endHour: 18,
                endMinute: 0,
              });
              setOpeningHours(newOpeningHours);
            }}
          />
        </>
      ) : (
        <DetailList
          margin="s 0 xl 0"
          fields={openingHours.map((ohs, i) => ({
            label: ohs.dayOfWeek,
            value: `${ohs.startHour < 10 ? "0" : ""}${ohs.startHour}:${
              ohs.startMinute
            }${ohs.startMinute < 10 ? "0" : ""} - ${
              ohs.endHour < 10 ? "0" : ""
            }${ohs.endHour}:${ohs.endMinute}${ohs.endMinute < 10 ? "0" : ""}`,
          }))}
        />
      )}

      {showAdvancedOptions ? (
        <OptionsToggleWrap onClick={() => setShowAdvancedOptions(false)}>
          <Chevron colorPreset="link" direction="down" />
          <Text weight="semi" colorPreset="link" margin="0 0 0 s">
            Hide Advanced Options
          </Text>
        </OptionsToggleWrap>
      ) : (
        <OptionsToggleWrap onClick={() => setShowAdvancedOptions(true)}>
          <Chevron colorPreset="link" />
          <Text weight="semi" colorPreset="link" margin="0 0 0 s">
            Show Advanced Options
          </Text>
        </OptionsToggleWrap>
      )}

      {showAdvancedOptions && (
        <>
          <Text margin="xl 0 0 0" weight="semi">
            Excluded Hours
          </Text>
          <Text size="s" colorPreset="secondary" margin="xs 0 0 0">
            Block off certain hours of the week to prevent influencers applying
          </Text>

          {excludedHours.map((ehs, i) => {
            return (
              <ExcludedHours
                openingHours={openingHours}
                index={i}
                key={`${ehs.dayOfWeek}-${ehs.startHour}-${ehs.startMinute}-${ehs.endHour}-${ehs.endMinute}`}
                defaultValue={ehs}
                onChange={(index, ehs) => {
                  const newExcludedHours = [...excludedHours];
                  newExcludedHours.splice(index, 1);
                  newExcludedHours.splice(index, 0, ehs);

                  setExcludedHours(newExcludedHours);
                }}
                onRemove={(index) => {
                  const newExcludedHours = [...excludedHours];
                  newExcludedHours.splice(index, 1);

                  setExcludedHours(newExcludedHours);
                }}
              />
            );
          })}
          <InlineAddBtn
            label="Add Hours"
            margin="m 0 0"
            onClick={() => {
              const newExcludedHours = [...excludedHours];
              newExcludedHours.push(defaultExcludedHours);
              setExcludedHours(newExcludedHours);
            }}
          />
        </>
      )}

      <CTA
        to="#"
        margin="xl 0 0 0"
        onClick={() => {
          if (!name) {
            setNameError("Name cannot be blank");
            return;
          }

          createLocation.mutate(
            {
              brandId: brand.id,
              placesId: props.placeId,
              name,
              lat: data.place.lat,
              lng: data.place.lng,
              email,
              phone: data.place.phone,
              address,
              excludedHours,
              openingHours,
            },
            {
              onSuccess: (res) => {
                toast.success("Location created");
                track("Location created", {
                  id: res.createLocation.location?.id
                    ? res.createLocation.location.id
                    : undefined,
                  brand: brand ? brand.name : undefined,
                });
                history.push("/b/listings?r=onboarding");
              },
            }
          );
        }}
      >
        {createLocation.isLoading ? "Loading..." : "Save"}
      </CTA>
    </>
  );
}

const OptionsToggleWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
