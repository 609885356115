import { useState } from "react";
import { MOBILE_BREAKPOINT } from "../../config";
import { GetBookingQuery } from "../../graphql/generated";
import styled from "../../styles";
import { Chevron } from "../Chevron";
import { Flex } from "../Flex";
import { CopyIcon } from "../icons/CopyIcon";
import { MailIcon } from "../icons/MailIcon";
import { NameIcon } from "../icons/NameIcon";
import { PhoneIcon } from "../icons/PhoneIcon";
import { Text } from "../Text";
import { ExternalTextLink } from "../TextLink";
import { View } from "../View";

const BioWrap = styled(View)`
  width: 100%;
  max-width: 500px;
`;

export const StackOnMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: flex;
    flex-direction: column;
  }
`;

const CopiedTextWrap = styled.div<{ isCopied: boolean }>`
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  justify-content: center;

  span {
    font-size: ${(p) => p.theme.typography.size.xs};
    font-weight: ${(p) => p.theme.typography.weight.semi};
    color: ${(p) => p.theme.color.primary};
  }

  margin: 0;

  transition: ${({ isCopied }) =>
    isCopied ? "opacity 0.2s ease-out 0.1s" : "opacity 0.2s ease-out"};
  opacity: ${({ isCopied }) => (isCopied ? 1 : 0)};
`;

const CopyWrap = styled(Flex)`
  position: relative;
  cursor: pointer;
  :hover {
    svg {
      path {
        stroke: ${({ theme }) => theme.color.primary};
      }
    }
  }
`;

const ContactDetailWrap = styled(Flex)`
  padding: ${(p) => p.theme.spacing.s} 0;
  .copyHover {
    opacity: 0;
  }

  :hover {
    .copyHover {
      opacity: 1;
    }
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0;
  }
`;

const ContactDetailsWrap = styled(Flex)<{ isOpen: boolean }>`
  transition: ${({ isOpen }) =>
    isOpen ? "max-height 0.2s ease-out" : "max-height 0.2s ease-out 0.05s"};
  max-height: ${({ isOpen }) => (isOpen ? "300px" : "0")};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-bottom: ${(p) => p.theme.spacing.m};
  }
`;

const FadeInView = styled(View)<{ isActive: boolean }>`
  transition: ${({ isActive }) =>
    isActive ? "opacity 100ms ease-out 50ms" : "opacity 0s ease-out"};
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  max-height: ${({ isActive }) => (isActive ? "none" : "0px")};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0;
    div {
      margin: ${(p) => p.theme.spacing.xs} 0;
    }
  }
`;

export const ContactDetails = ({
  booking,
}: {
  booking: GetBookingQuery["booking"];
}) => {
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [isEmailCopied, setEmailCopied] = useState(false);
  const [isPhoneCopied, setPhoneCopied] = useState(false);
  const [isNameCopied, setIsNameCopied] = useState(false);

  const handleCopyName = async (textToCopy: string) => {
    await navigator.clipboard.writeText(textToCopy);
    setIsNameCopied(true);
    setTimeout(() => setIsNameCopied(false), 1500); // Hide message after 3 seconds
  };

  const handleCopyEmail = async (textToCopy: string) => {
    await navigator.clipboard.writeText(textToCopy);
    setEmailCopied(true);
    setTimeout(() => setEmailCopied(false), 1500); // Hide message after 3 seconds
  };

  const handlePhoneCopy = async (textToCopy: string) => {
    await navigator.clipboard.writeText(textToCopy);
    setPhoneCopied(true);
    setTimeout(() => setPhoneCopied(false), 1500); // Hide message after 3 seconds
  };

  if (!booking.creator.preferredProfile) {
    return null;
  }

  if (booking.creator.accounts.length === 0) {
    return null;
  }

  return (
    <>
      <StackOnMobile>
        <BioWrap>
          <Text margin="s 0 s">
            {booking.creator.preferredProfile.biography}
          </Text>
        </BioWrap>
        <Flex
          margin="s 0 0 0"
          align="center"
          style={{ cursor: "pointer", userSelect: "none" }}
          onClick={() => setShowContactDetails(!showContactDetails)}
        >
          <Text weight="semi" colorPreset="link" margin="0 s 0 0">
            Contact Details
          </Text>
          <Chevron
            size="s"
            direction={showContactDetails ? "up" : "down"}
            colorPreset="link"
            animateTransition
          />
        </Flex>
      </StackOnMobile>
      <ContactDetailsWrap align="center" isOpen={showContactDetails} margin="0">
        <FadeInView isActive={showContactDetails}>
          <StackOnMobile>
            {booking.creator.accounts[0] &&
            booking.creator.accounts[0].firstName ? (
              <ContactDetailWrap align="center">
                <NameIcon style={{ marginTop: -3 }} colorPreset="link" />
                <Text weight="semi" margin="0 0 0 s" colorPreset="link">
                  {`${booking.creator.accounts[0].firstName}${
                    booking.creator.accounts[0] &&
                    booking.creator.accounts[0].lastName
                      ? ` ${booking.creator.accounts[0].lastName}`
                      : null
                  }`}
                </Text>
                <CopyWrap
                  align="center"
                  justify="center"
                  margin="0 m 0 s"
                  className="copyHover"
                  onClick={() =>
                    handleCopyName(
                      `${booking.creator.accounts[0].firstName}${
                        booking.creator.accounts[0].lastName
                          ? ` ${booking.creator.accounts[0].lastName}`
                          : null
                      }`
                    )
                  }
                >
                  <CopiedTextWrap isCopied={isNameCopied}>
                    <span>Copied!</span>
                  </CopiedTextWrap>
                  <CopyIcon width={12} colorPreset="secondary" />
                </CopyWrap>
              </ContactDetailWrap>
            ) : null}
            {booking.creator.accounts[0] &&
            booking.creator.accounts[0].email ? (
              <ContactDetailWrap align="center">
                <MailIcon style={{ marginTop: -3 }} colorPreset="link" />
                <ExternalTextLink
                  target="_blank"
                  href={`mailto:${booking.creator.accounts[0].email}`}
                  size="m"
                  margin="0 0 0 s"
                  colorPreset="link"
                >
                  {booking.creator.accounts[0].email}
                </ExternalTextLink>
                <CopyWrap
                  align="center"
                  justify="center"
                  margin="0 m 0 s"
                  className="copyHover"
                  onClick={() =>
                    handleCopyEmail(booking.creator.accounts[0].email)
                  }
                >
                  <CopiedTextWrap isCopied={isEmailCopied}>
                    <span>Copied!</span>
                  </CopiedTextWrap>
                  <CopyIcon width={12} colorPreset="secondary" />
                </CopyWrap>
              </ContactDetailWrap>
            ) : null}
            {booking.creator.accounts[0] &&
            booking.creator.accounts[0].phoneNumber ? (
              <ContactDetailWrap align="center">
                <PhoneIcon style={{ marginTop: -3 }} colorPreset="link" />
                <ExternalTextLink
                  target="_blank"
                  href={`tel:${booking.creator.accounts[0].phoneNumber}`}
                  size="m"
                  margin="0 0 0 s"
                  colorPreset="link"
                >
                  {booking.creator.accounts[0].phoneNumber}
                </ExternalTextLink>
                <CopyWrap
                  align="center"
                  justify="center"
                  margin="0 0 0 s"
                  className="copyHover"
                  onClick={() =>
                    booking.creator.accounts[0].phoneNumber &&
                    handlePhoneCopy(booking.creator.accounts[0].phoneNumber)
                  }
                >
                  <CopiedTextWrap isCopied={isPhoneCopied}>
                    <span>Copied!</span>
                  </CopiedTextWrap>
                  <CopyIcon width={12} colorPreset="secondary" />
                </CopyWrap>
              </ContactDetailWrap>
            ) : null}
          </StackOnMobile>
        </FadeInView>
      </ContactDetailsWrap>
    </>
  );
};
