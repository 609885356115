import React, { useEffect } from "react";
import styled from "styled-components";
import { MOBILE_BREAKPOINT, XL_DESKTOP_BREAKPOINT } from "../config";
import { CloseIcon } from "./CloseIcon";

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  position?: "left" | "right";
  width?: number | string;
  showCloseButton?: boolean;
  closeOnBackdropClick?: boolean;
}

export const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  onClose,
  children,
  position = "right",
  width = 390,
  showCloseButton = true,
  closeOnBackdropClick = true,
}) => {
  // Prevent body scroll when drawer is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  // Add keyboard support for closing drawer with Escape key
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (isOpen && event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [isOpen, onClose]);

  const handleWrapperClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (closeOnBackdropClick && e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <Container isOpen={isOpen}>
      <BackgroundWrapper isOpen={isOpen} onClick={handleWrapperClick}>
        <DrawerWrapper position={position} width={width} isOpen={isOpen}>
          {showCloseButton && (
            <CloseWrapper onClick={onClose}>
              <CloseIcon color={"#fff"} />
            </CloseWrapper>
          )}
          {children}
        </DrawerWrapper>
      </BackgroundWrapper>
    </Container>
  );
};

const Container = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  transition: visibility 0.2s ease-in-out;
`;

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  opacity: ${(props: { isOpen: boolean }) => (props.isOpen ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
`;

const DrawerWrapper = styled.div<{
  position: "left" | "right";
  width: number | string;
  isOpen: boolean;
}>`
  position: fixed;
  top: ${(p) => p.theme.spacing.xl};
  ${(p) => p.position}: ${(p) => p.theme.spacing.xl};
  width: ${(p) => (typeof p.width === "number" ? `${p.width}px` : p.width)};
  bottom: ${(p) => p.theme.spacing.xl};
  z-index: 1001;
  overflow-y: auto;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  box-shadow: ${(p) => p.theme.shadow.card};
  background-color: ${(p) => p.theme.color.card.background};
  transform: translateX(
    ${(p) => (p.isOpen ? "0" : p.position === "right" ? "120%" : "-120%")}
  );
  transition: all 0.3s ease-in-out;
  will-change: transform;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    height: 100%;
    top: 0;
    ${(p) => p.position}: 0;
    bottom: 0;
    border-radius: 0;
  }

  @media (min-width: ${XL_DESKTOP_BREAKPOINT}px) {
    width: ${(p) =>
      typeof p.width === "number" ? `${p.width + 30}px` : p.width};
  }
`;

const CloseWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: ${(p) => p.theme.spacing.m};
  left: ${(p) => p.theme.spacing.m};
  width: 32px;
  height: 32px;
  display: flex;
  text-decoration: none;
  border-radius: 999px;
  box-shadow: ${(p) => p.theme.shadow.imageFloatingIcon};
  justify-content: center;
  align-items: center;
  background-color: #000000cc;
  padding: 2px;
  z-index: 3;
  transition: background-color 0.2s ease;

  &:hover {
    background: #000;
  }
`;
