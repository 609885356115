// A simpler hook just for checking demo mode status
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useSelector } from "react-redux";
import { authSelectors } from "../store/auth/selector";
import { demoBookingConfig } from "../utils/demoBookingConfig";
import { BookingTabs } from "../utils/enums";

export function useDemoMode() {
  const demoModeEnabled = useFeatureFlagEnabled("demo-mode");
  const activeBrandId = useSelector(authSelectors.activeBrandId);

  const isDemoMode =
    demoModeEnabled &&
    demoBookingConfig.some((brand) => brand.id === activeBrandId);

  const demoBrand = isDemoMode
    ? demoBookingConfig.find((brand) => brand.id === activeBrandId)
    : undefined;

  return {
    isDemoMode,
    demoBrand,
    getDemoBookingIds: (tab: BookingTabs) =>
      demoBrand?.bookings
        .filter((booking) => booking.tab === tab)
        .map((booking) => booking.id) || [],
  };
}
