import { format, fromUnixTime } from "date-fns";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { MatchParams } from ".";
import { PostOverlay, TagWrap } from "../../../components/Brand/BookingDetails";
import { BookingTimeline } from "../../../components/Brand/BookingDetails/BookingTimeline";
import { ContactDetails } from "../../../components/Brand/ContactDetails";
import { Card } from "../../../components/Card";
import { CardLink } from "../../../components/CardLink";
import DetailList from "../../../components/DetailList";
import { CardDivider } from "../../../components/Divider";
import { Flex } from "../../../components/Flex";
import { H3 } from "../../../components/Heading";
import Loading from "../../../components/Loading";
import { Modal } from "../../../components/Modal";
import { PreferredProfile } from "../../../components/PreferredProfile";
import { Column, Table } from "../../../components/Table";
import { Tag } from "../../../components/Tag";
import { Text } from "../../../components/Text";
import { View } from "../../../components/View";
import { MOBILE_BREAKPOINT } from "../../../config";
import {
  BookingPostType,
  BookingStatus,
  BrandBookingsQuery,
  useGetBookingQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import styled, { css } from "../../../styles";
import { renderBookingPostTypeTag } from "../../../utils/enums";

interface Props {
  bookings: BrandBookingsQuery["bookings"]["bookings"];
  lastElementRef: (node: any) => void;
  isFetching: boolean;
}

const Wrap = styled.div`
  height: 100%;
  padding: 0 0 ${(p) => p.theme.spacing.xl};
`;

export const ArchivedBookings = ({
  bookings,
  lastElementRef,
  isFetching,
}: Props) => {
  const [activeBookingId, setActiveBookingId] = useState<string | null>(null);
  let idMatch = useRouteMatch<MatchParams>("/b/bookings/:page/:id");
  const history = useHistory();

  useEffect(() => {
    setActiveBookingId(idMatch && idMatch.params.id ? idMatch.params.id : null);
  }, [idMatch]);

  const columns: Column<BrandBookingsQuery["bookings"]["bookings"][0]>[] = [
    {
      header: "Status",
      key: "status",
      width: 130,
      render: (booking: BrandBookingsQuery["bookings"]["bookings"][0]) => (
        <Text margin="0" colorPreset="text" isCompact>
          {booking.status === BookingStatus.BookingStatusCancelled ? (
            <Tag text="Cancelled" color="purple" />
          ) : booking.status === BookingStatus.BookingStatusExpired ? (
            <Tag text="Expired" color="blue" />
          ) : (
            <Tag text="Rejected" color="red" />
          )}
        </Text>
      ),
    },
    {
      header: "Name",
      key: "name",
      width: 160, // Using wider column width as per original
      render: (booking: BrandBookingsQuery["bookings"]["bookings"][0]) => (
        <Text margin="0" colorPreset="text" isCompact truncate>
          {booking.creator.preferredProfile?.name}
        </Text>
      ),
    },
    {
      header: "Handle",
      key: "handle",
      width: 130,
      render: (booking: BrandBookingsQuery["bookings"]["bookings"][0]) => (
        <Text margin="0" colorPreset="text" isCompact truncate>
          {booking.creator.preferredProfile?.username}
        </Text>
      ),
    },
    {
      header: "Listing",
      key: "listing",
      width: 180,
      render: (booking: BrandBookingsQuery["bookings"]["bookings"][0]) => (
        <Text margin="0" colorPreset="text" isCompact truncate>
          {booking.listing.name}
        </Text>
      ),
    },
    {
      header: "Location",
      key: "location",
      width: 180,
      render: (booking: BrandBookingsQuery["bookings"]["bookings"][0]) => (
        <Text margin="0" colorPreset="text" isCompact truncate>
          {booking.location?.name}
        </Text>
      ),
    },
    {
      header: "Application Date",
      key: "applicationDate",
      width: 180,
      render: (booking: BrandBookingsQuery["bookings"]["bookings"][0]) => (
        <Text margin="0" colorPreset="text">
          {booking.createdAt
            ? format(fromUnixTime(booking.createdAt), "dd/MM/yyyy")
            : ""}
        </Text>
      ),
    },
    {
      header: "Expiry Date",
      key: "expirationDate",
      width: 130,
      render: (booking: BrandBookingsQuery["bookings"]["bookings"][0]) => (
        <Text margin="0" colorPreset="text">
          {booking.status === BookingStatus.BookingStatusExpired &&
          booking.updatedAt
            ? format(fromUnixTime(booking.updatedAt), "dd/MM/yyyy")
            : ""}
        </Text>
      ),
    },
    {
      header: "Cancellation Date",
      key: "cancellationDate",
      width: 180,
      render: (booking: BrandBookingsQuery["bookings"]["bookings"][0]) => (
        <Text margin="0" colorPreset="text">
          {booking.status === BookingStatus.BookingStatusCancelled &&
          booking.updatedAt
            ? format(fromUnixTime(booking.updatedAt), "dd/MM/yyyy")
            : ""}
        </Text>
      ),
    },
    {
      header: "Rejected At",
      key: "rejectedAt",
      width: 130,
      render: (booking: BrandBookingsQuery["bookings"]["bookings"][0]) => (
        <Text margin="0" colorPreset="text">
          {booking.status === BookingStatus.BookingStatusRejected &&
          booking.updatedAt
            ? format(fromUnixTime(booking.updatedAt), "dd/MM/yyyy")
            : ""}
        </Text>
      ),
    },
  ];

  return (
    <Wrap>
      <Modal
        isOpen={activeBookingId ? true : false}
        setIsOpen={() => setActiveBookingId(null)}
        onClose={() => {
          history.replace("/b/bookings/archived");
        }}
        maxWidth={760}
      >
        <ArchivedBookingDetails bookingId={activeBookingId} />
      </Modal>

      <View padding="0 0 xl">
        <Card>
          <Table
            isFetching={isFetching}
            columns={columns}
            data={bookings?.filter((e) => e.creator.preferredProfile) || []}
            onRowClick={(booking) =>
              history.push(`/b/bookings/archived/${booking.id}`)
            }
            lastElementRef={lastElementRef}
          />
        </Card>
      </View>
    </Wrap>
  );
};

export const ArchivedBookingDetails = ({
  bookingId,
}: {
  bookingId: string | null;
}) => {
  const client = useGqlClient();
  const { isLoading, data, isError } = useGetBookingQuery(
    client,
    { id: bookingId ? bookingId : "" },
    {
      retry: false,
    }
  );

  if (isLoading) {
    return (
      <Flex align="center" justify="center">
        <Loading />
      </Flex>
    );
  }

  if (isError || !data) {
    return (
      <View padding="xl">
        <H3>Oops</H3>
        <Text margin={"0 0 xl 0"}>
          Couldn't load your booking, please try again later.
        </Text>
      </View>
    );
  }

  return (
    <View>
      <PreferredProfile
        creator={data.booking.creator}
        platform={data.booking.platform}
        avatarSize={68}
      />
      <ContactDetails booking={data.booking} />
      <CardDivider margin="xl 0 0" />
      {data.booking.bookingEvents.length > 0 && (
        <View margin="xl 0 m">
          <BookingTimeline booking={data.booking} />
        </View>
      )}
      <PostsGrid>
        {data.booking.bookingPosts.map((bp) => (
          <CardLink href={bp.permalink} to={"/b/reports/" + bp.id} key={bp.id}>
            <div style={{ position: "relative" }}>
              <Image
                src={bp.thumbnailUrl ? bp.thumbnailUrl : bp.mediaUrl}
                square={bp.postType === BookingPostType.BookingPostTypeFeed}
              />
              <TagWrap>
                <div>{renderBookingPostTypeTag(bp.postType)}</div>
              </TagWrap>
              <PostOverlay />
            </div>
          </CardLink>
        ))}
      </PostsGrid>
      <View margin="xl 0 0">
        <DetailList
          fields={[
            {
              label: "Name",
              value: data.booking.creator.preferredProfile?.name,
            },
            {
              label: "Handle",
              value: data.booking.creator.preferredProfile?.username,
            },
            {
              label: "Listing",
              value: data.booking.listing.name,
            },
            {
              label: "Location",
              value: data.booking.location ? data.booking.location.name : "",
            },
            {
              label: "Status",
              value:
                data.booking.status === BookingStatus.BookingStatusCancelled
                  ? "Cancelled"
                  : data.booking.status === BookingStatus.BookingStatusExpired
                  ? "Expired"
                  : "Rejected",
            },
            {
              label: "Application Date",
              value: data.booking.createdAt
                ? format(fromUnixTime(data.booking.createdAt), "dd/MM/yyyy")
                : "",
            },
            data.booking.status === BookingStatus.BookingStatusExpired && {
              label: "Expiration Date",
              value: data.booking.updatedAt
                ? format(fromUnixTime(data.booking.updatedAt), "dd/MM/yyyy")
                : "",
            },
            data.booking.status === BookingStatus.BookingStatusCancelled && {
              label: "Cancellation Date",
              value: data.booking.updatedAt
                ? format(fromUnixTime(data.booking.updatedAt), "dd/MM/yyyy")
                : "",
            },
            data.booking.status === BookingStatus.BookingStatusCancelled && {
              label: "Cancellation Reason",
              value: data.booking.cancellationReason
                ? data.booking.cancellationReason
                : "",
            },
            data.booking.status === BookingStatus.BookingStatusRejected && {
              label: "Rejection Date",
              value: data.booking.updatedAt
                ? format(fromUnixTime(data.booking.updatedAt), "dd/MM/yyyy")
                : "",
            },
            data.booking.status === BookingStatus.BookingStatusRejected && {
              label: "Rejection Reason",
              value: data.booking.rejectionMessage
                ? data.booking.rejectionMessage
                : "",
            },
          ]}
        />
      </View>
    </View>
  );
};

const Image = styled.img<{ maxHeight?: number; square: boolean }>`
  ${(p) =>
    p.square
      ? css`
          aspect-ratio: 1 / 1;
        `
      : css`
          aspect-ratio: 1 / 1;
        `}
  width: 100%;
  object-fit: cover;
  z-index: 1;
  box-shadow: ${(p) => p.theme.shadow.card};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  max-height: ${(p) => (p.maxHeight ? `${p.maxHeight}px` : "none")};
`;

const PostsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: ${(p) => p.theme.spacing.l};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
  }
`;
