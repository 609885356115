import { fromUnixTime, getUnixTime, startOfHour } from "date-fns";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import { Button } from "../../../../components/CTA";
import { DateInput, DatePicker } from "../../../../components/DatePicker";
import { Flex } from "../../../../components/Flex";
import { Text } from "../../../../components/Text";
import { BookingsIcon } from "../../../../components/icons/BookingsIcon";
import { TrashIcon } from "../../../../components/icons/TrashIcon";
import { lightTheme } from "../../../../styles/theme";

export const Events = (props: {
  timeSlots: number[];
  setTimeSlots: (timeSlots: number[]) => void;
}) => {
  const initializedRef = useRef(false);

  const addEvent = () => {
    const newTimeSlots = [...props.timeSlots];
    newTimeSlots.push(getUnixTime(startOfHour(new Date())));
    props.setTimeSlots(newTimeSlots);
  };

  useEffect(() => {
    if (!initializedRef.current) {
      initializedRef.current = true;
      if (props.timeSlots.length === 0) {
        addEvent();
      }
    }
  }, []);

  return (
    <div>
      <Text weight="bold" margin="xxl 0 s">
        Event Dates
      </Text>
      {props.timeSlots.map((timeSlot, index) => (
        <Event
          key={index}
          id={index.toString()}
          onChange={(timeSlot) => {
            const newTimeSlots = [...props.timeSlots];
            newTimeSlots[index] = timeSlot ?? 0;
            props.setTimeSlots(newTimeSlots);
          }}
          onRemove={() => {
            const newTimeSlots = [...props.timeSlots];
            newTimeSlots.splice(index, 1);
            props.setTimeSlots(newTimeSlots);
          }}
          initialValue={timeSlot}
          index={index}
          isOnly={props.timeSlots.length === 1}
        />
      ))}
      <Flex margin="l 0 0 0">
        <div>
          <Button buttonType="add" size="s" onClick={addEvent}>
            + Add Event
          </Button>
        </div>
      </Flex>
    </div>
  );
};

interface EventProps {
  id: string;
  onChange: (timeSlot: number | undefined) => void;
  onRemove: (id: string) => void;
  initialValue?: number;
  index: number;
  isOnly: boolean;
}

const Event = (props: EventProps) => {
  return (
    <Flex direction="row" align="center" margin="0 0 m">
      <DatePicker
        showTime={true}
        dateFormat="EEE, do MMMM 'at' h:mm a"
        placeholderText="Start Date"
        isClearable={false}
        startDate={new Date()}
        date={props.initialValue ? fromUnixTime(props.initialValue) : null}
        setDate={(date) => {
          props.onChange(date ? getUnixTime(date) : undefined);
        }}
        icon={
          <div
            style={{
              margin: "auto 0",
            }}
          >
            <div
              style={{
                marginTop: 3,
                marginRight: lightTheme.spacing.xl,
                marginLeft: lightTheme.spacing.s,
              }}
            >
              <BookingsIcon colorPreset="secondary" width={17} />
            </div>
          </div>
        }
        customInput={
          <StyledDateInput clearable={false} noDates={false} hasIcon={true} />
        }
      />
      <TrashWrap
        justify="center"
        align="center"
        margin="0 0 0 m"
        style={{
          display: props.isOnly ? "none" : "flex",
        }}
        onClick={() => {
          props.onRemove(props.id);
        }}
      >
        <TrashIcon width={16} />
      </TrashWrap>
    </Flex>
  );
};

export default Event;

export const StyledDateInput = styled(DateInput)`
  width: 220px;
`;

const TrashWrap = styled(Flex)`
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  svg {
    path {
      fill: ${(p) => p.theme.color.typography.secondary};
    }
  }

  &:hover {
    svg {
      path {
        fill: ${(p) => p.theme.color.typography.text};
      }
    }
  }
`;
