import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "sonner";
import {
  BookingStatus,
  GetBookingQuery,
  useProgressBookingMutation,
} from "../../../graphql/generated";
import useAnalytics from "../../../hooks/useAnalytics";
import useGqlClient from "../../../hooks/useGqlClient";
import { useTheme } from "../../../hooks/useTheme";
import { authSelectors } from "../../../store/auth/selector";
import styled from "../../../styles";
import { Button, ButtonWrap } from "../../CTA";
import { Flex } from "../../Flex";
import Loader from "../../Loader";
import { Text } from "../../Text";
import { View } from "../../View";

const StyledFlex = styled(Flex)`
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

interface Props {
  booking: GetBookingQuery["booking"];
  onCancel: () => void;
}

export const CompleteBookingOverlay = (props: Props) => {
  const { track } = useAnalytics();
  const client = useGqlClient();
  const progressBooking = useProgressBookingMutation(client);
  const brand = useSelector(authSelectors.activeBrand);
  const history = useHistory();
  const theme = useTheme();
  const queryClient = useQueryClient();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        props.onCancel();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [props]);

  return (
    <>
      {progressBooking.isLoading ? (
        <Flex align="center" justify="center">
          <Loader />
        </Flex>
      ) : (
        <StyledFlex>
          <Text
            weight="bold"
            size="xl"
            margin={"0 0 0 0"}
            colorPreset="heading"
          >
            Complete Booking
          </Text>
          <Text margin={"s 0 m 0"} colorPreset="secondary" isInline>
            Are you sure you'd like to complete this booking before all the
            content has been posted?{" "}
            <span
              style={{
                fontWeight: theme.typography.weight.bold,
                color: theme.color.typography.heading,
              }}
            >
              Any additional content posted after this point will not be tracked
              on Joli.
            </span>
          </Text>

          <ButtonWrap margin="l 0 0">
            <Button buttonType="secondary" onClick={props.onCancel}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                progressBooking.mutate(
                  {
                    bookingId: props.booking.id,
                    status: BookingStatus.BookingStatusCompleted,
                  },
                  {
                    onSuccess: (res) => {
                      toast.success("Booking completed");
                      track("Booking completed", {
                        id: res.progressBooking.id
                          ? res.progressBooking.id
                          : undefined,
                        brand: brand ? brand.name : undefined,
                      });
                      props.onCancel();
                      history.replace("/b/bookings/completed");
                      queryClient.resetQueries(["getBooking"], {
                        exact: false,
                      });
                    },
                    onError: (error: any) => {
                      console.error("error: ", error);
                    },
                  }
                );
              }}
            >
              Complete
            </Button>
          </ButtonWrap>
        </StyledFlex>
      )}
    </>
  );
};

export const Alert = styled(View)`
  background-color: ${(p) => p.theme.color.secondary}30;
  border-left: 3px solid ${(p) => p.theme.color.secondary};
  border-radius: 2px;
  width: 100%;
  box-sizing: border-box;

  color: ${(p) => p.theme.color.secondary};
`;
