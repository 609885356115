import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Callout } from "../../../components/Callout";
import { Carousel, CarouselItem } from "../../../components/Carousel";
import { CloseIcon } from "../../../components/CloseIcon";
import { CardDivider } from "../../../components/Divider";
import { Drawer } from "../../../components/Drawer";
import { Flex } from "../../../components/Flex";
import { H1 } from "../../../components/Heading";
import { PostIcon } from "../../../components/icons/Instagram/PostIcon";
import { ReelIcon } from "../../../components/icons/Instagram/ReelIcon";
import { StoryIcon } from "../../../components/icons/Instagram/StoryIcon";
import { NoEntry } from "../../../components/icons/NoEntry";
import { TickIcon } from "../../../components/icons/TickIcon";
import { Radio } from "../../../components/RadioButton";
import { StyledMarkdown } from "../../../components/StyledMarkdown";
import { Text } from "../../../components/Text";
import { ExternalTextLink } from "../../../components/TextLink";
import { View } from "../../../components/View";
import {
  ListingOfferInput,
  MediaType,
  Platform,
  useBrandLocationsQuery,
  useDietaryPreferencesQuery,
  useGetBrandHandlesQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { useTheme } from "../../../hooks/useTheme";
import { authSelectors } from "../../../store/auth/selector";
import styled, { css } from "../../../styles";

interface PreviewProps {
  isOpen: boolean;
  onClose: () => void;
  images: string[];
  instagramOffers: ListingOfferInput[];
  tiktokOffers: ListingOfferInput[];
  name: string;
  description: string;
  locations: string[];
  instagramHandles: string[];
  tiktokHandles: string[];
  reels: number;
  posts: number;
  stories: number;
  tiktoks: number;
  dietaryPreferences?: string[] | null;
  ageRestrictions: number | null;
  videoUrl?: string;
}

export const Preview = (props: PreviewProps) => {
  const theme = useTheme();
  const client = useGqlClient();
  const [selectedPlatform, setSelectedPlatform] = useState<Platform>(
    Platform.Instagram
  );

  const [playVideo, setPlayVideo] = useState<boolean | null>(null);

  const { data: dietaryPreferencesData } = useDietaryPreferencesQuery(client);
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const { data: brandHandles } = useGetBrandHandlesQuery(client, {
    id: activeBrandId ?? "",
  });
  const { data: brandLocationsData } = useBrandLocationsQuery(client, {
    brandId: activeBrandId ?? "",
  });

  const [activeOffer, setActiveOffer] = useState<ListingOfferInput | null>(
    null
  );

  useEffect(() => {
    if (selectedPlatform === Platform.Instagram) {
      if (props.instagramOffers.length === 0) {
        setActiveOffer(null);
      } else if (props.instagramOffers.length > 1) {
        setActiveOffer(props.instagramOffers[1]);
      } else {
        setActiveOffer(props.instagramOffers[0]);
      }
    } else {
      if (props.tiktokOffers.length === 0) {
        setActiveOffer(null);
      } else if (props.tiktokOffers.length > 1) {
        setActiveOffer(props.tiktokOffers[1]);
      } else {
        setActiveOffer(props.tiktokOffers[0]);
      }
    }
  }, [selectedPlatform, props.instagramOffers, props.tiktokOffers]);

  const instagramHandle =
    brandHandles && brandHandles.brand && brandHandles.brand.instagramHandle
      ? brandHandles.brand.instagramHandle
      : props.instagramHandles[0];
  const tiktokHandle =
    brandHandles && brandHandles.brand && brandHandles.brand.tikTokHandle
      ? brandHandles.brand.tikTokHandle
      : props.tiktokHandles[0];

  const renderLocationText = () => {
    if (
      !brandLocationsData ||
      !props.locations ||
      props.locations.length === 0
    ) {
      return "No locations selected";
    }

    if (props.locations.length === 1) {
      const singleLocation =
        brandLocationsData?.brand?.locations.locations.find((l) =>
          props.locations.includes(l.id)
        );
      return singleLocation ? singleLocation.address : "Select locations";
    }

    return "Multiple locations";
  };

  const carouselItems = [
    ...(props.videoUrl ? [{ type: MediaType.Video, url: props.videoUrl }] : []),
    ...props.images.map((url) => ({ type: MediaType.Image, url })),
  ] as CarouselItem[];

  return (
    <Drawer
      isOpen={props.isOpen}
      onClose={props.onClose}
      width={400}
      position="right"
    >
      <div style={{ position: "relative" }}>
        <CloseWrapper
          onClick={playVideo ? () => setPlayVideo(false) : props.onClose}
        >
          <CloseIcon color={"#fff"} />
        </CloseWrapper>
        <Carousel items={carouselItems} />
        <View padding="m l">
          <Callout type="info">
            <Text size="s" margin="0" style={{ lineHeight: "1.3" }}>
              This is a simplified preview of what an influencer will see on
              their Joli app.
            </Text>
          </Callout>
          <H1 margin="l 0 xs">{props.name}</H1>
          <Text margin="0 0 m" colorPreset="secondary">
            {renderLocationText()}
          </Text>
          <StyledMarkdown markdown={props.description} />
          <TagsWrap>
            {props.dietaryPreferences &&
              props.dietaryPreferences.length > 0 &&
              dietaryPreferencesData?.dietaryPreferences
                .filter(
                  (dp) =>
                    props.dietaryPreferences &&
                    props.dietaryPreferences.includes(dp.id)
                )
                .map((dp) => (
                  <DietaryPreferencesWrap key={dp.id}>
                    <Flex margin="0 xs 0 0" justify="center" align="center">
                      <TickIcon
                        color={theme.color.typography.secondary}
                        width={20}
                      />
                    </Flex>
                    <Text size="xs" weight="semi" margin="0">
                      {dp.label}
                    </Text>
                  </DietaryPreferencesWrap>
                ))}
            {props.ageRestrictions && props.ageRestrictions > 0 ? (
              <AgeRestrictionsWrap>
                <Flex
                  margin="0 xs 0 0"
                  justify="center"
                  align="center"
                  style={{ marginTop: -3 }}
                >
                  <NoEntry width={16} color={theme.color.primary} />
                </Flex>
                <Text size="xs" weight="semi" margin="xxs 0">
                  {props.ageRestrictions}+ Only
                </Text>
              </AgeRestrictionsWrap>
            ) : null}
          </TagsWrap>
          <View margin="l 0 0 0">
            {props.instagramHandles && props.instagramHandles.length > 0 && (
              <Flex direction="row" align="center" margin="s 0">
                <Text size="s" weight="semi" margin="0">
                  Instagram
                </Text>
                <ExternalTextLink
                  size="s"
                  margin="0 0 0 l"
                  href={`https://instagram.com/${instagramHandle}`}
                  target="_blank"
                >
                  @{instagramHandle}
                </ExternalTextLink>
              </Flex>
            )}
            {tiktokHandle && (
              <Flex direction="row" align="center" margin="s 0">
                <Text size="s" weight="semi" margin="0">
                  TikTok
                </Text>
                <ExternalTextLink
                  size="s"
                  margin="0 0 0 l"
                  href={`https://tiktok.com/@${tiktokHandle}`}
                  target="_blank"
                >
                  @{tiktokHandle}
                </ExternalTextLink>
              </Flex>
            )}
          </View>
        </View>
        {props.instagramOffers.length > 0 && props.tiktokOffers.length > 0 ? (
          <>
            <CardDivider margin="0 0 l" />
            <View margin="0 l m">
              <Text size="m" weight="bold" margin="0 0 m 0">
                Platform
              </Text>
              <Flex align="center" margin="0">
                {props.instagramOffers && props.instagramOffers.length > 0 && (
                  <PlatformButton
                    title="Instagram"
                    active={selectedPlatform === Platform.Instagram}
                    onClick={() => {
                      setSelectedPlatform(Platform.Instagram);
                    }}
                  />
                )}
                {props.tiktokOffers && props.tiktokOffers.length > 0 && (
                  <PlatformButton
                    title="TikTok"
                    active={selectedPlatform === Platform.Tiktok}
                    onClick={() => {
                      setSelectedPlatform(Platform.Tiktok);
                    }}
                  />
                )}
              </Flex>
            </View>
          </>
        ) : null}
        <CardDivider margin="xl 0 l" />
        <View margin="0 l l">
          <Text size="m" weight="bold" margin="0 0 s 0">
            Offer
          </Text>
          {activeOffer ? (
            <Text margin="0" weight="semi">
              {activeOffer.offer}{" "}
              {activeOffer.maxGuests
                ? activeOffer.maxGuests === 1
                  ? "for you and 1 guest"
                  : `for you and ${activeOffer.maxGuests} guests`
                : ""}
            </Text>
          ) : (
            <Text margin="0" weight="semi">
              No offers available
            </Text>
          )}
        </View>
        <CardDivider margin="l 0 l" />
        <View margin="0 l xl">
          <Text size="m" weight="bold" margin="l 0 m 0">
            Deliverables
          </Text>
          {selectedPlatform === Platform.Instagram && (
            <>
              {props.stories > 0 && (
                <Flex justify="space-between" align="center" margin="0 0 l">
                  <Flex align="center" direction="row">
                    <StoryIcon width={26} height={26} colorPreset="text" />
                    <Text margin="0 0 0 m" weight="semi">
                      Instagram Story
                    </Text>
                  </Flex>
                  <Text size="m" margin="0" colorPreset="link" weight="bold">
                    x{props.stories}
                  </Text>
                </Flex>
              )}
              {props.posts > 0 && (
                <Flex justify="space-between" align="center" margin="0 0 l">
                  <Flex align="center" direction="row">
                    <PostIcon width={26} height={26} colorPreset="text" />
                    <Text margin="0 0 0 m" weight="semi">
                      Instagram Post
                    </Text>
                  </Flex>
                  <Text size="m" margin="0" colorPreset="link" weight="bold">
                    x{props.posts}
                  </Text>
                </Flex>
              )}
              {props.reels > 0 && (
                <Flex justify="space-between" align="center" margin="0 0 m">
                  <Flex align="center" direction="row">
                    <ReelIcon width={26} height={26} colorPreset="text" />
                    <Text margin="0 0 0 m" weight="semi">
                      Instagram Reel
                    </Text>
                  </Flex>
                  <Text size="m" margin="0" colorPreset="link" weight="bold">
                    x{props.reels}
                  </Text>
                </Flex>
              )}
            </>
          )}

          {selectedPlatform === Platform.Tiktok && (
            <>
              {props.tiktoks > 0 && (
                <Flex justify="space-between" align="center" margin="0">
                  <Flex align="center" direction="row">
                    <ReelIcon width={26} height={26} colorPreset="text" />
                    <Text margin="0 0 0 m" weight="semi">
                      TikTok Video
                    </Text>
                  </Flex>
                  <Text size="l" margin="0" colorPreset="link" weight="bold">
                    x{props.tiktoks}
                  </Text>
                </Flex>
              )}
            </>
          )}
        </View>
      </div>
    </Drawer>
  );
};

const TagsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${(p) => p.theme.spacing.s};
  margin-top: ${(p) => p.theme.spacing.m};
`;

const DietaryPreferencesWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.s};
  background-color: ${(p) => p.theme.color.card.callout};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  height: 24px;
`;

const AgeRestrictionsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(p) => p.theme.spacing.xxs} ${(p) => p.theme.spacing.s};
  background-color: ${(p) => p.theme.color.input.hover};
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  height: 24px;
`;

export const PlatformButton = ({
  title,
  active,
  onClick,
}: {
  title: string;
  active: boolean;
  onClick: () => void;
}) => {
  return (
    <PlatformButtonWrap selected={active} onClick={onClick}>
      <Radio width={10} height={10} active={active} />
      <Text size="m" weight="bold" margin="0 0 0 m">
        {title}
      </Text>
    </PlatformButtonWrap>
  );
};

const PlatformButtonWrap = styled.div<{ selected: boolean }>`
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.color.typography.secondary}50;
  border-radius: ${(p) => p.theme.misc.borderRadiusSmall};
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.m};
  cursor: pointer;
  margin-right: ${(p) => p.theme.spacing.m};
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${(p) => p.theme.color.card.background};

  ${(p) =>
    p.selected
      ? css`
          border-color: ${p.theme.color.primary};
          box-shadow: inset 0 0 0 1px ${p.theme.color.primary};
        `
      : null}
`;

const CloseWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: ${(p) => p.theme.spacing.m};
  left: ${(p) => p.theme.spacing.m};
  width: 32px;
  height: 32px;
  display: flex;
  text-decoration: none;
  border-radius: 999px;
  box-shadow: ${(p) => p.theme.shadow.imageFloatingIcon};
  justify-content: center;
  align-items: center;
  background-color: #000000cc;
  padding: 2px;
  z-index: 3;

  &:hover {
    background: #000;
  }
`;
