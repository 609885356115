import { format, fromUnixTime } from "date-fns";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { MatchParams } from ".";
import {
  BrandMentionWrap,
  HoverOverlay,
  PostOverlay,
  PostWrap,
  SocialMediaHandle,
  TagWrap,
} from "../../../components/Brand/BookingDetails";
import { BookingTimeline } from "../../../components/Brand/BookingDetails/BookingTimeline";
import { ContactDetails } from "../../../components/Brand/ContactDetails";
import { Card } from "../../../components/Card";
import { CardLink } from "../../../components/CardLink";
import DetailList from "../../../components/DetailList";
import { Flex } from "../../../components/Flex";
import { H3 } from "../../../components/Heading";
import { PressLinkIcon } from "../../../components/icons/PressLinkIcon";
import Loading from "../../../components/Loading";
import { Modal } from "../../../components/Modal";
import { PreferredProfile } from "../../../components/PreferredProfile";
import { Column, Table } from "../../../components/Table";
import { Text } from "../../../components/Text";
import { View } from "../../../components/View";
import { MOBILE_BREAKPOINT } from "../../../config";
import {
  BookingPostType,
  BrandBookingsQuery,
  useGetBookingQuery,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import styled, { css } from "../../../styles";
import { renderBookingPostTypeTag } from "../../../utils/enums";

interface Props {
  bookings: BrandBookingsQuery["bookings"]["bookings"];
  lastElementRef: (node: any) => void;
  isFetching: boolean;
}

const Wrap = styled.div`
  height: 100%;
  padding: 0 0 ${(p) => p.theme.spacing.xl};
`;

export const CompletedBookings = ({
  bookings,
  lastElementRef,
  isFetching,
}: Props) => {
  const [activeBookingId, setActiveBookingId] = useState<string | null>(null);
  let idMatch = useRouteMatch<MatchParams>("/b/bookings/:page/:id");
  const history = useHistory();

  useEffect(() => {
    setActiveBookingId(idMatch && idMatch.params.id ? idMatch.params.id : null);
  }, [idMatch]);

  const columns: Column<BrandBookingsQuery["bookings"]["bookings"][0]>[] = [
    {
      header: "Name",
      key: "name",
      width: 160,
      render: (booking) => (
        <Text margin="0" colorPreset="text" isCompact truncate>
          {booking.creator.preferredProfile?.name}
        </Text>
      ),
    },
    {
      header: "Handle",
      key: "handle",
      width: 130,
      render: (booking) => (
        <Text margin="0" colorPreset="text" truncate>
          {booking.creator.preferredProfile?.username}
        </Text>
      ),
    },
    {
      header: "Listing",
      key: "listing",
      width: 160,
      render: (booking) => (
        <Text margin="0" colorPreset="text" size="s" truncate>
          {booking.listing.name}
        </Text>
      ),
    },
    {
      header: "Location",
      key: "location",
      width: 130,
      render: (booking) => (
        <Text margin="0" colorPreset="text" size="s" truncate>
          {booking.location ? booking.location.name : ""}
        </Text>
      ),
    },
    {
      header: "Date of Visit",
      key: "visitDate",
      width: 130,
      render: (booking) => (
        <Text margin="0" colorPreset="text">
          {booking.confirmedTimeslot
            ? format(fromUnixTime(booking.confirmedTimeslot.date), "dd/MM/yyyy")
            : booking.redeemedAt
            ? format(fromUnixTime(booking.redeemedAt), "dd/MM/yyyy")
            : ""}
        </Text>
      ),
    },
    {
      header: "Date of Completion",
      key: "completionDate",
      width: 160,
      render: (booking) => (
        <Text margin="0" colorPreset="text">
          {booking.completedAt
            ? format(fromUnixTime(booking.completedAt), "dd/MM/yyyy")
            : booking.updatedAt
            ? format(fromUnixTime(booking.updatedAt), "dd/MM/yyyy")
            : ""}
        </Text>
      ),
    },
    {
      header: "Feedback Score",
      key: "rating",
      width: 130,
      render: (booking) => (
        <Text margin="0" colorPreset="text">
          {booking.rating ? booking.rating : ""}
        </Text>
      ),
    },
    {
      header: "Feedback details",
      key: "feedback",
      width: 160,
      render: (booking) => (
        <Text margin="0" colorPreset="text" truncate>
          {booking.feedback ? booking.feedback : ""}
        </Text>
      ),
    },
  ];

  return (
    <Wrap>
      <Modal
        isOpen={activeBookingId ? true : false}
        setIsOpen={() => setActiveBookingId(null)}
        onClose={() => {
          history.replace("/b/bookings/completed");
          setActiveBookingId(null);
        }}
        maxWidth={760}
      >
        <CompletedBookingDetails
          bookingId={activeBookingId ? activeBookingId : ""}
        />
      </Modal>

      <View padding="0 0 xl">
        <Card>
          <Table
            columns={columns}
            data={bookings}
            onRowClick={(booking) => {
              history.push(`/b/bookings/completed/${booking.id}`);
            }}
            lastElementRef={lastElementRef}
            isFetching={isFetching}
          />
        </Card>
      </View>
    </Wrap>
  );
};

export const CompletedBookingDetails = ({
  bookingId,
}: {
  bookingId: string | null;
}) => {
  const client = useGqlClient();
  const { isLoading, data, isError } = useGetBookingQuery(
    client,
    { id: bookingId ? bookingId : "" },
    {
      retry: false,
    }
  );

  if (isLoading) {
    return (
      <Flex align="center" justify="center">
        <Loading />
      </Flex>
    );
  }

  if (isError || !data) {
    return (
      <View padding="xl">
        <H3>Oops</H3>
        <Text margin={"0 0 xl 0"}>
          Couldn't load your booking, please try again later.
        </Text>
      </View>
    );
  }

  return (
    <View>
      <PreferredProfile
        creator={data.booking.creator}
        platform={data.booking.platform}
        avatarSize={68}
      />
      <ContactDetails booking={data.booking} />
      {data.booking.bookingEvents.length > 0 && (
        <View margin="xl 0 m">
          <BookingTimeline booking={data.booking} />
        </View>
      )}
      <H3 margin="xl 0 m">Completed Content</H3>
      <PostsGrid>
        {data.booking.bookingPosts.map((bp) => (
          <CardLink href={bp.permalink} to={"/b/reports/" + bp.id} key={bp.id}>
            <PostWrap>
              <Image
                src={bp.thumbnailUrl ? bp.thumbnailUrl : bp.mediaUrl}
                square={bp.postType === BookingPostType.BookingPostTypeFeed}
              />
              <TagWrap>
                <div>{renderBookingPostTypeTag(bp.postType)}</div>
              </TagWrap>
              <HoverOverlay className="show-on-hover">
                <BrandMentionWrap>
                  <Flex
                    margin="0 0 0 0"
                    align="center"
                    justify="center"
                    style={{ marginTop: -2 }}
                  >
                    <PressLinkIcon width={16} />
                  </Flex>
                  <SocialMediaHandle>View in Reports</SocialMediaHandle>
                </BrandMentionWrap>
              </HoverOverlay>
              <PostOverlay />
            </PostWrap>
          </CardLink>
        ))}
      </PostsGrid>
      {data.booking.bookingEvents.length === 0 && (
        <View margin="xl 0 0">
          <DetailList
            fields={[
              {
                label: "Name",
                value: data.booking.creator.preferredProfile?.name,
              },
              {
                label: "Handle",
                value: data.booking.creator.preferredProfile?.username,
              },
              {
                label: "Listing",
                value: data.booking.listing.name,
              },
              {
                label: "Location",
                value: data.booking.location ? data.booking.location.name : "",
              },
              {
                label: "Date of Visit",
                value: data.booking.confirmedTimeslot
                  ? format(
                      fromUnixTime(data.booking.confirmedTimeslot.date),
                      "dd/MM/yyyy"
                    )
                  : data.booking.redeemedAt
                  ? format(fromUnixTime(data.booking.redeemedAt), "dd/MM/yyyy")
                  : "",
              },
              {
                label: "Date of Completion",
                value: data.booking.completedAt
                  ? format(fromUnixTime(data.booking.completedAt), "dd/MM/yyyy")
                  : "",
              },
              {
                label: "Feedback Score",
                value: data.booking.rating ? data.booking.rating : "",
              },
              {
                label: "Feedback Details",
                value: data.booking.feedback ? data.booking.feedback : "",
              },
            ]}
          />
        </View>
      )}
    </View>
  );
};

const Image = styled.img<{ maxHeight?: number; square: boolean }>`
  ${(p) =>
    p.square
      ? css`
          aspect-ratio: 1 / 1;
        `
      : css`
          aspect-ratio: 1 / 1;
        `}
  width: 100%;
  object-fit: cover;
  z-index: 1;
  box-shadow: ${(p) => p.theme.shadow.card};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  max-height: ${(p) => (p.maxHeight ? `${p.maxHeight}px` : "none")};
`;

const PostsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: ${(p) => p.theme.spacing.l};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: 1fr 1fr;
  }
`;
