import { useEffect, useRef, useState } from "react";
import { BookingPostMediaType, BookingPostType } from "../graphql/generated";
import { css, styled } from "../styles";
import { PlayIcon } from "./icons/PlayIcon";

const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 1;
`;

const Video = styled.video<{ borderRadius?: "full" | "none" }>`
  height: 100%;
  width: 100%;
  cursor: pointer;
  border-top-left-radius: ${(p) => p.theme.misc.borderRadius};
  border-top-right-radius: ${(p) => p.theme.misc.borderRadius};
  ${(p) =>
    p.borderRadius === "full" &&
    css`
      border-radius: ${(p) => p.theme.misc.borderRadius};
    `}
`;

const VideoWrapper = styled.div<{ borderRadius?: "full" | "none" }>`
  position: relative;
  ${(p) =>
    p.borderRadius === "full" &&
    css`
      border-radius: ${(p) => p.theme.misc.borderRadius};
    `}
`;

const PlayButtonWrap = styled.div<{
  playVideo: boolean | null;
  borderRadius?: "full" | "none";
}>`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  position: absolute;
  top: -8.5px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  transition: all 150ms linear;

  ${(p) =>
    p.borderRadius === "full" &&
    css`
      border-radius: ${(p) => p.theme.misc.borderRadius};
    `}

  ${(p) =>
    p.playVideo
      ? css`
          opacity: 0;
          visibility: hidden;
        `
      : css`
          opacity: 1;
          visibility: visible;
        `}

  svg {
    transition: opacity 0.3s ease, scale 0.3s ease;
    opacity: 0.7;
    scale: 1.2;
  }

  &:hover {
    svg {
      opacity: 0.9;
      scale: 1.25;
    }
  }
`;

interface MediaAssetProps {
  mediaType: BookingPostMediaType;
  postType: BookingPostType;
  mediaUrl: string;
  thumbnailUrl?: string;
  permalink?: string;
  borderRadius?: "full" | "none";
}

export const MediaAsset: React.FC<MediaAssetProps> = ({
  mediaType,
  postType,
  mediaUrl,
  thumbnailUrl,
  permalink,
  borderRadius = "none",
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [playVideo, setPlayVideo] = useState<boolean | null>(null);

  useEffect(() => {
    if (!videoRef.current || playVideo === null) {
      return;
    }
    playVideo ? videoRef.current.play() : videoRef.current.pause();
  }, [playVideo]);

  const imageURL = thumbnailUrl || mediaUrl;
  const path = new URL(imageURL).pathname;
  const optimizedImageUrl = `https://ik.imagekit.io/fxuomw4xy/${path}?tr=w-800`;

  const isVideoPending =
    (postType === BookingPostType.BookingPostTypeTiktok ||
      postType === BookingPostType.BookingPostTypeReel) &&
    mediaType === BookingPostMediaType.BookingPostMediaTypeImage;

  if (isVideoPending) {
    return (
      <VideoWrapper borderRadius={borderRadius}>
        <a target="_blank" rel="noreferrer" href={permalink}>
          <PlayButtonWrap playVideo={false}>
            <PlayIcon />
          </PlayButtonWrap>
        </a>
        <Image src={optimizedImageUrl} />
      </VideoWrapper>
    );
  }

  if (mediaType === BookingPostMediaType.BookingPostMediaTypeImage) {
    return <Image src={mediaUrl} />;
  }

  return (
    <VideoWrapper borderRadius={borderRadius}>
      <PlayButtonWrap
        playVideo={playVideo}
        onClick={() => setPlayVideo(true)}
        borderRadius={borderRadius}
      >
        <PlayIcon />
      </PlayButtonWrap>
      <Video
        borderRadius={borderRadius}
        muted
        ref={videoRef}
        src={mediaUrl}
        poster={optimizedImageUrl}
        onClick={() => setPlayVideo(!playVideo)}
        onEnded={() => {
          if (!videoRef.current) {
            return;
          }
          setPlayVideo(false);
          videoRef.current.currentTime = 0;
        }}
      />
    </VideoWrapper>
  );
};
