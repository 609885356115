import { useTheme } from "../../hooks/useTheme";
import styled from "../../styles";
import { Flex } from "../Flex";
import { Text } from "../Text";

interface AddIconProps {
  size?: number;
}

const AddIcon = ({ size = 32 }: AddIconProps) => {
  const theme = useTheme();

  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <circle
        cx="12"
        cy="12"
        r="11"
        stroke={`${theme.color.typography.secondary}70`}
        strokeWidth="1"
      />
      <path
        d="M12 8.4V15.6M8.4 12H15.6"
        stroke={theme.color.typography.text}
        strokeWidth="1"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default AddIcon;

export const AddButton = ({
  onAdd,
  label,
}: {
  onAdd: () => void;
  label: string;
}) => {
  return (
    <ButtonWrap margin="0 0 0 0" onClick={onAdd} align="center">
      <AddIcon />
      <Text size="m" weight="bold" margin="0 0 0 s">
        Add {label}
      </Text>
    </ButtonWrap>
  );
};

const ButtonWrap = styled(Flex)`
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  p {
    color: ${(p) => p.theme.color.typography.text};
  }

  &:hover {
    svg {
      circle {
        stroke: ${(p) => p.theme.color.primary};
      }
      path {
        stroke: ${(p) => p.theme.color.primary};
      }
    }
    p {
      color: ${(p) => p.theme.color.primary};
    }
  }
`;
