import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { toast } from "sonner";
import { SignupHeader } from "../../components/Brand/SignupHeader";
import { Callout } from "../../components/Callout";
import { Submit } from "../../components/CTA";
import { H1, H3 } from "../../components/Heading";
import { CheckCircle } from "../../components/icons/CheckCircle";
import { Input } from "../../components/Input";
import Loading from "../../components/Loading";
import { Steps } from "../../components/Steps";
import { Subtitle } from "../../components/Subtitle";
import { Text } from "../../components/Text";
import { ToggleButton } from "../../components/ToggleButton";
import { View } from "../../components/View";
import config, { MOBILE_BREAKPOINT } from "../../config";
import {
  useAccountsQuery,
  useUpdateAcccountMutation,
} from "../../graphql/generated";
import useAnalytics from "../../hooks/useAnalytics";
import useGqlClient from "../../hooks/useGqlClient";
import { useWindowSize } from "../../hooks/useWindowSize";
import { styled } from "../../styles";
import { SignupCard } from "../brands/signup/CreateBrand";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  align-self: center;
  text-align: left;
  margin-bottom: 30px;
  padding-top: ${(p) => p.theme.spacing.l};
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${(p) => p.theme.spacing.l};
  align-self: center;
  height: 100%;
  width: 100%;
  max-width: 500px;
`;

export interface MatchParams {
  page: string;
}

interface Props {
  accountType: "brand" | "creator";
}

export const howTheyHeardAboutUs = [
  "Instagram",
  "TikTok",
  "LinkedIn",
  "Google/Bing etc.",
  "Podcast",
  "Friend/Colleague",
  "Joli Influencer",
  "Joli Partner",
  "Joli Team",
  "Other",
];

export const ConfirmDetails = (props: Props) => {
  const window = useWindowSize();

  return (
    <Wrap>
      {props.accountType === "brand" ? (
        <SignupHeader steps={{ currentStep: 1, totalSteps: 4 }} />
      ) : (
        <SignupHeader steps={{ currentStep: 1, totalSteps: 2 }} />
      )}
      <ContentWrap>
        <UserForm accountType={props.accountType} />
      </ContentWrap>
      {window.width &&
        window.width > MOBILE_BREAKPOINT &&
        props.accountType === "brand" && (
          <Steps
            steps={[
              {
                link: "/signup/b/confirm-details",
                status: "current",
                name: "Details",
              },
              {
                link: "/signup/b/describe-brand",
                status: "upcoming",
                name: "Describe brand",
              },
              {
                link: "/signup/b/create-location",
                status: "upcoming",
                name: "Locations",
              },
            ]}
          />
        )}
    </Wrap>
  );
};

const UserForm = (props: { accountType: "brand" | "creator" }) => {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [error, setError] = useState<string | null>(null);
  const { identifyUser } = useAnalytics();
  const { track } = useAnalytics();
  const q = new URLSearchParams(history.location.search);
  const ev = q.get("ev");
  const accountQuery = useAccountsQuery(
    client,
    {},
    {
      retry: false,
    }
  );

  const updateAccount = useUpdateAcccountMutation(client, {});

  const { isLoading, data, isError } = accountQuery;

  const [step, setStep] = useState(1);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    howTheyHeardAboutUs: [""],
    referredById: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  useEffect(() => {
    if (data?.account) {
      let storedReferralId = data.account.referredById || "";

      const localStorageReferralId = localStorage.getItem(
        config.referrals.localStorageKey
      );
      if (localStorageReferralId) {
        storedReferralId = localStorageReferralId;
      } else {
        // If not in localStorage, try cookies
        const cookieMatch = document.cookie.match(/joli_referral_id=([^;]+)/);
        if (cookieMatch && cookieMatch[1]) {
          storedReferralId = cookieMatch[1];
        }
      }

      let heardFrom = [""];

      if (
        data.account.howTheyHeardAboutUs &&
        data.account.howTheyHeardAboutUs.length > 0
      ) {
        heardFrom = data.account.howTheyHeardAboutUs;
      } else if (
        storedReferralId &&
        storedReferralId !== config.referrals.independent.id
      ) {
        heardFrom = ["Joli Influencer"];
      }

      const newFormData = {
        firstName: data.account.firstName || "",
        lastName: data.account.lastName || "",
        email: data.account.email || "",
        howTheyHeardAboutUs: heardFrom,
        referredById: storedReferralId,
      };
      setFormData(newFormData);
    }
  }, [data, ev]);

  useEffect(() => {
    if (ev) {
      toast.success("Email verified");
    }
  }, [ev]);

  if (isLoading) {
    return (
      <Wrap>
        <Loading defer />
      </Wrap>
    );
  }

  if (isError || !data) {
    return (
      <Wrap>
        <H3>Oops</H3>

        <Text margin={"0 0 xl 0"}>
          Couldn't load this profile, please try again later.
        </Text>
      </Wrap>
    );
  }

  const validateForm = () => {
    const newErrors = { firstName: "", lastName: "", email: "" };
    let isValid = true;

    if (!formData.firstName.trim()) {
      newErrors.firstName = "Please enter your first name";
      isValid = false;
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Please enter your last name";
      isValid = false;
    }
    if (!formData.email.trim()) {
      newErrors.email = "Please enter your email";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (step === 1) {
      setStep(2);
      return;
    }

    if (!validateForm()) return;

    const submissionData = {
      ...formData,
      email: formData.email || data?.account?.email || "",
      howTheyHeardAboutUs: formData.howTheyHeardAboutUs,
      referredById: formData.referredById,
    };

    updateAccount.mutate(submissionData, {
      onError: (e) => setError(e as string),
      onSuccess: (res) => {
        track("Account created");
        identifyUser(data.account.id, {
          email: res.updateAccount.email,
          name: `${res.updateAccount.firstName} ${res.updateAccount.lastName}`,
          howTheyHeardAboutUs: formData.howTheyHeardAboutUs,
        });
        queryClient
          .resetQueries(["Accounts"], {
            exact: false,
          })
          .then(() => {
            history.push(
              props.accountType === "brand"
                ? "/signup/b/create-brand"
                : "/signup/i/connect-socials?onboarding"
            );
          });
      },
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  return (
    <>
      {error && <Text>Error updating: {error}</Text>}

      <form onSubmit={onSubmit}>
        <SignupCard>
          {step !== 1 ? (
            <div>
              <H1 margin="0 0 l 0">How did you hear about us?</H1>
              <Subtitle margin="xs 0 s 0">Please pick any that apply:</Subtitle>
              <OptionsWrapper>
                {howTheyHeardAboutUs.map((b) => {
                  return (
                    <ToggleButton
                      key={b}
                      title={b}
                      active={formData.howTheyHeardAboutUs.includes(b)}
                      margin="0 0 s 0"
                      onClick={() => {
                        if (formData.howTheyHeardAboutUs.includes(b)) {
                          setFormData((prev) => ({
                            ...prev,
                            howTheyHeardAboutUs:
                              prev.howTheyHeardAboutUs.filter((e) => e !== b),
                          }));
                        } else {
                          setFormData((prev) => ({
                            ...prev,
                            howTheyHeardAboutUs: [
                              ...prev.howTheyHeardAboutUs,
                              b,
                            ],
                          }));
                        }
                      }}
                    />
                  );
                })}
              </OptionsWrapper>
              <Discount referredById={formData.referredById} />
            </div>
          ) : (
            <div>
              <H1 margin="0 0 l 0">Confirm your details</H1>
              <Input
                label="First Name"
                value={formData.firstName}
                name="firstName"
                onChange={handleInputChange}
                margin="0 0 xl 0"
                error={errors.firstName}
              />
              <Input
                label="Last Name"
                value={formData.lastName}
                name="lastName"
                onChange={handleInputChange}
                margin="0 0 xl 0"
                error={errors.lastName}
              />
              <Text weight="semi" margin="0 0 s">
                Email
              </Text>
              <div style={{ position: "relative" }}>
                {data.account.email && data.account.emailVerifiedAt && (
                  <AbsoluteWrap>
                    <CheckCircle checked={true} />
                  </AbsoluteWrap>
                )}
                <Input
                  margin="0 0 s 0"
                  isDisabled={
                    data.account.email && data.account.emailVerifiedAt
                      ? true
                      : false
                  }
                  onChange={handleInputChange}
                  value={formData.email}
                  name="email"
                  error={errors.email}
                />
              </div>
            </div>
          )}
          <Submit
            margin="xl 0 0 0"
            type="submit"
            value={
              updateAccount.isLoading || updateAccount.isSuccess
                ? "Saving..."
                : "Next"
            }
          />
        </SignupCard>
      </form>
    </>
  );
};

const AbsoluteWrap = styled.div`
  position: absolute;
  top: 0;
  right: ${(p) => p.theme.spacing.m};
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
`;

const OptionsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${(p) => p.theme.spacing.s};
`;

const Discount = ({ referredById }: { referredById: string | null }) => {
  if (!referredById) {
    return null;
  }

  if (referredById === config.referrals.independent.id) {
    return (
      <View margin="m 0 0 0">
        <Callout
          type="success"
          header="Discount Activated"
          text={`Your ${config.referrals.independent.discount}% independent discount has been automatically applied`}
        />
      </View>
    );
  }

  return (
    <View margin="m 0 0 0">
      <Callout
        type="success"
        header="Discount Activated"
        text={`Your ${config.referrals.friend.discount}% discount has been automatically applied via @${referredById}`}
      />
    </View>
  );
};
