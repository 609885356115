import { useEffect, useRef, useState } from "react";
import { MediaType } from "../graphql/generated";
import styled from "../styles";
import { PauseIcon } from "./icons/PauseIcon";
import { PlayIcon } from "./icons/PlayIcon";

export interface CarouselItem {
  type: MediaType;
  url: string;
}

interface CarouselProps {
  items: CarouselItem[];
}

export const Carousel = ({ items }: CarouselProps) => {
  const videoRefs = useRef<{ [key: number]: HTMLVideoElement | null }>({});
  const [playingVideo, setPlayingVideo] = useState<number | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const handleScroll = () => {
      const scrollPosition = container.scrollLeft;
      const itemWidth = container.clientWidth;
      const newIndex = Math.min(
        Math.max(0, Math.round(scrollPosition / itemWidth)),
        items.length - 1
      );

      if (newIndex !== currentIndex) {
        setCurrentIndex(newIndex);
        // Pause video if we scroll away from it
        if (playingVideo !== null) {
          const videoElement = videoRefs.current[playingVideo];
          if (videoElement) {
            videoElement.pause();
            setPlayingVideo(null);
          }
        }
      }
    };

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [playingVideo, currentIndex, items.length]);

  const handleVideoToggle = (index: number) => {
    const videoElement = videoRefs.current[index];
    if (!videoElement) return;

    if (playingVideo === index) {
      videoElement.pause();
      setPlayingVideo(null);
    } else {
      // Pause any currently playing video
      if (playingVideo !== null) {
        const currentVideo = videoRefs.current[playingVideo];
        if (currentVideo) {
          currentVideo.pause();
        }
      }

      videoElement.play();
      setPlayingVideo(index);
    }
  };

  const handleIndicatorClick = (index: number) => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const itemWidth = container.clientWidth;
    container.scrollTo({
      left: index * itemWidth,
      behavior: "smooth",
    });
    setCurrentIndex(index);

    // Pause video if we navigate away from it
    if (playingVideo !== null) {
      const videoElement = videoRefs.current[playingVideo];
      if (videoElement) {
        videoElement.pause();
        setPlayingVideo(null);
      }
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {items.length > 0 ? (
        <ImageScrollContainer ref={scrollContainerRef}>
          {items.map((item, index) =>
            item.type === MediaType.Video ? (
              <VideoWrapper key={index} isPlaying={playingVideo === index}>
                <PlayButtonWrap onClick={() => handleVideoToggle(index)}>
                  {playingVideo === index ? (
                    <PauseIcon width={38} height={38} />
                  ) : (
                    <PlayIcon width={38} height={38} />
                  )}
                </PlayButtonWrap>
                <Video
                  isPlaying={playingVideo === index}
                  muted
                  ref={(el) => (videoRefs.current[index] = el)}
                  src={item.url}
                  onClick={() => handleVideoToggle(index)}
                  onEnded={() => {
                    const videoElement = videoRefs.current[index];
                    if (!videoElement) return;
                    setPlayingVideo(null);
                    videoElement.currentTime = 0;
                  }}
                />
              </VideoWrapper>
            ) : (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <ImageWrap>
                  <Image src={item.url} alt="" />
                </ImageWrap>
              </div>
            )
          )}
          {items.length > 1 && (
            <IndicatorWrapper>
              {items.map((_, index) => (
                <Indicator
                  key={index}
                  active={index === currentIndex}
                  onClick={() => handleIndicatorClick(index)}
                />
              ))}
            </IndicatorWrapper>
          )}
        </ImageScrollContainer>
      ) : null}
    </div>
  );
};

const VideoWrapper = styled.div<{ isPlaying: boolean | null }>`
  position: relative;
  width: 100%;
  max-width: ${(props) => (props.isPlaying ? "300px" : "inherit")};
  margin: 0 auto;
  aspect-ratio: ${(props) => (props.isPlaying ? "unset" : "328/214")};
  height: ${(props) => (props.isPlaying ? "100vh" : "auto")};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  transition: all 0.3s ease-in-out;
`;

const Video = styled.video<{ isPlaying: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: ${(props) => (props.isPlaying ? "contain" : "cover")};
  cursor: pointer;
  transition: object-fit 0.3s ease-in-out;
`;

const PlayButtonWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  transition: all 150ms linear;
  border-radius: ${(p) => p.theme.misc.borderRadius};
`;

const ImageScrollContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin: 0 auto;
  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    flex: 0 0 100%;
    scroll-snap-align: start;
    max-width: none;
  }
`;

const IndicatorWrapper = styled.div`
  position: absolute;
  bottom: ${(p) => p.theme.spacing.m};
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const Indicator = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(p) =>
    p.active ? "#FFFFFF" : "rgba(255, 255, 255, 0.5)"};
  margin: 0 ${(p) => p.theme.spacing.xxs};
  transition: background-color 0.2s ease;
  cursor: pointer;
`;

const ImageWrap = styled.div<{ width?: number }>`
  position: relative;
  scroll-snap-align: center;
  min-width: 100%;
  overflow: hidden;
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;
