import {
  addDays,
  format,
  formatDistanceToNowStrict,
  fromUnixTime,
} from "date-fns";
import { useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { toast } from "sonner";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../config";
import {
  BookingEventPerformerType,
  BookingEventType,
  BookingStatus,
  GetBookingQuery,
  ListingType,
  useRespondToConversationMutation,
} from "../../../graphql/generated";
import useGqlClient from "../../../hooks/useGqlClient";
import { css, ThemeProp } from "../../../styles";
import { demoBookingConfig } from "../../../utils/demoBookingConfig";
import { renderBookingPostType } from "../../../utils/enums";
import { formatDateTime } from "../../../utils/formatTime";
import Avatar from "../../Avatar";
import { Chevron } from "../../Chevron";
import { Flex } from "../../Flex";
import { ApplyIcon } from "../../icons/ApplyIcon";
import { ApproveIcon } from "../../icons/ApprovedIcon";
import { RescheduleIcon } from "../../icons/RescheduleIcon";
import { SendIcon } from "../../icons/SendIcon";
import Loader from "../../Loader";
import { Text } from "../../Text";
import { TextLink } from "../../TextLink";
import { View } from "../../View";

const Timeline = styled.div<{ expanded: boolean; lastChildPadding: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${(p: ThemeProp) => p.theme.spacing.m};
  padding-left: 3px;
  padding-top: 2px;
  max-height: ${(p: ThemeProp & { expanded: boolean }) =>
    p.expanded ? "auto" : "96px"};
  overflow: hidden;

  &:last-child {
    padding-bottom: ${(
      p: ThemeProp & {
        lastChildPadding: boolean;
        expanded: boolean;
      }
    ) =>
      !p.expanded && p.lastChildPadding
        ? p.theme.spacing.xl
        : p.theme.spacing.s};
  }
`;

const TimelineItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const TimelineConnector = styled.div<{ isLast: boolean }>`
  position: absolute;
  left: 0px;
  top: -4px;
  bottom: ${(props) => (props.isLast ? "0" : "-16px")};
  width: 1px;
  background-color: ${(p: ThemeProp) => p.theme.color.button.secondaryBorder};
`;

const TimelineIconWrapper = styled.div`
  position: absolute;
  left: -6px;
  top: 6px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

const TimelineTextWrapper = styled(Flex)`
  margin-left: ${(p: ThemeProp) => p.theme.spacing.m};
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: ${(p: ThemeProp) => p.theme.spacing.m};
    flex-direction: column-reverse;
  }
`;

const DateText = styled(Text)`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 0px;
  }
`;

const TimelineDot = styled.div`
  width: 7px;
  height: 7px;
  background-color: ${(p: ThemeProp) => p.theme.color.button.secondaryBorder};
  border-radius: 50%;
  border: 3px solid ${(p: ThemeProp) => p.theme.color.card.callout};
`;

function getPerformerName(
  be: GetBookingQuery["booking"]["bookingEvents"][0],
  booking: GetBookingQuery["booking"]
) {
  if (be.performedByType === BookingEventPerformerType.Internal) {
    return "Joli (Team)";
  }

  if (be.performedByType === BookingEventPerformerType.Automatic) {
    return "Joli (Automated)";
  }

  if (be.type === BookingEventType.InstagramMessageSent) {
    if (be.performedByType === BookingEventPerformerType.Creator) {
      if (booking.creator.instagram) {
        return `@${booking.creator.instagram.username}`;
      }

      return null;
    }

    if (!booking.listing.brand.instagramHandle) {
      return null;
    }

    return `@${booking.listing.brand.instagramHandle}`;
  }

  if (!be.performedBy) {
    return null;
  }

  if (be.performedByType === BookingEventPerformerType.Creator) {
    return `${be.performedBy.firstName} (@${booking.creator.preferredProfile?.username})`;
  }

  if (be.performedBy.email) {
    return `${be.performedBy.firstName} ${be.performedBy.lastName} (${be.performedBy.email})`;
  }

  return `${be.performedBy.firstName} ${be.performedBy.lastName}`;
}

export const BookingTimeline = ({
  booking,
  supportOldTimeline,
  demoMode,
}: {
  booking: GetBookingQuery["booking"];
  supportOldTimeline?: boolean;
  demoMode?: boolean;
}) => {
  const [expanded, setExpanded] = useState(false);

  let events = booking.bookingEvents;

  if (demoMode) {
    const demoEvents = demoBookingConfig
      .find((brand) => brand.id === booking.listing.brand.id)
      ?.bookings.find((b) => b.id === booking.id)?.events;

    if (demoEvents) {
      events = demoEvents;
    }
  }

  if (!events || events.length === 0) {
    if (!supportOldTimeline) {
      return null;
    }

    return <BookingHistoryOld booking={booking} />;
  }

  return (
    <TimeLineWrap key={booking.id}>
      <Timeline
        expanded={expanded || events.length <= 3}
        lastChildPadding={events.length > 3}
      >
        {events.map((be, idx) => {
          const performerName = getPerformerName(be, booking);

          const lastCreatorMessage = events.filter(
            (event) =>
              event.type === BookingEventType.InstagramMessageSent &&
              event.performedByType === BookingEventPerformerType.Creator &&
              !!event.conversationId
          );

          return (
            <TimelineItem key={be.timestamp}>
              <TimelineConnector isLast={idx === events.length - 1} />
              <TimelineIconWrapper>
                <TimelineDot />
              </TimelineIconWrapper>
              <TimelineTextWrapper>
                <div style={{ width: "100%" }}>
                  <BookingEventText
                    performerName={performerName}
                    bookingEvent={
                      be as GetBookingQuery["booking"]["bookingEvents"][0]
                    }
                    booking={booking}
                    index={idx}
                  />
                  {lastCreatorMessage[lastCreatorMessage.length - 1]?.id ===
                    be.id &&
                    be.conversationId &&
                    booking.creator.instagram &&
                    booking.listing.brand.instagram && (
                      <View margin="0 0 xs 0">
                        <InstagramReplySection
                          senderAvatar={
                            booking.listing.brand.instagram.avatar || null
                          }
                          recipient={booking.creator.instagram.username}
                          conversationId={be.conversationId}
                        />
                      </View>
                    )}
                </div>

                <DateText
                  margin="0 0 0 s"
                  size="xxs"
                  colorPreset="secondary"
                  weight="semi"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {formatDateTime(fromUnixTime(be.timestamp))}
                </DateText>
              </TimelineTextWrapper>
            </TimelineItem>
          );
        })}
        {events.length > 3 ? (
          <AbsoluteWrap
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          >
            <div>
              <Chevron
                className="expand-timeline"
                direction={expanded ? "up" : "down"}
                animateTransition
                width={11}
                height={11}
              />
            </div>
          </AbsoluteWrap>
        ) : null}
      </Timeline>
    </TimeLineWrap>
  );
};

const AbsoluteWrap = styled.div<{ expanded: boolean }>`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0px;
  padding-bottom: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  ${(p) =>
    p.expanded
      ? css`
          height: 15px;
          background: transparent;
        `
      : css`
          height: 45px;
          background: linear-gradient(
            to top,
            ${(p: ThemeProp) => p.theme.color.card.callout},
            ${(p: ThemeProp) => `${p.theme.color.card.callout}fa`} 10%,
            ${(p: ThemeProp) => `${p.theme.color.card.callout}d4`} 70%,
            transparent
          );
        `}

  border-radius: ${(p: ThemeProp) => p.theme.misc.borderRadius};

  .expand-timeline {
    path {
      fill: ${(p: ThemeProp) => p.theme.color.typography.secondary};
      transition: fill 0.1s ease-in-out;
    }
  }

  :hover {
    .expand-timeline {
      path {
        fill: ${(p: ThemeProp) => p.theme.color.typography.heading};
      }
    }
  }
`;

interface BookingEventTextProps {
  performerName: string | null;
  bookingEvent: GetBookingQuery["booking"]["bookingEvents"][0];
  booking: GetBookingQuery["booking"];
  index: number;
}

const BookingEventText = ({
  performerName,
  bookingEvent,
  booking,
  index,
}: BookingEventTextProps) => {
  switch (bookingEvent.type) {
    case BookingEventType.Applied:
      return (
        <div>
          <Text size="xs" margin="0" isCompact weight="semi">
            Applied for{" "}
            <TextLink
              weight="semi"
              textDecoration="underline"
              colorPreset="text"
              isInline
              to={`/b/listings/${booking.listing.id}`}
              size="xs"
            >
              {booking.listing.name.length > 45
                ? `${booking.listing.name.substring(0, 45)}...`
                : booking.listing.name}
            </TextLink>
            {booking.location ? <> · {booking.location.name}</> : ""}
          </Text>
          {performerName ? (
            <Text size="xxs" margin="0" colorPreset="secondary">
              {performerName}
            </Text>
          ) : null}
        </div>
      );
    case BookingEventType.Approved:
      if (booking.type === ListingType.RedeemAnytime) {
        return (
          <div>
            <Text size="xs" margin="0" isCompact>
              <SemiText>Booking Approved</SemiText> to be redeemed{" "}
              {booking.approvedAt && (
                <SemiText>
                  anytime before{" "}
                  {format(
                    addDays(fromUnixTime(booking.approvedAt), 30),
                    "do MMMM yyyy"
                  )}
                </SemiText>
              )}
              {!bookingEvent.performedBy
                ? "based on the listing approval settings"
                : ""}
            </Text>
            {performerName ? (
              <Text size="xxs" margin="0" colorPreset="secondary">
                {performerName}
              </Text>
            ) : null}
          </div>
        );
      }

      let approvedTitle = "Booking Approved";

      if (bookingEvent.approvedDate) {
        approvedTitle =
          "Booking Approved for " +
          format(
            fromUnixTime(bookingEvent.approvedDate),
            "h:mmaaaaa'm' EEEE do MMMM"
          );

        if (!bookingEvent.performedBy) {
          approvedTitle += " based on the listing approval settings";
        }
      }

      return (
        <div>
          <BookingEventWithDescription
            title={approvedTitle}
            description={bookingEvent.description}
            performerName={performerName}
          />
        </div>
      );
    case BookingEventType.Rejected:
      return (
        <BookingEventWithDescription
          title="Declined"
          description={bookingEvent.description}
          performerName={performerName}
        />
      );
    case BookingEventType.Cancelled:
      return (
        <BookingEventWithDescription
          title="Cancelled"
          description={bookingEvent.description}
          performerName={performerName}
        />
      );
    case BookingEventType.Rescheduled:
      let rescheduledTitle = "Rescheduled";

      if (bookingEvent.approvedDate) {
        rescheduledTitle =
          "Booking Rescheduled for " +
          format(
            fromUnixTime(bookingEvent.approvedDate),
            "h:mmaaaaa'm' EEEE do MMMM"
          );

        if (!bookingEvent.performedBy) {
          rescheduledTitle += " based on the listing approval settings";
        }
      }

      return (
        <BookingEventWithDescription
          title={rescheduledTitle}
          description={bookingEvent.description}
          performerName={performerName}
        />
      );
    case BookingEventType.RescheduleRequested:
      return (
        <BookingEventWithDescription
          title="Reschedule Requested"
          description={bookingEvent.description}
          performerName={performerName}
        />
      );
    case BookingEventType.Redeemed:
      return (
        <div>
          <Text size="xs" margin="0" isCompact weight="semi">
            Redeemed
          </Text>
          {performerName ? (
            <Text size="xxs" margin="0" colorPreset="secondary">
              {performerName}
            </Text>
          ) : null}
        </div>
      );
    case BookingEventType.NoShow:
      return (
        <BookingEventWithDescription
          title="No Show"
          description="Influencer behaviour is actively monitored to reduce future no shows"
          noQuotes
          performerName={performerName}
        />
      );
    case BookingEventType.Expired:
      return (
        <div>
          <Text size="xs" margin="0" isCompact weight="semi">
            Application Expired
          </Text>
        </div>
      );
    case BookingEventType.ApplicationExpired:
      return (
        <div>
          <Text size="xs" margin="0" isCompact weight="semi">
            Application Expired
          </Text>
        </div>
      );
    case BookingEventType.BookingExpired:
      return (
        <div>
          <Text size="xs" margin="0" isCompact weight="semi">
            Booking Expired
          </Text>
        </div>
      );
    case BookingEventType.LateCancellation:
      return (
        <BookingEventWithDescription
          title="Late Notice Cancellation"
          description="Influencer behaviour is actively monitored to reduce last-minute changes"
          noQuotes
          performerName={performerName}
        />
      );
    case BookingEventType.LateReschedule:
      return (
        <BookingEventWithDescription
          title="Late Notice Reschedule"
          description="Influencer behaviour is actively monitored to reduce last-minute changes"
          noQuotes
          performerName={performerName}
        />
      );
    case BookingEventType.Completed:
      return (
        <div>
          <Text size="xs" margin="0" isCompact weight="semi">
            Booking Completed
          </Text>
        </div>
      );
    case BookingEventType.CreatorFeedbackSubmitted:
      return (
        <BookingEventWithDescription
          title="Feedback Submitted"
          description={bookingEvent.description}
          performerName={performerName}
        />
      );
    case BookingEventType.ContentUploaded:
      return (
        <div>
          <Text size="xs" margin="0" isCompact weight="semi">
            {bookingEvent.bookingPost ? (
              <TextLink
                weight="semi"
                textDecoration="underline"
                colorPreset="text"
                isInline
                to={`/b/reports/${bookingEvent.bookingPost.id}`}
                size="xs"
              >
                {renderBookingPostType(bookingEvent.bookingPost?.postType)}
              </TextLink>
            ) : (
              "Content"
            )}{" "}
            Uploaded
          </Text>
          {performerName ? (
            <Text size="xxs" margin="0" colorPreset="secondary">
              {performerName}
            </Text>
          ) : null}
        </div>
      );
    case BookingEventType.Milestone:
      return (
        <div>
          <Text size="xs" margin="0" isCompact weight="semi">
            {bookingEvent.bookingPost ? (
              <TextLink
                weight="semi"
                textDecoration="underline"
                colorPreset="text"
                isInline
                to={`/b/reports/${bookingEvent.bookingPost.id}`}
                size="xs"
              >
                {renderBookingPostType(bookingEvent.bookingPost?.postType)}
              </TextLink>
            ) : (
              "Content"
            )}
            {" - "}
            {bookingEvent.description}
          </Text>
        </div>
      );
    case BookingEventType.CreatorConfirmedAttendance:
      return (
        <BookingEventWithDescription
          title="Attendance Confirmed"
          description={
            bookingEvent.approvedDate
              ? `They confirmed their booking for ${format(
                  fromUnixTime(bookingEvent.approvedDate),
                  "h:mmaaaaa'm' EEEE do MMMM"
                )}`
              : undefined
          }
          noQuotes
          performerName={performerName}
        />
      );
    case BookingEventType.CreatorContactedVenue:
      return (
        <BookingEventWithDescription
          title="Venue Contacted"
          description="They confirmed they contacted the venue directly, as the booking was within 24 hours"
          noQuotes
          performerName={performerName}
        />
      );
    case BookingEventType.IssueReported:
      return (
        <BookingEventWithDescription
          title="Issue Reported"
          description={bookingEvent.description}
          performerName={performerName}
        />
      );
    case BookingEventType.IssueResponse:
      return (
        <BookingEventWithDescription
          title="Issue Update"
          description={bookingEvent.description}
          performerName={performerName}
        />
      );
    case BookingEventType.ContentOverdueMessageSent:
      return (
        <BookingEventWithDescription
          title="Content Overdue Message Sent"
          description={
            "Manual follow-up message from The Joli Team regarding overdue content"
          }
          performerName={performerName}
        />
      );
    case BookingEventType.AutomatedContentReminderSent:
      return (
        <BookingEventWithDescription
          title="Automated Content Reminder"
          description={
            "An automated push notification was sent to the influencer"
          }
          performerName={performerName}
        />
      );
    case BookingEventType.ToggleVoucherCreated:
      return (
        <BookingEventWithDescription
          title="Toggle Voucher Created"
          noQuotes
          description={
            bookingEvent.description
              ? `ID: ${bookingEvent.description}`
              : undefined
          }
          performerName={performerName}
        />
      );
    case BookingEventType.ToggleVoucherCancelled:
      return (
        <BookingEventWithDescription
          title="Toggle Voucher Cancelled"
          noQuotes
          description={
            bookingEvent.description
              ? `ID: ${bookingEvent.description}`
              : undefined
          }
          performerName={performerName}
        />
      );
    case BookingEventType.InstagramMessageSent: {
      return (
        <div style={{ flex: 1 }}>
          <BookingEventWithDescription
            title="Instagram Message Sent"
            noQuotes
            description={
              bookingEvent.description
                ? `"${bookingEvent.description}"`
                : undefined
            }
            performerName={performerName}
          />
        </div>
      );
    }
    case BookingEventType.AccessCollinsBookingCreated:
      return (
        <BookingEventWithDescription
          title="Collins Booking Created"
          noQuotes
          description={
            bookingEvent.description
              ? `ID: ${bookingEvent.description}`
              : undefined
          }
          performerName={performerName}
        />
      );
    case BookingEventType.AccessCollinsBookingUpdated:
      return (
        <BookingEventWithDescription
          title="Collins Booking Updated"
          noQuotes
          description={
            bookingEvent.description
              ? `ID: ${bookingEvent.description}`
              : undefined
          }
          performerName={performerName}
        />
      );
    case BookingEventType.AccessCollinsBookingCancelled:
      return (
        <BookingEventWithDescription
          title="Collins Booking Cancelled"
          noQuotes
          description={
            bookingEvent.description
              ? `ID: ${bookingEvent.description}`
              : undefined
          }
          performerName={performerName}
        />
      );
    case BookingEventType.ExpectedPostDateSet:
      return (
        <BookingEventWithDescription
          title={
            bookingEvent.approvedDate
              ? `Expected Post Date Set for ${format(
                  fromUnixTime(bookingEvent.approvedDate),
                  "h:mmaaaaa'm' EEEE do MMMM"
                )}`
              : "Expected Post Date Set"
          }
          noQuotes
          performerName={performerName}
        />
      );
    default:
      return null;
  }
};

const TimeLineWrap = styled(View)`
  background-color: ${(p) => p.theme.color.card.callout};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.l} ${(p) => p.theme.spacing.xl};
  margin: ${(p) => p.margin || "0"};
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
  }
  position: relative;
  padding-bottom: 8px;
`;

const CardCallout = styled(View)`
  background-color: ${(p) => p.theme.color.card.callout};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.l} ${(p) => p.theme.spacing.xl};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
  }
`;

const BookingHistoryOld = ({
  booking,
}: {
  booking: GetBookingQuery["booking"];
}) => {
  const { firstName = "", lastName = "" } = booking.approvedBy || {};

  const approvedBy = booking.approvedBy
    ? [firstName.trim(), lastName.trim()].filter(Boolean).join(" ")
    : "Joli";

  return (
    <View>
      <CardCallout>
        <Flex align="center">
          <Flex align="center" justify="center" margin="0 l 0 0">
            <ApplyIcon colorPreset="secondary" width={26} />
          </Flex>
          <View>
            <Text margin="0" size="s">
              Applied to your{" "}
              <span style={{ fontWeight: 600 }}>{booking.listing.name}</span>{" "}
              listing{" "}
              {booking.location ? (
                <>
                  at{" "}
                  <span style={{ fontWeight: 600 }}>
                    {booking.location.name}
                  </span>
                </>
              ) : null}
            </Text>
            <Text margin="0" size="xs" colorPreset="secondary" isCompact>
              {formatDistanceToNowStrict(fromUnixTime(booking.updatedAt), {
                addSuffix: true,
              })}
            </Text>
          </View>
        </Flex>
        {booking.approvedAt &&
        booking.status !== BookingStatus.BookingStatusPending ? (
          <Flex align="center">
            <Flex align="center" justify="center" margin="0 l 0 s">
              <ApproveIcon colorPreset="secondary" width={26} />
            </Flex>
            <View margin="m 0 0">
              {booking.status ===
              BookingStatus.BookingStatusCreatorRescheduled ? (
                <Text margin="0 0 0" size="s">
                  <span style={{ fontWeight: 600 }}>{approvedBy} </span>{" "}
                  approved the booking on{" "}
                  <span style={{ fontWeight: 600 }}>
                    {format(fromUnixTime(booking.approvedAt), "EEEE do MMMM")}
                  </span>
                </Text>
              ) : (
                <Text margin="0 0 0" size="s">
                  <span style={{ fontWeight: 600 }}>{approvedBy} </span>{" "}
                  approved the booking {booking.confirmedTimeslot ? "for " : ""}
                  {booking.confirmedTimeslot ? (
                    <span style={{ fontWeight: 600 }}>
                      {format(
                        fromUnixTime(booking.confirmedTimeslot?.date),
                        "h:mmaaaaa'm' EEEE do MMMM"
                      )}
                    </span>
                  ) : (
                    <span>
                      to be redeemed anytime before{" "}
                      <span style={{ fontWeight: 600 }}>
                        {format(
                          addDays(fromUnixTime(booking.approvedAt), 30),
                          "do MMMM yyyy"
                        )}
                      </span>
                    </span>
                  )}
                  {!booking.approvedBy
                    ? "based on the listing approval settings"
                    : ""}
                </Text>
              )}
              <Text margin="0" size="xs" colorPreset="secondary" isCompact>
                {formatDistanceToNowStrict(fromUnixTime(booking.approvedAt), {
                  addSuffix: true,
                })}
              </Text>
            </View>
          </Flex>
        ) : null}
      </CardCallout>
      {booking.approvedAt &&
      booking.status === BookingStatus.BookingStatusCreatorRescheduled ? (
        <View margin="m 0 0">
          <RescheduleCallout>
            <Flex align="center">
              <Flex align="center" justify="center" margin="0 l 0 xs">
                <RescheduleIcon width={26} />
              </Flex>
              <View margin="0">
                <Text margin="0 0 0" size="s">
                  They've already been approved but have requested to reschedule
                </Text>
              </View>
            </Flex>
          </RescheduleCallout>
        </View>
      ) : null}
    </View>
  );
};

const BookingEventWithDescription = ({
  title,
  description,
  performerName,
  noQuotes,
}: {
  title: string;
  description?: string;
  noQuotes?: boolean;
  performerName?: string | null;
}) => {
  const [showDescription, setShowDescription] = useState(false);
  return (
    <div>
      <Text
        margin="0"
        size="xs"
        weight="semi"
        isCompact
        style={{
          userSelect: "none",
          cursor: description ? "pointer" : "default",
        }}
        onClick={
          description ? () => setShowDescription(!showDescription) : undefined
        }
      >
        {title}{" "}
        {description ? (
          <Chevron
            width={9}
            height={9}
            direction={showDescription ? "down" : undefined}
            animateTransition
          />
        ) : null}
      </Text>
      {description && showDescription ? (
        <Text size="xs" margin="0">
          {noQuotes ? description?.trimEnd() : `"${description?.trimEnd()}"`}
        </Text>
      ) : null}
      {performerName ? (
        <Text size="xxs" margin="0" colorPreset="secondary">
          {performerName}
        </Text>
      ) : null}
    </div>
  );
};

const RescheduleCallout = styled(View)`
  background-color: ${(p) => p.theme.color.input.hover};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.xl};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
  }
`;

const SemiText = styled.span`
  font-weight: ${(p) => p.theme.typography.weight.semi};
`;
const InstagramReplySection = ({
  conversationId,
  recipient,
  senderAvatar,
}: {
  conversationId: string;
  recipient: string;
  senderAvatar: string | null;
}) => {
  const [isActive, setIsActive] = useState(false);
  const [replyText, setReplyText] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const client = useGqlClient();
  const respondToMessageMutation = useRespondToConversationMutation(client);
  const queryClient = useQueryClient();

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "28px"; // Reset height to min
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleTextAreaInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReplyText(e.target.value);
    if (isActive) {
      adjustHeight();
    }
  };

  const handleFocus = () => {
    setIsActive(true);
    // Small delay to ensure smooth transition
    setTimeout(adjustHeight, 0);
  };

  const handleBlur = () => {
    if (!replyText.trim()) {
      setIsActive(false);
    }
  };

  const handleSend = () => {
    respondToMessageMutation.mutate(
      {
        conversationId,
        message: replyText,
      },
      {
        onSuccess: () => {
          toast.success("Message sent");
          queryClient.invalidateQueries(["getBooking"], {
            exact: false,
          });
          setReplyText("");
          setIsActive(false);
        },
        onError: (error) => {
          console.error(error);
          toast.error("Failed to send message");
        },
      }
    );
  };

  return (
    <ReplyWrap isActive={isActive} direction="column" justify="space-between">
      <Flex align="flex-start">
        {senderAvatar ? (
          <Flex margin="xs s 0 0" align="center" justify="center">
            <Avatar
              borderSize={0}
              withShadow={false}
              url={senderAvatar}
              size={18}
            />
          </Flex>
        ) : null}
        <View style={{ width: "100%", marginTop: 5 }}>
          <StyledArea
            ref={textareaRef}
            value={replyText}
            onChange={handleTextAreaInput}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={`Reply to @${recipient}...`}
            isActive={isActive}
          />
        </View>
      </Flex>
      <ReplyButton onClick={handleSend} isActive={isActive}>
        <Flex align="center" justify="center">
          {respondToMessageMutation.isLoading ? (
            <Loader height={22} width={22} fill="#fff" />
          ) : (
            <SendIcon height={20} width={20} />
          )}
        </Flex>
      </ReplyButton>
    </ReplyWrap>
  );
};

const ReplyWrap = styled(Flex)<{ isActive: boolean }>`
  padding: ${(p: ThemeProp) => p.theme.spacing.xs}
    ${(p: ThemeProp) => p.theme.spacing.s};
  border: 1px solid ${(p: ThemeProp) => p.theme.color.typography.secondary}50;
  border-radius: ${(p: ThemeProp) => p.theme.misc.borderRadiusXSmall};
  min-width: 100%;
  box-sizing: border-box;
  background-color: ${(p: ThemeProp) => p.theme.color.card.background};
  transition: all 0.2s ease-in-out;
  width: 100%;
  overflow: hidden;
  margin-top: ${(p: ThemeProp) => p.theme.spacing.s};
  height: ${(p) => (p.isActive ? "auto" : "38px")} !important;

  ${(p) =>
    p.isActive &&
    css`
      border-color: ${p.theme.color.primary};
      box-shadow: 0px 0px 0px 2px ${p.theme.color.input.hover};
    `}

  &:hover {
    border-color: ${(p: ThemeProp) => p.theme.color.primary};
    box-shadow: 0px 0px 0px 2px ${(p: ThemeProp) => p.theme.color.input.hover};
  }
`;

const StyledArea = styled.textarea<{ isActive: boolean }>`
  border: none;
  font-size: ${(p) => p.theme.typography.size.xs};
  line-height: 140%;
  resize: none;
  white-space: pre-line;
  width: 100%;
  overflow: hidden;
  color: ${(p) => p.theme.color.typography.text};
  background: transparent;
  padding: 0;
  margin: 0;
  margin-bottom: ${(p) => (p.isActive ? p.theme.spacing.s : 0)};
  height: ${(p) => (p.isActive ? "auto" : "24px")};

  &:focus {
    outline: none;
  }
`;

const ReplyButton = styled.button<{ isActive: boolean }>`
  margin-left: auto;
  max-width: 80px;
  width: 62px;
  height: 30px;
  font-size: ${(p) => p.theme.typography.size.xxs};
  font-weight: ${(p) => p.theme.typography.weight.bold};
  box-sizing: border-box;
  background-color: ${(p) => p.theme.color.primary};
  border: none;
  border-radius: ${(p) => p.theme.misc.borderRadiusXSmall};
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  outline: none !important;
  font-family: ${(p) => p.theme.typography.bodyFamily};
  margin-bottom: ${(p) => (p.isActive ? p.theme.spacing.xs : 0)};
  opacity: ${(p) => (p.isActive ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;

  &:hover {
    box-shadow: none;
    filter: brightness(0.95);
  }
`;
