import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "../hooks/useWindowSize";
import styled, { css } from "../styles";

const INSET_SPACING = 3;

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  user-select: none;

  & * {
    z-index: 2;
  }

  padding: 0 ${() => INSET_SPACING}px;

  background-color: ${(p) => p.theme.color.typography.secondary}25;
  border-radius: ${(p: { size?: string }) =>
    p.size === "l" ? "24px" : "20px"};
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`;

const Puck = styled.div`
  background: white;
  position: absolute;
  top: ${INSET_SPACING}px;
  bottom: ${INSET_SPACING}px;
  opacity: 1;
  transition: transform 200ms cubic-bezier(0.76, 0, 0.24, 1), width 200ms linear;
  border-radius: ${(p: { size?: string }) =>
    p.size === "l" ? "24px" : "20px"};
  background: #fdfdff;
`;

const Toggle = styled.div<{
  isDisabled?: boolean;
  active?: boolean;
  size?: string;
}>`
  font-weight: 600;
  font-size: ${(p: { size?: string }) => (p.size === "l" ? "14px" : "12px")};
  height: ${(p: { size?: string }) => (p.size === "l" ? "38px" : "28px")};
  position: relative;
  flex: 1;
  justify-content: center;
  align-items: center;

  display: flex;

  transition: all 200ms linear;

  cursor: pointer;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `}

  ${(p: { active?: boolean; size?: string }) =>
    p.active
      ? css`
          color: #000;
        `
      : css`
          color: ${(p) => p.theme.color.typography.secondary};
        `}
`;

const NotificationCount = styled.span`
  background-color: #6c45c6;
  border-radius: 999px;
  position: absolute;
  right: ${(p) => p.theme.spacing.s};
  padding: 0 ${(p) => p.theme.spacing.s};
  font-size: ${(p) => p.theme.typography.size.xs};

  ${(p: { active?: boolean }) =>
    p.active
      ? css`
          background-color: ${(p) => p.theme.color.additional.darkPurple}25;
          color: #000;
        `
      : css`
          background-color: ${(p) => p.theme.color.additional.darkPurple};
          color: #fff;
        `}
`;

export type SegmentedControlProps<T> = {
  value: T;
  options: SegmentOptions<T>[];
  onChange: (value: T) => void;
  className?: any;
  size?: "default" | "l";
};

export type SegmentOptions<T> = {
  label: string;
  value: T;
  count?: number;
  isDisabled?: boolean;
};

export function SegmentedControl<T>(props: SegmentedControlProps<T>) {
  const activeRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [x, setX] = useState(0);
  const [width, setWidth] = useState(0);
  const size = useWindowSize();
  const { size: controlSize = "default" } = props;

  function reposition() {
    if (!activeRef.current || !wrapperRef.current) {
      return;
    }

    const wrapperRect = wrapperRef.current.getBoundingClientRect();
    const activeRect = activeRef.current.getBoundingClientRect();

    setX(activeRect.left - wrapperRect.left);
    setWidth(activeRect.width);
  }

  useEffect(() => {
    requestAnimationFrame(() => {
      reposition();
    });
  }, [props.value, size]);

  return (
    <Container className={props.className} size={controlSize}>
      <Wrapper ref={wrapperRef}>
        <Puck
          style={{
            transform: `translate3d(${x}px, 0, 0)`,
            width,
          }}
          size={controlSize}
        />
        {props.options.map((d) => {
          const isActive = d.value === props.value;
          return (
            <Toggle
              ref={isActive ? activeRef : null}
              active={isActive}
              onClick={(e) => !d.isDisabled && props.onChange(d.value)}
              key={d.label + d.value}
              size={controlSize}
              isDisabled={d.isDisabled}
            >
              {d.label}
              {d.count && (
                <NotificationCount active={isActive}>
                  {d.count}
                </NotificationCount>
              )}
            </Toggle>
          );
        })}
      </Wrapper>
    </Container>
  );
}
